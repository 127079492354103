import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full flex flex-row space-x-2 justify-between px-4 items-center py-4 sticky top-0 bg-white z-50",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_2 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_3 = { class: "flex flex-col space-y-[1px]" }
const _hoisted_4 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_5 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_6 = { class: "flex flex-row items-center justify-center space-x-2" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "w-full flex flex-col overflow-y-auto relative h-full" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_10 = { class: "w-full flex flex-col" }
const _hoisted_11 = {
  key: 0,
  class: "h-[250px] w-full flex items-center justify-center"
}
const _hoisted_12 = {
  key: 1,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_13 = { class: "w-full flex flex-col space-y-3 pt-4" }
const _hoisted_14 = { class: "w-full flex flex-col pt-4" }
const _hoisted_15 = { class: "col-span-1 flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_banner_ad = _resolveComponent("app-banner-ad")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_shoplist_sponsored = _resolveComponent("app-shoplist-sponsored")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_shoplist_explore = _resolveComponent("app-shoplist-explore")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_plain_layout = _resolveComponent("plain-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_plain_layout, {
        title: 'clear',
        class: "overflow-y-auto h-screen"
      }, {
        topBar: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "w-[16px] cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.goBack()))
              }, [
                _createVNode(_component_app_icon, {
                  "custom-class": 'h-[14px]',
                  name: 'arrow-back'
                })
              ]),
              _createVNode(_component_app_image_loader, {
                photoUrl: _ctx.BusinessProfile?.photo_url ?? '/images/temp/brand.png',
                customClass: '!h-[40px] !w-[40px] rounded-full'
              }, null, 8, ["photoUrl"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_app_normal_text, { class: "!text-left !font-semibold !text-sm" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.BusinessProfile?.business_name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_app_icon, {
                    name: 'verified',
                    class: "!h-[14px]"
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-gray-800 !text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" @" + _toDisplayString(_ctx.BusinessProfile?.business_tag), 1)
                    ]),
                    _: 1
                  }),
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "h-[4px] w-[4px] rounded-full bg-gray-500" }, null, -1)),
                  _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-gray-800 !text-left !whitespace-nowrap" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.numberToAbbrev(_ctx.followers || 0, false)) + " Follower" + _toDisplayString(_ctx.followers > 1 ? "s" : ""), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.followUnfollowBusiness(_ctx.BusinessProfile?.uuid || ''))),
                  customClass: `!rounded-full !py-1 !px-3 !text-[12px] ${
                  !_ctx.isFollowed
                    ? 'bg-primary-main !text-white'
                    : '!bg-primary-50 !text-primary-main'
                }`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isFollowed ? "Following" : "Follow"), 1)
                  ]),
                  _: 1
                }, 8, ["customClass"])
              ]),
              _createElementVNode("div", {
                class: "h-[30px] w-[20px] flex items-center justify-center cursor-pointer",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showBusinessInfo && _ctx.showBusinessInfo(...args)))
              }, [
                _createVNode(_component_app_icon, {
                  name: 'info-circle',
                  customClass: 'h-[20px]'
                })
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_app_banner_ad)
              ]),
              (!_ctx.businessShoplists.data.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_app_empty_state, {
                      title: 'No Offers here',
                      "sub-title": `This ${
                  _ctx.BusinessProfile?.is_brand ? 'brand' : 'merchant'
                } has no offers at the moment.`
                    }, null, 8, ["sub-title"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.businessShoplists.data.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.businessShoplists.data[0]], (item, index) => {
                      return (_openBlock(), _createBlock(_component_app_shoplist_sponsored, {
                        key: index,
                        item: item,
                        class: "!w-full",
                        mediaBoxStyle: "!border-[1px] !border-grey-100 dark:!border-grey-700 ",
                        onClick: ($event: any) => (_ctx.handleShoplsistClick(item))
                      }, null, 8, ["item", "onClick"]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_app_header_text, { customClass: '!text-left  !text-base !font-semibold' }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Cashback Offers ")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_app_virtual_scroller, {
                          "container-class": 'w-full grid grid-cols-2 gap-3 !space-y-0',
                          data: _ctx.businessShoplists.data,
                          pagination: _ctx.businessShoplists.paginatorInfo,
                          fetchMore: _ctx.fetchMoreData,
                          direction: 'vertical',
                          ref: "merchantVirtualScrollerRef"
                        }, {
                          "item-content": _withCtx(({ index, item }) => [
                            _createElementVNode("div", _hoisted_15, [
                              (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                                class: "w-full",
                                key: index,
                                onClick: ($event: any) => (_ctx.handleShoplsistClick(item)),
                                item: item,
                                mediaBoxStyle: '!h-[140px] mdlg:!h-[200px] md:!h-[200px]',
                                theme: _ctx.Logic.Common.currentAppTheme()
                              }, null, 8, ["onClick", "item", "theme"]))
                            ])
                          ]),
                          "skeleton-loaders": _withCtx(() => _cache[5] || (_cache[5] = [
                            _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                              _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                            ], -1),
                            _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                              _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                            ], -1)
                          ])),
                          _: 1
                        }, 8, ["data", "pagination", "fetchMore"])
                      ])
                    ]),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}