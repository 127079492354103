import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-[1px]" }
const _hoisted_2 = { class: "w-full flex flex-row justify-end" }
const _hoisted_3 = { class: "w-full flex flex-col pt-4 space-y-4" }
const _hoisted_4 = { class: "w-full flex flex-col" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_6 = {
  class: "w-full flex-col flex sticky bottom-0 pt-4 bg-white dark:bg-black",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_auth_layout = _resolveComponent("auth-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, {
    class: _normalizeClass('!overflow-y-hidden')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_auth_layout, {
        onButtonClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/login'))),
        onGoBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.goBack())),
        "go-back": true,
        "right-button": false,
        hasBottomBorder: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full relative h-[8px] bg-gray-100 rounded-full" }, [
              _createElementVNode("div", { class: "absolute top-0 left-0 w-[98%] h-[8px] bg-primary-500 rounded-full" })
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, { class: "!text-grey-500 dark:!text-grey-400" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" 98% ")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_header_text, { class: "!text-left pb-4" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Finally, a little about you. ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_text_field, {
                  "focus-border": 'border-primary-400',
                  "has-title": true,
                  "custom-class": '!bg-grey-50',
                  placeholder: 'Select date',
                  modelValue: _ctx.aboutForm.date_of_birth,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.aboutForm.date_of_birth) = $event)),
                  name: "Date of birth",
                  type: "date",
                  ref: "dob",
                  required: "",
                  rules: [
                _ctx.FormValidations.RequiredRule,
                _ctx.FormValidations.customValidator(
                  _ctx.mustBe18OrAbove(_ctx.aboutForm.date_of_birth),
                  `You must be 18 years old or above`
                ),
              ]
                }, {
                  title: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Your date of birth ")
                  ])),
                  _: 1
                }, 8, ["modelValue", "rules"]),
                _createVNode(_component_app_select, {
                  placeholder: 'Select your gender',
                  hasTitle: true,
                  paddings: 'py-3 px-4',
                  options: _ctx.genderOption,
                  required: "",
                  ref: "gender",
                  modelValue: _ctx.aboutForm.gender,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.aboutForm.gender) = $event))
                }, {
                  title: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Your gender ")
                  ])),
                  _: 1
                }, 8, ["options", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_AppButton, {
                padding: 'py-3',
                "custom-class": `rounded-[40px] ${
              _ctx.aboutForm.date_of_birth && _ctx.aboutForm.gender ? 'opacity-100' : 'opacity-50'
            }`,
                "bg-color": 'bg-primary-main',
                "text-color": 'text-white',
                onClick: _ctx.continueButtonClicked
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" Continue ")
                ])),
                _: 1
              }, 8, ["custom-class", "onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}