import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 h-full flex-grow items-center justify-center px-4" }
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-center" }
const _hoisted_3 = { class: "w-full flex flex-row space-x-2 bg-grey-50 dark:!bg-gray-900 rounded-[10px] items-center justify-center h-[98px]" }
const _hoisted_4 = { class: "flex flex-col pt-1" }
const _hoisted_5 = { class: "!font-bold" }
const _hoisted_6 = { class: "!font-bold" }
const _hoisted_7 = {
  key: 1,
  class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-50 dark:!bg-secondary-main !text-center justify-center items-center"
}
const _hoisted_8 = {
  key: 2,
  class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-50 dark:!bg-secondary-main !text-center justify-center items-center"
}
const _hoisted_9 = {
  key: 3,
  class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-50 dark:!bg-secondary-main !text-center justify-center items-center"
}
const _hoisted_10 = {
  key: 4,
  class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-blue-50 dark:!bg-blue-main"
}
const _hoisted_11 = { class: "!font-bold" }
const _hoisted_12 = { class: "!font-bold" }
const _hoisted_13 = {
  key: 5,
  class: "w-full flex flex-row items-center justify-center py-4 pb-2"
}
const _hoisted_14 = { class: "col-span-2 flex flex-col" }
const _hoisted_15 = {
  key: 1,
  class: "col-span-full flex flex-col !h-[40px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_countdown = _resolveComponent("app-countdown")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        hideTopBar: _ctx.showConfettiSet,
        title: 'Reward Info'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_icon, {
                name: 'success-check',
                customClass: 'h-[134px]'
              })
            ]),
            _createVNode(_component_app_normal_text, {
              customClass: '!text-base !font-bold w-[80%] text-center',
              class: "!text-black"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.successMessage), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_icon, {
                name: 'reward-white',
                customClass: 'h-[48px]'
              }),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left !uppercase" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.earningsCopy), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { customClass: '!text-xl !font-bold' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.pointGained, false, "", false, "")) + " pts ", 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            ((_ctx.SinglePointEarned?.points || 0) > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (
              _ctx.currentCategory != 'SCANNED_RECEIPT' &&
              _ctx.processingStatus == 'processed' &&
              !_ctx.SinglePointEarned?.claimed
            )
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(`w-full flex flex-col py-3 px-3 rounded-[12px] ${
              _ctx.expired
                ? 'bg-secondary-50 items-center justify-center dark:!bg-secondary-main'
                : 'bg-success-50 dark:!bg-success-main'
            }`)
                      }, [
                        (!_ctx.expired)
                          ? (_openBlock(), _createBlock(_component_app_normal_text, { key: 0 }, {
                              default: _withCtx(() => [
                                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "!font-bold" }, "Congratulations! ", -1)),
                                _cache[3] || (_cache[3] = _createTextVNode(" You have earned it. ")),
                                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "!font-bold" }, "Claim your points", -1)),
                                _cache[5] || (_cache[5] = _createTextVNode(" now or it will expire ")),
                                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.expiryTime), 1),
                                _cache[6] || (_cache[6] = _createTextVNode(" as a ")),
                                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.AuthUser?.profile?.subscription_plan.name), 1),
                                _cache[7] || (_cache[7] = _createTextVNode(" subscription member. "))
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_app_normal_text, { key: 1 }, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode(" Your earned point has expired. ")
                              ])),
                              _: 1
                            }))
                      ], 2))
                    : _createCommentVNode("", true),
                  (
              _ctx.currentCategory == 'EXTERNAL_BANK_TRANSFER' &&
              _ctx.processingStatus == 'processing'
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_app_normal_text, null, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" We are processing your money transfer. This might take "),
                            _createElementVNode("span", { class: "!font-bold" }, "5 minutes", -1),
                            _createTextVNode(" to "),
                            _createElementVNode("span", { class: "!font-bold" }, "24 hours", -1),
                            _createTextVNode(" to be completed. ")
                          ])),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.currentCategory == 'SCANNED_RECEIPT' && _ctx.processingStatus == 'rejected')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_app_normal_text, null, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode(" Sorry, we couldn't verify your scanned receipt. ")
                          ])),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
              _ctx.currentCategory == 'EXTERNAL_BANK_TRANSFER' && _ctx.processingStatus == 'failed'
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_app_normal_text, null, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode(" We couldn't process your money transfer. ")
                          ])),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.currentCategory == 'SCANNED_RECEIPT' && _ctx.secondsDifference > 10)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_app_normal_text, null, {
                          default: _withCtx(() => [
                            _cache[12] || (_cache[12] = _createTextVNode(" We are ")),
                            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "!font-bold" }, "verifying and processing", -1)),
                            _cache[14] || (_cache[14] = _createTextVNode(" your receipt. Processing peroid might take up to ")),
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.AuthUser?.profile?.subscription_plan.scan_processing_period) + " hours", 1),
                            _cache[15] || (_cache[15] = _createTextVNode(" as a ")),
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.AuthUser?.profile?.subscription_plan.name), 1),
                            _cache[16] || (_cache[16] = _createTextVNode(" subscription member. "))
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  false
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_app_countdown, { secondsTarget: _ctx.secondsDifference }, null, 8, ["secondsTarget"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "w-full h-[100px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(`w-full grid ${
            _ctx.showConfettiSet &&
            !(
              _ctx.currentCategory == 'EXTERNAL_BANK_TRANSFER' &&
              _ctx.SinglePointEarned?.processing_status == 'processing'
            )
              ? 'grid-cols-2'
              : 'grid-cols-2'
          }  gap-3 pt-3`)
              }, [
                (_ctx.currentCategory != 'SCANNED_RECEIPT')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.SinglePointEarned?.processing_status != 'processing')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(`col-span-2 flex flex-col ${
                  _ctx.SinglePointEarned?.claimed || _ctx.expired ? 'opacity-50' : ''
                }`)
                          }, [
                            _createVNode(_component_app_button, {
                              padding: 'px-6 py-3',
                              border: 'border-b-[3px]',
                              customClass: 'w-full',
                              loading: _ctx.loaderSetup.loading,
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (
                    _ctx.SinglePointEarned?.claimed || _ctx.expired ? null : _ctx.actions.claimed()
                  ))
                            }, {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createTextVNode(" Claim Point ")
                              ])),
                              _: 1
                            }, 8, ["loading"])
                          ], 2))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_app_button, {
                    padding: 'px-6 py-3',
                    customClass: 'w-full',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.exploreOffers()))
                  }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode(" Explore offers ")
                    ])),
                    _: 1
                  })
                ]),
                (_ctx.bannerAdIsInView)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15))
                  : _createCommentVNode("", true)
              ], 2)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["hideTopBar"])
    ]),
    _: 1
  }))
}