import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col h-full flex-grow relative overflow-y-hidden" }
const _hoisted_2 = { class: "xs:w-[90%] w-[85%] flex flex-row px-4" }
const _hoisted_3 = { class: "w-full flex flex-col flex-grow h-full px-4 pt-4 z-50 overflow-y-hidden" }
const _hoisted_4 = { class: "h-full flex flex-grow flex-col w-full rounded-[10px] space-y-3 py-3 relative overflow-y-auto" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex flex-col space-y-3"
}
const _hoisted_6 = {
  key: 0,
  class: "w-full flex flex-col space-y-1"
}
const _hoisted_7 = { class: "w-full flex flex-row items-center justify-between px-2 py-2 bg-white dark:bg-gray-800" }
const _hoisted_8 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_9 = { class: "w-[38px]" }
const _hoisted_10 = { class: "bg-[#5A189A] border-white border-opacity-30 flex flex-row justify-end px-2 py-[2px] rounded-[40px]" }
const _hoisted_11 = { class: "w-full grid grid-cols-7 gap-3 border-b-[1px] dark:border-[#664D7E] border-gray-200 pb-3" }
const _hoisted_12 = { class: "col-span-2 flex flex-col items-center justify-center space-y-1" }
const _hoisted_13 = { class: "w-[84px] h-[84px] xs:w-[70px] xs:h-[70px] rounded-full border-[1px] relative border-white border-opacity-30 flex flex-col items-center justify-center" }
const _hoisted_14 = { class: "w-full flex flex-row items-center justify-center absolute top-[-30%] left-0" }
const _hoisted_15 = { class: "px-3 py-[2px] xs:px-2 bg-white flex flex-row rounded-[15px] space-x-1 items-center" }
const _hoisted_16 = { class: "col-span-3 flex flex-col items-center justify-center space-y-1" }
const _hoisted_17 = { class: "w-[118px] h-[150px] flex flex-col items-center justify-center relative" }
const _hoisted_18 = { class: "w-[84px] h-[84px] rounded-full border-white flex flex-col items-center justify-center" }
const _hoisted_19 = { class: "px-3 py-[2px] bg-white flex flex-row rounded-[15px] space-x-1 items-center" }
const _hoisted_20 = { class: "col-span-2 flex flex-col items-center justify-center space-y-1" }
const _hoisted_21 = { class: "w-[84px] h-[84px] xs:w-[70px] xs:h-[70px] rounded-full border-[1px] relative border-white border-opacity-30 flex flex-col items-center justify-center" }
const _hoisted_22 = { class: "w-full flex flex-row items-center justify-center absolute top-[-30%] left-0" }
const _hoisted_23 = { class: "px-3 py-[2px] xs:px-2 bg-white flex flex-row rounded-[15px] space-x-1 items-center" }
const _hoisted_24 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-between py-2 border-b-[1px] dark:border-[#664D7E] border-gray-200"
}
const _hoisted_25 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_26 = { class: "w-[38px]" }
const _hoisted_27 = { class: "bg-[#5A189A] border-white border-opacity-30 flex flex-row justify-end px-2 py-[2px] rounded-[40px]" }
const _hoisted_28 = {
  key: 1,
  class: "w-full flex flex-col py-4 px-8 rounded-[10px] justify-center items-center space-y-3 h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_tabs = _resolveComponent("app-tabs")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: 'Leaderboard',
        mainSectionClass: "!bg-white dark:!bg-black !overflow-y-hidden !fixed top-0 left-0 mdlg:!relative md:!relative  w-full",
        hasPadding: false,
        topBarStyle: '!bg-transparent',
        parentContClass: 'md:!h-screen',
        class: "!overflow-y-hidden md:!overflow-y-hidden",
        theme: "light",
        showBottomPadding: false
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "absolute -top-[1%] -right-0" }, [
            _createElementVNode("img", {
              src: "/images/leaderboard-bg-image.png",
              class: "h-[140px]"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, {
                class: _normalizeClass('!text-black dark:!text-white')
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Climb the Shoppers Leaderboard. Shop, earn points, and unlock exclusive rewards! ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_app_tabs, {
                  data: _ctx.filters,
                  modelValue: _ctx.selectedFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilter) = $event))
                }, null, 8, ["data", "modelValue"]),
                (_ctx.leaderboardUsers.length > 3)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      ((_ctx.currentUser?.index || 0) > 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_app_normal_text, {
                              color: 'text-white',
                              customClass: '!font-semibold !uppercase !text-[12px]'
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode(" Your position ")
                              ])),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_app_normal_text, {
                                  color: 'text-black dark:!text-white',
                                  customClass: 'w-[20px] flex flex-row items-center justify-center !text-[12px]'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString((_ctx.currentUser?.index || 0) + 1), 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("div", _hoisted_9, [
                                  _createVNode(_component_app_image_loader, {
                                    class: "!rounded-full !w-[38px] !h-[38px] !bg-[#FFF7E6] flex items-center justify-center",
                                    photoUrl: _ctx.currentUser?.user.profile?.photo_url || ''
                                  }, {
                                    default: _withCtx(() => [
                                      (!_ctx.currentUser?.user.profile?.photo_url)
                                        ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                            key: 0,
                                            class: _normalizeClass('!font-semibold !text-[12px] !bg-[#FFAA00] uppercase')
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.currentUser?.user.username?.charAt(0)), 1)
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["photoUrl"])
                                ]),
                                _createVNode(_component_app_normal_text, {
                                  color: 'text-black dark:!text-white',
                                  customClass: '!line-clamp-1 text-left !capitalize'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.currentUser?.user.username), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_app_normal_text, {
                                  class: _normalizeClass('dark:!text-white !text-black  !font-light  !text-[11px] !whitespace-nowrap')
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.abbreviateNumber(_ctx.currentUser?.points || 0)) + " pts ", 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_app_icon, {
                                name: 'second-position',
                                customClass: 'h-[33px]'
                              })
                            ]),
                            _createVNode(_component_app_image_loader, {
                              photoUrl: _ctx.leaderboardUsers[1].photo_url,
                              class: _normalizeClass('!h-[90%] !w-[90%] !border-[3px] !border-secondary-400 !bg-white !rounded-full flex items-center justify-center')
                            }, {
                              default: _withCtx(() => [
                                (!_ctx.leaderboardUsers[1].photo_url)
                                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                      key: 0,
                                      customClass: '!text-lg !font-semibold uppercase'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[1].name?.charAt(0)), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["photoUrl"])
                          ]),
                          _createVNode(_component_app_normal_text, {
                            color: 'text-black dark:!text-white',
                            customClass: 'xs:!text-[9px] !text-[12px] !capitalize'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[1].name), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("span", _hoisted_15, [
                            _createVNode(_component_app_icon, {
                              name: 'badge-game',
                              customClass: 'h-[13px]'
                            }),
                            _createVNode(_component_app_normal_text, { customClass: '!text-[10px] xs:!text-[8px] !text-[#9D4EDD] !font-semibold !whitespace-nowrap' }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[1].points) + " pts", 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute top-0 left-0 h-full w-full flex items-center justify-center" }, [
                              _createElementVNode("img", {
                                src: "/images/icons/first-position.svg",
                                class: "h-auto mt-[-23%]"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_app_image_loader, {
                                photoUrl: _ctx.leaderboardUsers[0].photo_url,
                                class: _normalizeClass('!h-[100%] !w-[100%] !bg-white !rounded-full flex items-center justify-center')
                              }, {
                                default: _withCtx(() => [
                                  (!_ctx.leaderboardUsers[0].photo_url)
                                    ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                        key: 0,
                                        customClass: '!text-lg !font-semibold uppercase'
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[0].name?.charAt(0)), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["photoUrl"])
                            ])
                          ]),
                          _createVNode(_component_app_normal_text, {
                            color: 'text-black dark:!text-white',
                            class: "!text-[12px] !capitalize"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[0].name), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("span", _hoisted_19, [
                            _createVNode(_component_app_icon, {
                              name: 'badge-game',
                              customClass: 'h-[13px]'
                            }),
                            _createVNode(_component_app_normal_text, { customClass: '!text-[10px] !text-[#9D4EDD] !font-semibold !whitespace-nowrap' }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[0].points) + " pts", 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_component_app_icon, {
                                name: 'thrid-position',
                                customClass: 'h-[33px]'
                              })
                            ]),
                            _createVNode(_component_app_image_loader, {
                              photoUrl: _ctx.leaderboardUsers[2].photo_url,
                              class: _normalizeClass('!h-[90%] !w-[90%] !border-[3px] !border-secondary-400 !bg-white flex items-center rounded-full justify-center')
                            }, {
                              default: _withCtx(() => [
                                (!_ctx.leaderboardUsers[2].photo_url)
                                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                      key: 0,
                                      color: 'text-shop_dark_400',
                                      customClass: '!text-lg !font-semibold uppercase'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[2].name?.charAt(0)), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["photoUrl"])
                          ]),
                          _createVNode(_component_app_normal_text, {
                            color: 'text-black dark:!text-white',
                            customClass: 'xs:!text-[9px] !text-[12px] !capitalize'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[2].name), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("span", _hoisted_23, [
                            _createVNode(_component_app_icon, {
                              name: 'badge-game',
                              customClass: 'h-[13px]'
                            }),
                            _createVNode(_component_app_normal_text, { customClass: '!text-[10px] xs:!text-[8px]  !font-semibold !whitespace-nowrap !text-[#9D4EDD]' }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.leaderboardUsers[2].points) + " pts", 1)
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leaderboardUsers, (user, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (index > 2)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                _createElementVNode("div", _hoisted_25, [
                                  _createVNode(_component_app_normal_text, {
                                    color: 'text-black dark:!text-white',
                                    customClass: 'w-[20px] flex flex-row items-center justify-center'
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(index + 1) + ". ", 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createElementVNode("div", _hoisted_26, [
                                    _createVNode(_component_app_image_loader, {
                                      photoUrl: user.photo_url,
                                      class: "!rounded-full !h-[38px] !w-[38px] flex items-center justify-center !bg-[#FFF7E6] border-[1px] border-[#FFF7E6]"
                                    }, {
                                      default: _withCtx(() => [
                                        (!user.photo_url)
                                          ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                              key: 0,
                                              class: _normalizeClass('!text-lg !font-semibold !text-[#FFAA00] uppercase')
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(user.name?.charAt(0)), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["photoUrl"])
                                  ]),
                                  _createVNode(_component_app_normal_text, {
                                    color: 'text-black dark:!text-white',
                                    customClass: '!line-clamp-1 text-left lowercase !text-[12px] !capitalize'
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(user.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("div", _hoisted_27, [
                                  _createVNode(_component_app_normal_text, {
                                    class: _normalizeClass('!text-white !font-light !text-[11px] !whitespace-nowrap')
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(user.points) + " pts ", 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_28, _cache[4] || (_cache[4] = [
                      _createElementVNode("span", {
                        class: _normalizeClass('!text-[#A799B5] dark:!text-gray-300 !text-xs')
                      }, " There are currently no users in this leaderboard. ", -1)
                    ])))
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}