<template>
  <app-wrapper>
    <plain-layout :title="'clear'" class="overflow-y-auto h-screen">
      <template v-slot:topBar>
        <div
          class="w-full flex flex-row space-x-2 justify-between px-4 items-center py-4 sticky top-0 bg-white z-50"
          style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
        >
          <div class="flex flex-row space-x-2 items-center">
            <div class="w-[16px] cursor-pointer" @click="Logic.Common.goBack()">
              <app-icon :custom-class="'h-[14px]'" :name="'arrow-back'"> </app-icon>
            </div>
            <app-image-loader
              :photoUrl="BusinessProfile?.photo_url ?? '/images/temp/brand.png'"
              :customClass="'!h-[40px] !w-[40px] rounded-full'"
            />
            <div class="flex flex-col space-y-[1px]">
              <div class="flex flex-row space-x-1 items-center">
                <div>
                  <app-normal-text class="!text-left !font-semibold !text-sm">
                    {{ BusinessProfile?.business_name }}
                  </app-normal-text>
                </div>
                <app-icon :name="'verified'" class="!h-[14px]" />
              </div>
              <div class="flex flex-row space-x-1 items-center">
                <app-normal-text class="!text-[11px] !text-gray-800 !text-left">
                  @{{ BusinessProfile?.business_tag }}
                </app-normal-text>
                <span class="h-[4px] w-[4px] rounded-full bg-gray-500"> </span>
                <app-normal-text
                  class="!text-[11px] !text-gray-800 !text-left !whitespace-nowrap"
                >
                  {{ numberToAbbrev(followers || 0, false) }}
                  Follower{{ followers > 1 ? "s" : "" }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center justify-center space-x-2">
            <div class="flex flex-col">
              <app-button
                @click="followUnfollowBusiness(BusinessProfile?.uuid || '')"
                :customClass="`!rounded-full !py-1 !px-3 !text-[12px] ${
                  !isFollowed
                    ? 'bg-primary-main !text-white'
                    : '!bg-primary-50 !text-primary-main'
                }`"
              >
                {{ isFollowed ? "Following" : "Follow" }}
              </app-button>
            </div>
            <div
              class="h-[30px] w-[20px] flex items-center justify-center cursor-pointer"
              @click="showBusinessInfo"
            >
              <app-icon :name="'info-circle'" :customClass="'h-[20px]'" />
            </div>
          </div>
        </div>
      </template>

      <div class="w-full flex flex-col overflow-y-auto relative h-full">
        <!-- Top bar -->
        <div class="w-full flex flex-col space-y-3 px-4">
          <!-- Banner ad -->
          <div class="w-full flex flex-col">
            <app-banner-ad />
          </div>

          <template v-if="!businessShoplists.data.length">
            <div class="h-[250px] w-full flex items-center justify-center">
              <app-empty-state
                :title="'No Offers here'"
                :sub-title="`This ${
                  BusinessProfile?.is_brand ? 'brand' : 'merchant'
                } has no offers at the moment.`"
              />
            </div>
          </template>

          <!-- Brand Shoplists -->
          <div
            class="w-full flex flex-col space-y-2"
            v-if="businessShoplists.data.length"
          >
            <!-- Top -->
            <app-shoplist-sponsored
              v-for="(item, index) in [businessShoplists.data[0]]"
              :key="index"
              :item="item"
              class="!w-full"
              mediaBoxStyle="!border-[1px] !border-grey-100 dark:!border-grey-700 "
              @click="handleShoplsistClick(item)"
            />

            <!-- Cashback Offers -->
            <div class="w-full flex flex-col space-y-3 pt-4">
              <app-header-text :customClass="'!text-left  !text-base !font-semibold'">
                Cashback Offers
              </app-header-text>

              <div class="w-full flex flex-col pt-4">
                <app-virtual-scroller
                  :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
                  :data="businessShoplists.data"
                  :pagination="businessShoplists.paginatorInfo"
                  :fetchMore="fetchMoreData"
                  :direction="'vertical'"
                  ref="merchantVirtualScrollerRef"
                >
                  <template #item-content="{ index, item }">
                    <div class="col-span-1 flex flex-col">
                      <app-shoplist-explore
                        class="w-full"
                        :key="index"
                        @click="handleShoplsistClick(item)"
                        :item="item"
                        :mediaBoxStyle="'!h-[140px] mdlg:!h-[200px] md:!h-[200px]'"
                        :theme="Logic.Common.currentAppTheme()"
                      />
                    </div>
                  </template>
                  <template #skeleton-loaders>
                    <div class="col-span-1 flex flex-col">
                      <div
                        class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                      ></div>
                    </div>
                    <div class="col-span-1 flex flex-col">
                      <div
                        class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                      ></div>
                    </div>
                  </template>
                </app-virtual-scroller>
              </div>
            </div>

            <!-- Spacer -->
            <div class="h-[120px]"></div>
          </div>
        </div>
      </div>
    </plain-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppIcon,
  AppNormalText,
  AppImageLoader,
  AppBannerAd,
  AppHeaderText,
  AppEmptyState,
  AppShoplistExplore,
  AppButton,
  AppShoplistSponsored,
  AppVirtualScroller,
} from "@shpt/ui-components";
import { MediaBox } from "@shpt/ui-components/src/types";
import { getMediaBox, getMediaBoxForProduct } from "@shpt/ui-components/src/composable";
import { Collection, ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import { ContentType, FollowAction, PaginatorInfo } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppImageLoader,
    AppBannerAd,
    AppHeaderText,
    AppEmptyState,
    AppWrapper,
    AppShoplistExplore,
    AppButton,
    AppShoplistSponsored,
    AppVirtualScroller,
  },
  name: "BrandProfilePage",
  layout: "Onboarding",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "BusinessProfile",
        method: "GetBusinessProfile",
        params: [],
        requireAuth: true,
        useRouteQuery: true,
        queries: ["uuid"],
        ignoreProperty: true,
      },
      {
        domain: "User",
        property: "MerchantShoplistsContent",
        method: "GetUserContent",
        params: [ContentType.BusinessContents, 1, 6, true, false],
        requireAuth: true,
        useRouteQuery: true,
        queries: ["uuid"],
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Brand Profile Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    const currentBusinessUuid = ref("");

    const BusinessProfile = ref(Logic.User.BusinessProfile);
    const MerchantShoplistsContent = ref(Logic.User.MerchantShoplistsContent);
    const isFollowed = ref(false);
    const followers = ref(0);

    const showBusinessInfo = () => {
      Logic.Common.showModal({
        show: true,
        title: `About ${BusinessProfile.value?.business_name}`,
        type: "business_info",
        action: () => {
          // Logic.Common.goBack();
        },
        extraData: {
          data: BusinessProfile.value,
        },
      });
    };

    const businessShoplists = reactive<{
      data: {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[];
      paginatorInfo: PaginatorInfo | undefined;
    }>({
      data: [],
      paginatorInfo: undefined,
    });

    const merchantShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const fetchMoreData = (nextPage: number) => {
      return Logic.User.GetUserContent(
        currentBusinessUuid.value,
        [ContentType.BusinessContents],
        nextPage,
        6,
        true
      )
        .then((responseData) => {
          if (responseData) {
            const existingRecommndationContent = JSON.parse(
              JSON.stringify(MerchantShoplistsContent.value?.contents)
            );
            responseData.contents.push(...existingRecommndationContent);

            MerchantShoplistsContent.value = responseData;

            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const setMerchantShoplists = () => {
      merchantShoplists.length = 0;

      MerchantShoplistsContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          businessShoplists.data.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
        if (item.type == "product") {
          const mediaBox = getMediaBoxForProduct(item.data as ShoplistProduct);
          if (mediaBox) {
            businessShoplists.data.push({
              data: mediaBox,
              id: item.id.toString(),
              type: "product",
              colors: {
                bg: "",
                text: "",
              },
            });
          }
        }
      });

      businessShoplists.paginatorInfo = {
        count: 0,
        currentPage: MerchantShoplistsContent.value?.current_page || 0,
        hasMorePages: true,
        lastPage: MerchantShoplistsContent.value?.total_pages || 0,
        perPage: 6,
        total: MerchantShoplistsContent.value?.total_pages || 0,
      };

      currentBusinessUuid.value = Logic.Common.route?.query?.uuid?.toString() || "";

      if (Logic.Common.currentBuildType() == "web") {
        useMeta({
          title: BusinessProfile.value?.business_name || "",
        });
      }
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.BusinessContents, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(`/shoplist/product/${item.data.product_data?.uuid}`);
      }
    };

    const followUnfollowBusiness = (bussiness_uuid: string) => {
      Logic.User.FollowUnfollowBusinessForm = {
        action: isFollowed.value ? FollowAction.Unfollow : FollowAction.Follow,
        business_uuid: bussiness_uuid,
      };

      isFollowed.value = !isFollowed.value;

      followers.value = isFollowed.value ? followers.value + 1 : followers.value - 1;

      Logic.User.FollowUnfollowBusiness()?.then((data) => {
        if (data) {
          //
        }
      });
    };

    const setFollowedStatus = () => {
      isFollowed.value = BusinessProfile.value?.is_following || false;
      followers.value = BusinessProfile.value?.followers || 0;
    };

    onIonViewWillEnter(() => {
      setMerchantShoplists();
      setFollowedStatus();
    });

    watch(MerchantShoplistsContent, () => {
      setMerchantShoplists();
    });

    watch(BusinessProfile, () => {
      setFollowedStatus();
    });

    onMounted(() => {
      Logic.User.watchProperty("BusinessProfile", BusinessProfile);
      Logic.User.watchProperty("MerchantShoplistsContent", MerchantShoplistsContent);
      setMerchantShoplists();
      setFollowedStatus();
    });

    return {
      merchantShoplists,
      Logic,
      BusinessProfile,
      ContentType,
      currentBusinessUuid,
      isFollowed,
      followers,
      businessShoplists,
      fetchMoreData,
      numberToAbbrev,
      setStoryContent,
      showBusinessInfo,
      handleShoplsistClick,
      followUnfollowBusiness,
    };
  },
});
</script>
