<template>
  <div class="w-[165px] flex flex-col space-y-2 !cursor-pointer" v-if="item">
    <app-normal-text
      v-if="!hideCategory"
      class="w-full !text-left !font-light !text-[#313131] dark:!text-grey-200 !text-[12px]"
    >
      {{ item.data.main_category }}
    </app-normal-text>
    <div class="w-full flex flex-col">
      <app-media-box
        :data="item.data"
        :isVideo="!item.data.is_grid"
        class="!h-[200px] !rounded-[7.9px] relative"
      >
      </app-media-box>
    </div>
    <div class="w-full flex flex-col pb-3 left-0 space-y-1">
      <app-normal-text class="!text-left !line-clamp-1 w-full !font-[550] !text-[12px]">
        {{ item.data.title }}
      </app-normal-text>
      <div class="flex flex-row justify-between items-center">
        <app-normal-text class="!text-[12px] dark:!text-white !font-normal">
          {{
            Logic.Common.convertToMoney(
              parseFloat(item.data.price?.toFixed(2) || "0"),
              false,
              "ngn"
            )
          }}
        </app-normal-text>
        <span class="flex flex-row space-x-1 items-center">
          <!-- <app-icon :name="'cashback'" :customClass="'h-[11px]'" /> -->
          <app-normal-text
            :customClass="'!text-[11px] !text-grey-400 dark:!text-gray-200  !font-light !whitespace-nowrap'"
          >
            Earn
            <span class="!font-normal !text-[#9D4EDD] !text-[12px]">
              {{ numberToAbbrev(item.data.points || 0) }} pts
            </span>
          </app-normal-text>
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MediaBox } from "../../types";
import { defineComponent } from "vue";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import AppMediaBox from "../AppMediaBox";
import { Logic, numberToAbbrev } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppMediaBox,
  },
  props: {
    item: {
      type: Object as () => {
        data: MediaBox;
        id: string;
        colors: {
          bg: string;
          text: string;
        };
      },
    },
    hideCategory: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      numberToAbbrev,
      Logic,
    };
  },
  name: "AppShoplistRecommended",
});
</script>
