import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "w-full h-full absolute top-0 left-0" }
const _hoisted_3 = { class: "w-full h-full flex flex-col justify-between" }
const _hoisted_4 = {
  key: 0,
  class: "w-full h-[3px] bg-white !bg-opacity-50 rounded-full overflow-hidden"
}
const _hoisted_5 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_6 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_7 = { class: "flex flex-col items-start" }
const _hoisted_8 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_9 = {
  key: 0,
  class: "w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center"
}
const _hoisted_10 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin  h-5 w-5  text-white`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_11 = { class: "flex flex-col space-y-3 w-full" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col space-y-4 w-full justify-end items-end"
}
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col space-y-1 items-center justify-center"
}
const _hoisted_14 = {
  key: 1,
  class: "w-full flex flex-row justify-between items-end"
}
const _hoisted_15 = {
  key: 0,
  class: "flex flex-row space-x-2 items-center flex-nowrap"
}
const _hoisted_16 = {
  key: 2,
  class: "w-full bg-white dark:!bg-gray-900 !bg-opacity-[84%] rounded-[10px] py-3 px-3 flex flex-row space-x-3"
}
const _hoisted_17 = { class: "w-[90px]" }
const _hoisted_18 = { class: "w-[90px] h-[90px] grid grid-cols-2 z-0" }
const _hoisted_19 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 justify-between"
}
const _hoisted_20 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_21 = {
  key: 0,
  class: "w-full flex flex-row space-x-2 items-center"
}
const _hoisted_22 = { class: "flex flex-row space-x-1 items-center !text-right" }
const _hoisted_23 = { class: "!font-semibold !text-[#9D4EDD] !text-[12px]" }
const _hoisted_24 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_25 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_26 = {
  key: 3,
  class: "w-full flex flex-col"
}
const _hoisted_27 = {
  key: 4,
  class: "w-full flex flex-col space-y-2 items-center justify-center"
}
const _hoisted_28 = {
  key: 0,
  class: "w-full grid grid-cols-2 gap-2"
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center" }
const _hoisted_31 = { class: "text-black !font-semibold !text-xs" }
const _hoisted_32 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
}
const _hoisted_33 = { class: "absolute top-0 right-0 px-1 py-1 h-full flex items-center justify-center z-20" }
const _hoisted_34 = { class: "w-[30px] h-[30px] flex items-center justify-center" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { class: "py-1 flex flex-row items-center justify-center relative w-full" }
const _hoisted_37 = { class: "text-black !font-semibold !text-[11px] z-50" }
const _hoisted_38 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
}
const _hoisted_39 = { class: "absolute top-0 right-0 px-1 py-1 h-full flex items-center justify-center z-20" }
const _hoisted_40 = { class: "w-[30px] h-[30px] flex items-center justify-center" }
const _hoisted_41 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "text-black !font-semibold !text-xs" }
const _hoisted_44 = ["onClick"]
const _hoisted_45 = { class: "py-2 flex flex-row items-center justify-center relative" }
const _hoisted_46 = { class: "text-black !font-semibold !text-[11px] z-50" }
const _hoisted_47 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
}
const _hoisted_48 = { class: "absolute top-0 right-0 px-1 py-1 h-full flex items-center justify-center z-20" }
const _hoisted_49 = { class: "w-[30px] h-[30px] flex items-center justify-center" }
const _hoisted_50 = { class: "w-full flex flex-row items-center justify-center pt-2" }
const _hoisted_51 = {
  key: 0,
  class: "text-center text-gray-300 !text-[11px]"
}
const _hoisted_52 = {
  key: 1,
  class: "text-center text-gray-300 !text-[11px]"
}
const _hoisted_53 = {
  key: 0,
  class: "w-full absolute bottom-0 left-0 bg-black z-30",
  style: {"height":"calc(env(safe-area-inset-bottom))"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_media_box = _resolveComponent("app-media-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full flex flex-col ${_ctx.isFullScreen ? 'h-full' : 'h-auto'} `),
    id: `story-${_ctx.uniqueKey}`
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (_ctx.currentIndex == index && _ctx.contentIsMounted)
          ? (_openBlock(), _createBlock(_component_app_media_box, {
              key: 0,
              onTouchstart: _cache[7] || (_cache[7] = ($event: any) => (!_ctx.videoIsReady && _ctx.pauseTimer())),
              onTouchend: _cache[8] || (_cache[8] = ($event: any) => (!_ctx.videoIsReady && _ctx.resumeTimer())),
              data: item.media,
              isVideo: !item.media.is_grid,
              playButtonStyle: '!h-[50px]',
              class: "!rounded-[0px] !relative mdlg:!rounded-[10px] md:!rounded-[10px]",
              isGrid: false,
              isFullScreen: _ctx.isFullScreen,
              ref_for: true,
              ref: "mediaBoxRef",
              onVideoSettingsChanged: _cache[9] || (_cache[9] = 
          (data) => {
            _ctx.videoSettings.paused = data.paused;
            _ctx.videoSettings.muted = data.muted;
            _ctx.videoSettings.loading = data.loading;
            _ctx.videoSettings.total_duration = data.total_duration;
            _ctx.videoSettings.current_time = data.current_time;
          }
        ),
              onCurrentTime: _cache[10] || (_cache[10] = 
          (data) => {
            _ctx.timeLoader.current = data;
          }
        ),
              onTotalDuration: _cache[11] || (_cache[11] = 
          (data) => {
            _ctx.baseDuration = data;
          }
        ),
              style: _normalizeStyle(
          !_ctx.isFullScreen
            ? `height: calc(100vh - env(safe-area-inset-bottom) -  env(safe-area-inset-top) - 120px);`
            : 'height: 100dvh'
        ),
              "allow-play": true,
              loopVideo: false,
              onLoaded: _cache[12] || (_cache[12] = ($event: any) => (_ctx.imageLoaded = true))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: "w-full flex flex-col space-y-2 bg-gradient-to-b from-black !from-opacity-20 to-transparent px-3 py-3 h-[20%] relative mdlg:!rounded-t-[10px] md:!rounded-t-[10px]",
                      style: _normalizeStyle(` padding-top: ${
                _ctx.isFullScreen ? ' calc(env(safe-area-inset-top) + 12px)' : '12px'
              }  !important;`),
                      onClick: _withModifiers(($event: any) => (null), ["stop"])
                    }, [
                      (!_ctx.hasEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("div", {
                              class: "h-full bg-white transition-all duration-200 ease-linear",
                              style: _normalizeStyle({
                    width: `${(_ctx.timeLoader.current / _ctx.timeLoader.total) * 100}%`,
                  })
                            }, null, 4)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_app_image_loader, {
                            photoUrl: item.media.user_info.image,
                            customClass: '!h-[40px] !w-[40px] rounded-full cursor-pointer',
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleActions('profile')), ["stop"]))
                          }, null, 8, ["photoUrl"]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.media.user_info.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white !text-[10px]" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" @" + _toDisplayString(item.media.user_info.handle), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          (_ctx.videoSettings.loading)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[13] || (_cache[13] = [
                                  _createElementVNode("circle", {
                                    class: "opacity-25",
                                    cx: "12",
                                    cy: "12",
                                    r: "10",
                                    stroke: "currentColor",
                                    "stroke-width": "4"
                                  }, null, -1),
                                  _createElementVNode("path", {
                                    class: "opacity-75",
                                    fill: "currentColor",
                                    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  }, null, -1)
                                ])))
                              ]))
                            : _createCommentVNode("", true),
                          (item.media.video_url && !_ctx.videoSettings.paused)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: "w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer",
                                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.mediaBoxRef[0].toggleMuteUnmute()), ["stop"]))
                              }, [
                                _createVNode(_component_app_icon, {
                                  name: _ctx.videoSettings.muted ? 'mute-white' : 'unmute-white',
                                  class: "!h-[14px]"
                                }, null, 8, ["name"])
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.isFullScreen)
                            ? (_openBlock(), _createBlock(_component_app_icon, {
                                key: 2,
                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
                      _ctx.Logic.Auth.AuthUser
                        ? _ctx.Logic.Common.goBack()
                        : _ctx.Logic.Common.GoToRoute('/auth/login')
                    ), ["stop"])),
                                name: 'cross-white',
                                customClass: 'h-[17px] cursor-pointer'
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ], 4),
                    item
                      ? (_openBlock(), _createBlock(_Transition, {
                          key: 0,
                          name: "slide-out-bottom"
                        }, {
                          default: _withCtx(() => [
                            (
                  (item.media.video_url && _ctx.videoSettings.paused) ||
                  !item.media.video_url ||
                  !_ctx.hideOnPlay
                )
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "w-full flex flex-row justify-between items-end px-3 py-3 h-[40%] mdlg:!rounded-b-[10px] md:!rounded-b-[10px]",
                                  style: _normalizeStyle(`background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);   padding-bottom: ${
                  _ctx.isFullScreen ? ' calc(env(safe-area-inset-bottom) + 12px)' : '12px'
                } !important;`)
                                }, [
                                  _createElementVNode("div", _hoisted_11, [
                                    (!_ctx.hasEdit)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                          (item.media.data?.uuid)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                                _createElementVNode("span", {
                                                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.handleActions('like')), ["stop"])),
                                                  class: "h-[40px] w-[40px] rounded-full bg-white !bg-opacity-20 flex items-center justify-center cursor-pointer"
                                                }, [
                                                  _createVNode(_component_app_icon, {
                                                    name: _ctx.isLiked ? 'heart-red' : 'heart-white',
                                                    class: "!h-[18px]"
                                                  }, null, 8, ["name"])
                                                ]),
                                                _createVNode(_component_app_normal_text, { class: "!text-white !text-[10px]" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.numberToAbbrev(_ctx.likeCount, false)), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("span", {
                                            class: "h-[40px] w-[40px] rounded-full bg-white !bg-opacity-20 flex items-center justify-center cursor-pointer",
                                            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.handleActions('share')), ["stop"]))
                                          }, [
                                            _createVNode(_component_app_icon, {
                                              name: "share-white-story",
                                              class: "!h-[18px]"
                                            })
                                          ])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (item.type != 'polls')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                          (item.media.tags.length)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.media.tags, (tag, index) => {
                                                  return (_openBlock(), _createElementBlock("span", {
                                                    class: "px-2 py-[1px] bg-transparent rounded-[20px] border-[1px] border-white",
                                                    key: index
                                                  }, [
                                                    _createVNode(_component_app_normal_text, { class: "!text-white !text-[11px]" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(tag), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (item.type == 'shoplist')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                          _createElementVNode("div", _hoisted_17, [
                                            _createElementVNode("div", _hoisted_18, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridImages, (image, index) => {
                                                return (_openBlock(), _createBlock(_component_app_image_loader, {
                                                  photoUrl: image.url,
                                                  class: _normalizeClass(`${image.width} h-full rounded-none`),
                                                  key: index,
                                                  style: _normalizeStyle(`${image.border} `)
                                                }, null, 8, ["photoUrl", "class", "style"]))
                                              }), 128))
                                            ])
                                          ]),
                                          (item.shoplist)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                _createElementVNode("div", _hoisted_20, [
                                                  _createVNode(_component_app_normal_text, { class: "!font-bold !text-sm text-left !line-clamp-1" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(item.shoplist.title), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  (!_ctx.hideExtraShoplistInfo)
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                        _createVNode(_component_app_normal_text, { class: "!text-[11px] text-left" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(item.shoplist.base_tag), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _cache[15] || (_cache[15] = _createElementVNode("span", { class: "h-[3px] w-[4px] rounded-full bg-black dark:!bg-white" }, null, -1)),
                                                        _createElementVNode("span", _hoisted_22, [
                                                          _createVNode(_component_app_normal_text, { customClass: '!text-[11px] !text-grey-600 dark:!text-gray-100  !text-right !font-light !whitespace-nowrap' }, {
                                                            default: _withCtx(() => [
                                                              _cache[14] || (_cache[14] = _createTextVNode("Earn ")),
                                                              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.numberToAbbrev(item.media.points || 0)) + " pts ", 1)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ])
                                                      ]))
                                                    : _createCommentVNode("", true)
                                                ]),
                                                _createElementVNode("div", _hoisted_24, [
                                                  _createVNode(_component_app_normal_text, { class: "!font-bold !text-base text-left" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                                item.shoplist.price,
                                false,
                                "ngn"
                              )), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  (!_ctx.hideExtraShoplistInfo)
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                                        _createVNode(_component_app_button, {
                                                          padding: 'px-4 py-2',
                                                          class: "!text-[12px] mdlg:!text-xs",
                                                          onClick: _withModifiers(($event: any) => (_ctx.previewShoplist(item)), ["stop"])
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_app_normal_text, { class: "!text-[12px] mdlg:!text-xs !line-clamp-1 !text-white dark:!text-white" }, {
                                                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                                _createTextVNode(" Claim Offer ")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["onClick"])
                                                      ]))
                                                    : _createCommentVNode("", true)
                                                ])
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (item.type == 'website_visit' && item.website_visit)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                          _createVNode(_component_app_button, {
                                            class: "!bg-white w-full py-3 !rounded-[7px] !text-black dark:!text-black !font-semibold cursor-pointer",
                                            onClick: _withModifiers(($event: any) => (_ctx.openBrowser(item.website_visit.landing_page_url)), ["stop"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.website_visit?.cta), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (item.type == 'polls' && item.polls)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                          _createVNode(_component_app_normal_text, { class: "w-full text-center !font-bold !text-xl !text-white uppercase pb-1 px-3" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.polls?.question), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          (
                          item.polls?.answer_type === 'binary' ||
                          item.polls.options.length == 2
                        )
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPollSummary, (option, index) => {
                                                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                                    (
                              (item.polls.options.filter(
                                (eachitem) => eachitem.image_url
                              ).length == 0 &&
                                _ctx.hasEdit) ||
                              (!_ctx.hasEdit &&
                                _ctx.pollSummary.filter(
                                  (eachitem) => eachitem.image_url
                                ).length == 0 &&
                                _ctx.hasEdit)
                            )
                                                      ? (_openBlock(), _createElementBlock("button", {
                                                          key: 0,
                                                          class: "focus:outline-none col-span-1 py-3 bg-white rounded-[6px] flex justify-center items-center relative cursor-pointer",
                                                          onClick: _withModifiers(($event: any) => (_ctx.handlePollAnswer(option.slug || '')), ["stop"])
                                                        }, [
                                                          _createElementVNode("div", _hoisted_30, [
                                                            _createElementVNode("span", _hoisted_31, _toDisplayString(option.value), 1),
                                                            (_ctx.hasAnsweredPoll)
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                                                  _createElementVNode("div", {
                                                                    class: "absolute top-0 left-0 h-full flex items-center justify-center bg-primary-100 rounded-bl-[6px] rounded-br-[6px]",
                                                                    style: _normalizeStyle(`width: ${option.percentage}%`)
                                                                  }, null, 4),
                                                                  _createElementVNode("div", _hoisted_33, [
                                                                    _createElementVNode("span", _hoisted_34, [
                                                                      _createVNode(_component_app_normal_text, { class: "!text-black !text-[10px] !font-semibold" }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(parseFloat(
                                            parseFloat(
                                              option.percentage?.toFixed(2) || "0"
                                            ).toString()
                                          )) + "% ", 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1024)
                                                                    ])
                                                                  ])
                                                                ]))
                                                              : _createCommentVNode("", true)
                                                          ])
                                                        ], 8, _hoisted_29))
                                                      : (_openBlock(), _createElementBlock("div", {
                                                          key: 1,
                                                          class: "col-span-1 rounded-[6px] bg-white flex flex-col cursor-pointer",
                                                          onClick: _withModifiers(($event: any) => (_ctx.handlePollAnswer(option.slug || '')), ["stop"])
                                                        }, [
                                                          _createVNode(_component_app_image_loader, {
                                                            photoUrl: option.image_url || '',
                                                            class: "h-[120px] mdlg:!h-[150px] rounded-t-[6px] w-full",
                                                            onLoaded: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadedImages++))
                                                          }, null, 8, ["photoUrl"]),
                                                          _createElementVNode("div", _hoisted_36, [
                                                            _createElementVNode("span", _hoisted_37, _toDisplayString(option.value), 1),
                                                            (_ctx.hasAnsweredPoll)
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                                                  _createElementVNode("div", {
                                                                    class: "absolute top-0 left-0 h-full flex items-center justify-center bg-primary-100 rounded-bl-[6px] rounded-br-[6px]",
                                                                    style: _normalizeStyle(`width: ${option.percentage}%`)
                                                                  }, null, 4),
                                                                  _createElementVNode("div", _hoisted_39, [
                                                                    _createElementVNode("span", _hoisted_40, [
                                                                      _createVNode(_component_app_normal_text, { class: "!text-black !text-[10px] !font-semibold" }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(parseFloat(
                                          parseFloat(
                                            option.percentage?.toFixed(2) || "0"
                                          ).toString()
                                        )) + "% ", 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1024)
                                                                    ])
                                                                  ])
                                                                ]))
                                                              : _createCommentVNode("", true)
                                                          ])
                                                        ], 8, _hoisted_35))
                                                  ], 64))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true),
                                          (
                          item.polls?.answer_type === 'options' &&
                          item.polls.options.length > 2
                        )
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPollSummary, (option, index) => {
                                                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                                    (
                              item.polls?.option_type === 'plain' &&
                              item.polls.options.filter(
                                (eachitem) => eachitem.image_url
                              ).length == 0
                            )
                                                      ? (_openBlock(), _createElementBlock("button", {
                                                          key: 0,
                                                          class: "focus:outline-none col-span-2 py-3 bg-white rounded-[6px] flex justify-center items-center cursor-pointer",
                                                          onClick: _withModifiers(($event: any) => (_ctx.handlePollAnswer(option.slug || '')), ["stop"])
                                                        }, [
                                                          _createElementVNode("span", _hoisted_43, _toDisplayString(option.value), 1)
                                                        ], 8, _hoisted_42))
                                                      : (_openBlock(), _createElementBlock("div", {
                                                          key: 1,
                                                          class: "col-span-1 rounded-[6px] bg-white flex flex-col cursor-pointer",
                                                          onClick: _withModifiers(($event: any) => (_ctx.handlePollAnswer(option.slug || '')), ["stop"])
                                                        }, [
                                                          _createVNode(_component_app_image_loader, {
                                                            photoUrl: option.image_url || '',
                                                            class: "h-[120px] mdlg:!h-[150px] rounded-t-[6px] w-full",
                                                            onLoaded: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loadedImages++))
                                                          }, null, 8, ["photoUrl"]),
                                                          _createElementVNode("div", _hoisted_45, [
                                                            _createElementVNode("span", _hoisted_46, _toDisplayString(option.value), 1),
                                                            (_ctx.hasAnsweredPoll)
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                                                  _createElementVNode("div", {
                                                                    class: "absolute top-0 left-0 h-full flex items-center justify-center bg-primary-100 rounded-bl-[6px] rounded-br-[6px]",
                                                                    style: _normalizeStyle(`width: ${option.percentage}%`)
                                                                  }, null, 4),
                                                                  _createElementVNode("div", _hoisted_48, [
                                                                    _createElementVNode("span", _hoisted_49, [
                                                                      _createVNode(_component_app_normal_text, { class: "!text-black !text-[10px] !font-semibold" }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(parseFloat(
                                          parseFloat(
                                            option.percentage?.toFixed(2) || "0"
                                          ).toString()
                                        )) + "% ", 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1024)
                                                                    ])
                                                                  ])
                                                                ]))
                                                              : _createCommentVNode("", true)
                                                          ])
                                                        ], 8, _hoisted_44))
                                                  ], 64))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("div", _hoisted_50, [
                                            (!_ctx.hasAnsweredPoll)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_51, " Vote to see poll result "))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_52, _toDisplayString(_ctx.numberToAbbrev(
                              _ctx.pollSummary.reduce((acc, curr) => acc + curr.count, 0)
                            )) + " Vote" + _toDisplayString(_ctx.pollSummary.reduce((acc, curr) => acc + curr.count, 0) > 1
                              ? "s"
                              : ""), 1))
                                          ])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 4))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.isFullScreen)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_53))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["data", "isVideo", "isFullScreen", "style"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _createVNode(_Transition, { name: "slide-out-bottom" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "bottom-section", {}, undefined, true)
      ]),
      _: 3
    })
  ], 10, _hoisted_1))
}