<template>
  <app-wrapper>
    <dashboard-layout :is-home="true" :pageKey="'home'">
      <div class="w-full flex flex-col space-y-4 pt-2">
        <!-- Options -->
        <div
          class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center"
        >
          <div class="flex flex-row space-x-3 py-2 pr-4">
            <div
              :class="`flex flex-col space-y-1 items-center justify-center w-[65px]  mdlg:!w-[75px] cursor-pointer ${
                option.title == 'Snap' || option.title == 'Pay' ? 'mdlg:!hidden' : ''
              }`"
              v-for="(option, index) in options"
              :key="index"
              @click="option.action()"
            >
              <app-icon :name="option.icon" :customClass="'!h-[65px] mdlg:!h-[75px]'" />
              <app-normal-text
                class="!text-[11px] text-center !whitespace-nowrap dark:!text-white"
              >
                {{ option.title }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <!-- Exclusive Savings for you-->
        <div class="w-full flex flex-col space-y-2 pt-3">
          <div class="w-full flex flex-row items-center justify-between">
            <app-header-text :customClass="'!text-left px-4 !text-base !font-semibold'">
              Exclusive Savings for you
            </app-header-text>

            <app-normal-text
              class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase pr-4"
              @click="
                setStoryContent(ContentType.RecommendedForYou, {
                  id: 0,
                  type: 'collection',
                })
              "
            >
              See all >
            </app-normal-text>
          </div>
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 py-1 pr-4">
              <app-shoplist-recommended
                :item="item"
                v-for="(item, index) in recommendedShopLists"
                @click="
                  setStoryContent(ContentType.RecommendedForYou, {
                    id: parseInt(item.id),
                    type: 'collection',
                  })
                "
                :key="index"
              />

              <div
                class="flex flex-col space-y-3 h-full justify-start"
                v-if="showRecommendationIsLoading"
              >
                <div class="w-auto h-[10px] skeleton rounded"></div>
                <div class="!h-[210px] w-[165px] skeleton !rounded-[7.9px]"></div>
              </div>
            </div>

            <div id="visibility-handle-recommended"></div>
          </div>
        </div>

        <!-- Featured Shops -->
        <div class="w-full flex flex-col space-y-2 pb-4">
          <div class="w-full flex flex-row items-center justify-between px-4">
            <app-header-text :customClass="'!text-left  !text-base !font-semibold'">
              Featured Brands
            </app-header-text>
            <app-normal-text
              class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
              @click="
                Logic.Common.GoToRoute(
                  `/shoplist/brands?key=${
                    selectedCategory == 'All' ? '0' : selectedCategory
                  }`
                )
              "
            >
              See all >
            </app-normal-text>
          </div>

          <div class="w-full flex flex-col" v-if="ManyBusinesses">
            <app-virtual-scroller
              :container-class="'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2'"
              :data="ManyBusinesses.data"
              :pagination="ManyBusinesses.paginatorInfo"
              :fetchMore="fetchMoreBusinesses"
              :direction="'horizontal'"
            >
              <template #item-content="{ index, item }">
                <app-business :item="item" :key="index" />
              </template>
              <template #skeleton-loaders>
                <div class="w-[165px] mdlg:!w-[240px]">
                  <div
                    class="!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                  ></div>
                </div>
                <div class="w-[165px] mdlg:!w-[240px]">
                  <div
                    class="!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                  ></div>
                </div>
              </template>
            </app-virtual-scroller>
          </div>
        </div>

        <!-- Cashback Savings Deals -->
        <div class="w-full flex flex-col space-y-2 pb-4">
          <div class="w-full flex flex-row items-center justify-between px-4">
            <app-header-text :customClass="'!text-left  !text-base !font-semibold'">
              Cashback Savings Deals
            </app-header-text>
            <app-normal-text
              class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
              @click="
                Logic.Common.GoToRoute(
                  `/shoplist/featured?key=${
                    selectedCategory == 'All' ? '0' : selectedCategory
                  }`
                )
              "
            >
              See all >
            </app-normal-text>
          </div>

          <div class="w-full flex flex-col" v-if="sponsoredShoplists">
            <app-virtual-scroller
              :container-class="'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2'"
              :data="sponsoredShoplists.data"
              :pagination="sponsoredShoplists.paginatorInfo"
              :fetchMore="fetchMoreSponsoredShoplists"
              :direction="'horizontal'"
            >
              <template #item-content="{ index, item }">
                <div>
                  <app-shoplist-sponsored
                    :item="item"
                    :key="index"
                    @click="
                      setStoryContent(ContentType.SponsoredShoplist, {
                        id: parseInt(item.id),
                        type: item.type,
                      })
                    "
                  />
                </div>
              </template>
              <template #skeleton-loaders>
                <div class="!h-[230px] w-[270px] skeleton !rounded-[10px]"></div>
                <div class="!h-[230px] w-[270px] skeleton !rounded-[10px]"></div>
              </template>
            </app-virtual-scroller>
          </div>
        </div>

        <!-- Most Popular -->
        <div class="w-full flex flex-col space-y-4 pt-2">
          <div class="w-full flex flex-row items-center justify-between px-4">
            <app-header-text class="!text-base !font-semibold">
              Most Popular
            </app-header-text>

            <app-normal-text
              class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
              @click="Logic.Common.GoToRoute(`/shoplist/products?key=All`)"
            >
              See all >
            </app-normal-text>
          </div>

          <!-- Top shoplists by category -->
          <div class="w-full grid grid-cols-2 gap-3 !gap-y-6 px-4">
            <app-shoplist-explore
              v-for="(item, index) in firstExploreShoplist"
              :key="index"
              :item="item"
              @click="handleShoplsistClick(item)"
            />

            <template v-if="showExploreIsLoading">
              <div
                class="col-span-1 flex flex-col space-y-2 h-[180px] mdlg:!h-[250px] md:!h-[250px] rounded-[10px] skeleton"
              ></div>
              <div
                class="col-span-1 flex flex-col space-y-2 h-[180px] mdlg:!h-[250px] md:!h-[250px] rounded-[10px] skeleton"
              ></div>
            </template>

            <div class="col-span-2" id="visibility-handle-offers"></div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppIcon,
  AppNormalText,
  AppHeaderText,
  AppShoplistRecommended,
  AppShoplistExplore,
  AppVirtualScroller,
  AppBusiness,
  AppShoplistSponsored,
} from "@shpt/ui-components";
import {
  getMediaBox,
  getMediaBoxForProduct,
  numberToAbbrev,
  onRecommendedScrolledToEnd,
  shoppingCategories,
  showExploreIsLoading,
  showRecommendationIsLoading,
  fetchMoreSponsoredShoplists,
} from "@shpt/ui-components/src/composable";
import { Advert, ContentType } from "@shpt/logic/src/gql/graphql";
import { Collection, ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { MediaBox } from "@shpt/ui-components/src/types";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import AppWrapper from "@/components/AppWrapper.vue";
import { PaginatorInfo } from "@shpt/logic/dist/esm/gql/graphql";
import { computed } from "vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppWrapper,
    AppShoplistRecommended,
    AppShoplistExplore,
    AppVirtualScroller,
    AppBusiness,
    AppShoplistSponsored,
  },
  name: "HomePage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "WhatsNewContent",
        method: "GetUserContent",
        params: [
          "",
          [
            ContentType.WhatsNew,
            ContentType.Favorite,
            ContentType.Limited,
            ContentType.Explore,
            ContentType.RecommendedForYou,
            ContentType.SponsoredShoplist,
          ],
          1,
          6,
        ],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ParentCategories",
        method: "GetParentCategories",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyBusinesses",
        method: "GetBusiness",
        params: [1, 10],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Home Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "For You",
    });

    const slidePosition = ref(0);

    const currentSlidePosition = ref(0);

    const selectedCategory = ref("All");

    const categoryNames = reactive<string[]>([]);

    const RecommendedForYouContent = ref(Logic.User.RecommendedForYouContent);
    const ManyBrandsByCategory = ref(Logic.Shoplist.ManyBrandsByCategory);
    const ManyBusinesses = ref(Logic.Shoplist.ManyBusinesses);
    const SponsoredShoplistContent = ref(Logic.User.SponsoredShoplistContent);
    const ExploreContent = ref(Logic.User.ExploreContent);

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
    });

    const options = reactive([
      {
        title: "Snap",
        icon: "options/snap",
        action: () => {
          Logic.Common.GoToRoute("/snap");
        },
      },
      {
        title: "Pay",
        icon: "options/pay",
        action: () => {
          Logic.Common.GoToRoute("/pay/merchant");
        },
      },
      {
        title: "What's New",
        icon: "options/whats-new",
        action: () => {
          setStoryContent(ContentType.WhatsNew, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Favorite",
        icon: "options/favourite",
        action: () => {
          setStoryContent(ContentType.Favorite, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Limited",
        icon: "options/limited",
        action: () => {
          setStoryContent(ContentType.Limited, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Explore",
        icon: "options/explore",
        action: () => {
          setStoryContent(ContentType.Explore, {
            id: 0,
            type: "collection",
          });
        },
      },
    ]);

    const sponsoredShoplists = reactive<{
      data: {
        data: MediaBox;
        id: string;
        type: "collection" | "ad";
      }[];
      paginatorInfo: PaginatorInfo | undefined;
    }>({
      data: [],
      paginatorInfo: undefined,
    });

    const recommendedShopLists = reactive<
      {
        data: MediaBox;
        id: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);
    const exploreShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const firstExploreShoplist = computed(() => {
      const exploreShoplistsBase = JSON.parse(JSON.stringify(exploreShoplists));

      return exploreShoplistsBase.slice(0, 6);
    });

    const authUser = ref(Logic.Auth.AuthUser);

    const setCategory = () => {
      categoryNames.length = 0;
      categoryNames.push("All");

      categoryNames.push(...shoppingCategories().map((item) => item.name));
    };

    const setShoplistColors = (focus: "recommended" | "explore") => {
      if (focus == "recommended") {
        // recommendedShopLists.forEach((item) => {
        //   Logic.Common.getMostFrequentColorFromURL(item.data.base_image).then(
        //     (color) => {
        //       if (color) {
        //         const fullColor = getColors(color);
        //         item.colors.bg = fullColor.bgColor;
        //         item.colors.text = fullColor.textColor;
        //       }
        //     }
        //   );
        // });
      }
    };

    const setSponsoredShoplists = () => {
      sponsoredShoplists.data.length = 0;

      sponsoredShoplists.paginatorInfo = {
        count: 0,
        currentPage: Logic.User.SponsoredShoplistContent?.current_page || 0,
        hasMorePages: true,
        lastPage: 0,
        perPage: 6,
        total: Logic.User.SponsoredShoplistContent?.total_pages || 0,
      };

      SponsoredShoplistContent.value?.contents.forEach((item) => {
        const adContent: Advert = item.data as Advert;

        let adType: any = adContent.type;
        if (item.type == "ad" && adType == "shoppable") {
          const shoplistData = (item.data as Advert).advert_media?.shoplist;
          const productData = (item.data as Advert).advert_media?.product;
          if (shoplistData) {
            const mediaBox = getMediaBox(shoplistData);

            sponsoredShoplists.data.push({
              data: mediaBox,
              id: item.id.toString(),
              type: "ad",
            });
          } else if (productData) {
            const mediaBox = getMediaBoxForProduct(productData as ShoplistProduct);
            if (mediaBox) {
              sponsoredShoplists.data.push({
                data: mediaBox,
                id: item.id.toString(),
                type: "ad",
              });
            }
          }
        } else if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          sponsoredShoplists.data.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
          });
        }
      });
    };

    const setRecommendedShoplists = () => {
      recommendedShopLists.length = 0;

      RecommendedForYouContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          recommendedShopLists.push({
            data: mediaBox,
            id: item.id.toString(),
            colors: {
              bg: "",
              text: "",
            },
          });
        }
      });

      setShoplistColors("recommended");
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(`/shoplist/product/${item.data.product_data?.uuid}`);
      }
    };

    const fetchMoreBusinesses = (nextPage: number) => {
      return Logic.Shoplist.GetBusiness(nextPage, 10, true)
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyBusinesses)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyBusinesses = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const askForNotification = (callBack: () => void) => {
      if (localStorage.getItem("onboarding_notification_prompted")) {
        callBack();
        return;
      }
      localStorage.setItem("onboarding_notification_prompted", "true");
      Logic.Common.showModal({
        show: true,
        title: "Get Notified",
        type: "ask_for_permission",
        extraData: {
          info: "Be the first to know about top cashback deals and rewards",
          cta_copy: "Enable Notification",
          icon: "notification-badge",
        },
        action: async (skip = false) => {
          if (skip) {
            Logic.Common.showModal({ show: false });
            callBack();
            return;
          }
          try {
            await Logic.Notification.registerNotifications();
          } catch (error) {
            //
          }

          Logic.Common.showModal({ show: false });
          callBack();
        },
      });
    };

    const setExploreShoplists = () => {
      exploreShoplists.length = 0;

      ExploreContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          exploreShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
        if (item.type == "product") {
          const mediaBox = getMediaBoxForProduct(item.data as ShoplistProduct);
          if (mediaBox) {
            exploreShoplists.push({
              data: mediaBox,
              id: item.id.toString(),
              type: "product",
              colors: {
                bg: "",
                text: "",
              },
            });
          }
        }
      });
    };

    const createRecommendedForYouObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRecommendedScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(`visibility-handle-recommended`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    // const createExploreShoplistsObserver = () => {
    //   const options = {
    //     root: null, // Use the viewport as the root
    //     threshold: 1, // Trigger when 10% of the target is visible
    //   };

    //   const observer = new IntersectionObserver(
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     onExploreShoplistsScrolledToEnd,
    //     options
    //   );
    //   const targetElement = document.getElementById(`visibility-handle-offers`);
    //   if (targetElement) {
    //     observer.observe(targetElement);
    //   }
    // };

    onIonViewWillEnter(() => {
      setCategory();
    });

    onIonViewDidEnter(() => {
      askForNotification(() => {
        Logic.Game.CheckGameStatus();
      });
    });

    const setBrandsByCategory = () => {
      Logic.Shoplist.ManyBusinesses = ManyBrandsByCategory.value;
    };

    watch(RecommendedForYouContent, () => {
      setRecommendedShoplists();
    });

    watch(ExploreContent, () => {
      setExploreShoplists();
    });

    watch(ManyBrandsByCategory, () => {
      setBrandsByCategory();
    });

    watch(SponsoredShoplistContent, () => {
      setSponsoredShoplists();
    });

    onMounted(() => {
      setCategory();
      Logic.User.watchProperty("RecommendedForYouContent", RecommendedForYouContent);
      Logic.User.watchProperty("ExploreContent", ExploreContent);
      // Logic.Shoplist.watchProperty(
      //   "ManyBrandsByCategory",
      //   ManyBrandsByCategory
      // );
      Logic.User.watchProperty("SponsoredShoplistContent", SponsoredShoplistContent);
      Logic.Shoplist.watchProperty("ManyBusinesses", ManyBusinesses);
      setRecommendedShoplists();
      setExploreShoplists();
      setTimeout(() => {
        createRecommendedForYouObserver();
        // createExploreShoplistsObserver();
      }, 200);
      setSponsoredShoplists();

      if (Logic.Common.currentBuildType() == "web") {
        Logic.Game.CheckGameStatus();
      }
    });

    return {
      Logic,
      authUser,
      options,
      slidePosition,
      currentSlidePosition,
      categoryNames,
      selectedCategory,
      recommendedShopLists,
      exploreShoplists,
      showRecommendationIsLoading,
      showExploreIsLoading,
      ContentType,
      ManyBusinesses,
      sponsoredShoplists,
      firstExploreShoplist,
      fetchMoreBusinesses,
      setStoryContent,
      handleShoplsistClick,
      numberToAbbrev,
      fetchMoreSponsoredShoplists,
    };
  },
});
</script>
