<template>
  <div class="w-full flex flex-col space-y-3 pt-2">
    <div
      class="w-full py-4 px-4 bg-grey-50 dark:bg-gray-900 rounded-[12px] items-center justify-center space-y-1 flex flex-col"
    >
      <app-normal-text :color="'text-black'">
        {{ data?.payment_tag }}
      </app-normal-text>
      <app-header-text :custom-class="'!font-extrabold !text-xl'">
        {{ Logic.Common.convertToMoney(data?.amount || 0, true, "ngn") }}
      </app-header-text>
    </div>

    <!-- Available accounts -->
    <div class="w-full flex flex-col space-y-2">
      <!-- <div class="w-full flex flex-row">
        <app-normal-text :class="'!font-semibold !text-black'">
          Select wallet to pay
        </app-normal-text>
      </div> -->
      <div
        class="w-full flex flex-col rounded-[12px] border-[1px] border-gray-200 dark:border-gray-700"
      >
        <!-- Cashback account -->
        <div
          v-if="!data?.from_bank_payment"
          class="w-full flex flex-row items-center justify-between px-3 py-3"
          @click="useCashPoint ? (useCashPoint = false) : (useCashPoint = true)"
        >
          <div class="flex flex-row space-x-2 items-center">
            <!-- <app-icon
              :name="
                useCashPoint
                  ? `selected${Logic.Common.currentAppTheme() == 'light' ? '' : '-white'}`
                  : 'not-selected'
              "
              :customClass="'h-[17px]'"
            /> -->
            <app-normal-text :color="'text-black'"> Cashback Balance </app-normal-text>
          </div>
          <app-normal-text
            :color="'text-black'"
            :customClass="'!font-semibold flex flex-row items-center'"
          >
            {{ Logic.Common.convertToMoney(UserWallet?.point_balance || 0, true, "ngn") }}
            <span v-if="loadingUpdateWallet" class="pl-2">
              <app-loading-state />
            </span>
          </app-normal-text>
        </div>
      </div>
    </div>

    <div
      v-if="(data?.type != 'bank_transfer' || getFreeTransfer() == 0) && !useCashPoint"
      class="w-full py-4 px-4 bg-grey-50 dark:bg-gray-900 rounded-[12px] items-center justify-between space-x-1 flex flex-row"
    >
      <app-normal-text :customClass="'!font-extrabold'" :color="'text-black'">
        Fee
      </app-normal-text>
      <app-normal-text v-if="data?.type != 'bank_transfer'">
        {{ Logic.Common.convertToMoney(0, false, "ngn") }}
      </app-normal-text>
      <app-normal-text v-else>
        {{ Logic.Common.convertToMoney(getTransferFee(data?.amount || 0), true, "ngn") }}
      </app-normal-text>
    </div>

    <!-- Free transfer -->
    <div
      v-if="data?.type == 'bank_transfer' && getFreeTransfer() > 0 && !useCashPoint"
      class="w-full py-4 px-4 rounded-[12px] items-center justify-between space-x-1 flex flex-row"
    >
      <app-normal-text :customClass="'!font-extrabold'" :color="'text-black'">
        Free transfers left
      </app-normal-text>
      <app-normal-text>
        {{ getFreeTransfer() }}
      </app-normal-text>
    </div>

    <div
      class="w-full flex flex-col rounded-[14px] border-[1px] border-shop_deep_purple_100 dark:border-gray-700"
      v-if="selectedMethod == 'wallet' && showWalletAccount && !useCashPoint"
    >
      <!-- Wallet -->
      <div class="py-4 flex flex-col space-y-3 px-3">
        <!-- Account details -->
        <div
          class="w-full flex flex-col space-y-3 px-0 py-3 bg-grey-50 dark:bg-gray-900 rounded-[14px]"
        >
          <template v-if="UserWallet?.wallet_account?.account_name">
            <app-normal-text
              :customClass="'w-full px-4  pb-2   border-b-[1px] border-opacity-30 border-[#BDA3D7]'"
              :color="'text-black'"
            >
              Fund wallet
            </app-normal-text>
            <div class="px-4 flex flex-row items-center space-x-2 justify-between">
              <div class="flex flex-col space-y-1">
                <app-normal-text :color="'text-primary-400 dark:text-primary-100'">
                  Bank Name
                </app-normal-text>
                <app-normal-text :color="'text-black !font-semibold dark:text-white'">
                  {{ UserWallet?.wallet_account?.bank_name || "" }}
                </app-normal-text>
              </div>
            </div>

            <div class="px-4 flex pb-4 flex-row items-center space-x-2 justify-between">
              <div class="flex flex-col space-y-1">
                <app-normal-text :color="'text-primary-400 dark:text-primary-100'">
                  Account Number
                </app-normal-text>
                <app-normal-text :color="'text-black !font-semibold dark:text-white'">
                  {{ UserWallet?.wallet_account?.account_number || "" }}
                </app-normal-text>
              </div>

              <app-button
                :padding="'px-3 py-2'"
                :border="'border-b-[3px]'"
                :customClass="'!flex flex-row space-x-2 items-center'"
                @click="
                  copyAccountNumber(UserWallet?.wallet_account?.account_number || '')
                "
              >
                {{ accountCopied ? "Copied!" : "Copy" }}
                <app-icon :name="'copy-white'" :customClass="'h-[17px] pl-2'" />
              </app-button>
            </div>

            <div class="w-full flex flex-col px-4">
              <app-normal-text :color="'text-primary-400 dark:text-primary-100'">
                Money transfer made might take up to 5mins to reflect
              </app-normal-text>
            </div>
          </template>
          <template v-else>
            <div
              class="w-full flex flex-col space-y-3 h-[100px] justify-center items-center px-4"
            >
              <app-normal-text :customClass="'text-center'">
                Setup your wallet account and fund your Shoppoint wallet with bank
                transfer.
              </app-normal-text>
              <div class="w-full flex flex-row items-center justify-center">
                <app-button
                  :padding="'px-4 py-1'"
                  :border="'border-b-[2px]'"
                  @click="goToSetupWallet()"
                >
                  Set up wallet account
                </app-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col pt-1 xs:pb-3 pb-4">
      <app-button
        :customClass="'w-full'"
        :padding="'py-3'"
        @click="continuePayment()"
        :is-disabled="loadingUpdateWallet"
      >
        Continue
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AppNavCard from "../AppNavCard";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppCheckbox } from "../AppForm";
import { User } from "../../gql/graphql";
import AppLoadingState from "../AppLoadingState";

export default defineComponent({
  components: {
    AppNavCard,
    AppIcon,
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppCheckbox,
    AppLoadingState,
  },
  props: {
    data: {
      type: Object as () => {
        payment_tag: string;
        amount: number;
        type?: string;
        from_bank_payment?: boolean;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  name: "AppModalPayment",
  setup(props) {
    // Spending account deprecated

    const selectedMethod = ref("wallet");

    const AuthUser = ref<User | undefined>(Logic.Auth.AuthUser);
    const UserWallet = ref(Logic.Wallet.UserWallet);

    const loadingUpdateWallet = ref(false);

    const showWalletAccount = ref(false);

    const accountCopied = ref(false);

    const amountRemaining = ref(0);

    const useCashPoint = ref(false);

    const hasSufficientBalance = ref(false);

    const showAddCard = () => {
      Logic.Common.showModal({
        show: true,
        title: "Add a new card",
        type: "add_card",
        actionLabel: "Add",
        validateForm: true,
        action: (data: any) => {
          console.log(data);
        },
      });
    };

    const getUpdateWallet = () => {
      loadingUpdateWallet.value = true;
      Logic.Wallet.GetUserWallet()
        .then((response) => {
          loadingUpdateWallet.value = false;
        })
        .catch((error) => {
          Logic.Common.showAlert({
            show: true,
            type: "error",
            message: "Unable to get updated wallet balance",
          });
          loadingUpdateWallet.value = false;
        });
    };

    const setWalletState = () => {
      if (props.data) {
        useCashPoint.value = false;
        hasSufficientBalance.value = false;
        const totalAmount = props.data.amount;

        const cashpointBalance = UserWallet.value?.point_balance || 0;

        if (cashpointBalance >= totalAmount) {
          hasSufficientBalance.value = true;
        } else {
          amountRemaining.value = totalAmount - cashpointBalance;
        }

        if (!UserWallet.value?.spending_account_upgraded) {
          if (props.data.from_bank_payment) {
            useCashPoint.value = true;
          } else {
            useCashPoint.value = true;
          }
        } else {
          const totalAvailableMoney = UserWallet.value?.total_balance || 0;

          if (totalAmount > totalAvailableMoney) {
            if (cashpointBalance > totalAmount) {
              useCashPoint.value = true;
            } else {
              showWalletAccount.value = false;
            }
          } else {
            showWalletAccount.value = false;
          }
        }
      }
    };

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Wallet.watchProperty("UserWallet", UserWallet);
      setWalletState();
      getUpdateWallet();
    });

    watch(useCashPoint, () => {
      if (!useCashPoint.value) {
        const totalAmount = props?.data?.amount || 0;

        const totalAvailableMoney = UserWallet.value?.total_balance || 0;

        if (totalAmount > totalAvailableMoney) {
          showWalletAccount.value = false;
        } else {
          showWalletAccount.value = false;
        }
      }
    });

    const goToSetupWallet = () => {
      Logic.Common.showModal({ show: false });
      Logic.Common.GoToRoute("/wallet/create-wallet-account");
    };

    const continuePayment = () => {
      if (hasSufficientBalance.value) {
        props.action({
          amount_sufficient: true,
        });
      } else {
        Logic.Common.showModal({
          show: true,
          title: "Complete Payment",
          type: "payment_provider",
          extraData: {
            amount: amountRemaining.value,
            currency: "NGN",
            email: Logic.Auth.AuthUser?.email,
            user_uuid: Logic.Auth.AuthUser?.uuid,
            wallet_uuid: UserWallet.value?.uuid,
            type: "point",
            narration: "Shoppoint Voucher Purchase",
          },
          preventClose: true,
          action: () => {
            props.action({
              amount_sufficient: true,
            });
          },
          closeAction: () => {
            Logic.Common.showModal({
              show: false,
            });
          },
        });
      }
    };

    const copyAccountNumber = (text: string) => {
      accountCopied.value = true;
      Logic.Common.copytext(text);
      setTimeout(() => {
        accountCopied.value = false;
      }, 3000);
    };

    const getFreeTransfer = () => {
      const planFreeTransfers =
        AuthUser.value?.profile?.subscription_plan.free_transfer_per_month || 0;
      const earnedFreeTransfers = AuthUser.value?.wallet?.free_transfers || 0;
      const usedFreeTransferThisMonth = AuthUser.value?.current_month_free_transfers || 0;

      let availableFreeTransfer = 0;

      if (usedFreeTransferThisMonth < planFreeTransfers + earnedFreeTransfers) {
        availableFreeTransfer =
          planFreeTransfers + earnedFreeTransfers - usedFreeTransferThisMonth;
      }

      return availableFreeTransfer;
    };

    const getTransferFee = (amount: number) => {
      let fee = 0;
      if (amount <= 5000) {
        fee = 25;
      }
      if (amount > 5000 && amount <= 30000) {
        fee = 35;
      }
      if (amount > 30000 && amount < 50000) {
        fee = 50;
      }
      if (amount >= 50000) {
        fee = 50;
      }
      return fee;
    };

    return {
      selectedMethod,
      Logic,
      AuthUser,
      useCashPoint,
      showWalletAccount,
      accountCopied,
      hasSufficientBalance,
      UserWallet,
      loadingUpdateWallet,
      showAddCard,
      goToSetupWallet,
      continuePayment,
      copyAccountNumber,
      getTransferFee,
      getFreeTransfer,
    };
  },
});
</script>
