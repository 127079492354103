import { CombinedError } from 'urql'
import {
  BusinessPaginator,
  Category,
  CategoryPaginator,
  Collection,
  CollectionPaginator,
  DeliveryLocationPaginator,
  InstagramMediaData,
  MutationAddRemoveCollectionProductArgs,
  MutationCheckIfMediaAccountIsConnectedArgs,
  MutationCheckIfProductIsAvailableInAreaArgs,
  MutationCreateBusinessDeliveryAreasArgs,
  MutationCreateShoplistCollectionArgs,
  MutationCreateShoplistProductArgs,
  MutationCreateShoplistProductRewardArgs,
  MutationCreateUserDeliveryLocationArgs,
  MutationImportCsvProductsArgs,
  MutationImportShopifyProductFromCsvArgs,
  MutationLikeUnlikeCollectionArgs,
  MutationLikeUnlikeProductArgs,
  MutationRequestShipmentRateArgs,
  MutationUpdateDeliveryLocationArgs,
  MutationUpdateShoplistCollectionArgs,
  MutationUpdateShoplistProductArgs,
  MutationUpdateShoplistProductRewardArgs,
  PackageDimension,
  ShippingCategory,
  ShippingRateResponse,
  ShopifyCollectionConnection,
  ShopifyProductConnection,
  ShoplistCart,
  ShoplistProduct,
  ShoplistProductPaginator,
  SupportedArea,
  WoocommerceResponse,
} from '../../gql/graphql'
import { $api } from '../../services'
import Common from './Common'
import { Logic } from '..'
import { Drivers, Storage } from '@ionic/storage'
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
export default class Shoplist extends Common {
  constructor() {
    super()

    this.Storage = new Storage({
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    })
    this.Storage.create().then(async () => {
      this.ParentCategories = JSON.parse(
        (await this.Storage.get('parent_categories')) || '{}',
      )
    })

    this.UserProductCart = JSON.parse(
      localStorage.getItem('user_product_cart') || '{}',
    )
  }

  // Default variables
  public ManyCategories: CategoryPaginator | undefined
  public ManySupportedAreas: SupportedArea[] | undefined
  public ManyDeliveryLocations: DeliveryLocationPaginator | undefined
  public ManyShoplistProducts: ShoplistProductPaginator | undefined
  public ManyShoplistCollections: CollectionPaginator | undefined
  public ParentCategories: Category[] | undefined
  public SingleShoplistProduct: ShoplistProduct | undefined
  public ManySimilarProducts: ShoplistProduct[] | undefined
  public CashbackOffersProducts: ShoplistProductPaginator | undefined
  public CashbackOffersCollections: CollectionPaginator | undefined
  public ManyShopifyProducts: ShopifyProductConnection | undefined
  public ManyShopifyCollections: ShopifyCollectionConnection | undefined
  public ManyWoocommerceProducts: WoocommerceResponse | undefined
  public SingleShoplistCollection: Collection | undefined
  public ManyCategoriesWithProducts: Category[] | undefined
  public ManyBrandsByCategory: BusinessPaginator | undefined
  public ManyCollectionsByCategory: CollectionPaginator | undefined
  public ManyUserShoplistCarts: ShoplistCart[] | undefined
  public ManyPackageDimensions: PackageDimension[] | undefined
  public ManyShippingCategory: ShippingCategory[] | undefined
  public ManyShippingRates: ShippingRateResponse[] | undefined
  public ManyBusinesses: BusinessPaginator | undefined
  public ManyShoplistsForBusiness: CollectionPaginator | undefined
  public InstagramAuthUrl: string | undefined
  public InstagramMedia: InstagramMediaData | undefined
  public UserProductCart: Collection | undefined
  public ManyShoplistBrands: BusinessPaginator | undefined
  public SearchResults:
    | {
        collections: CollectionPaginator | undefined
        products: ShoplistProductPaginator | undefined
        businesses: BusinessPaginator | undefined
      }
    | undefined
  public Storage: Storage

  // Mutation forms
  public CreateBusinessDeliveryAreasForm:
    | MutationCreateBusinessDeliveryAreasArgs
    | undefined
  public UpdateDeliveryLocationForm:
    | MutationUpdateDeliveryLocationArgs
    | undefined
  public CreateShoplistProductForm:
    | MutationCreateShoplistProductArgs
    | undefined
  public UpdateShoplistProductForm:
    | MutationUpdateShoplistProductArgs
    | undefined
  public UpdateShoplistProductRewardForm:
    | MutationUpdateShoplistProductRewardArgs
    | undefined
  public CreateShoplistProductRewardForm:
    | MutationCreateShoplistProductRewardArgs
    | undefined
  public CreateShoplistCollectionForm:
    | MutationCreateShoplistCollectionArgs
    | undefined
  public UpdateShoplistCollectionForm:
    | MutationUpdateShoplistCollectionArgs
    | undefined
  public CreateUserDeliveryLocationForm:
    | MutationCreateUserDeliveryLocationArgs
    | undefined
  public CheckIfProductIsAvailableInAreaForm:
    | MutationCheckIfProductIsAvailableInAreaArgs
    | undefined
  public RequestShipmentRateForm: MutationRequestShipmentRateArgs | undefined
  public LikeUnlikeCollectionForm: MutationLikeUnlikeCollectionArgs | undefined
  public CheckIfMediaAccountIsConnectedForm:
    | MutationCheckIfMediaAccountIsConnectedArgs
    | undefined
  public LikeUnlikeProductForm: MutationLikeUnlikeProductArgs | undefined
  public AddRemoveCollectionProductForm:
    | MutationAddRemoveCollectionProductArgs
    | undefined
  public ImportShopifyProductFromCSVForm:
    | MutationImportShopifyProductFromCsvArgs
    | undefined
  public ImportCSVProductsForm: MutationImportCsvProductsArgs | undefined

  // Queries
  public GetShoplistProductCategories = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
  ) => {
    return $api.shoplist
      .GetShoplistProductCategories(page, count, orderType, order, whereQuery)
      .then(async (response) => {
        this.ManyCategories = response.data?.GetShoplistProductCategories

        return response.data?.GetShoplistProductCategories
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetParentCategories = () => {
    return $api.shoplist
      .GetShoplistProductCategories(
        1,
        200,
        'CREATED_AT',
        'DESC',
        `{
      column: PARENT_CATEGORY_ID
      operator: EQ
      value: null
    }`,
      )
      .then(async (response) => {
        this.ManyCategories = response.data?.GetShoplistProductCategories
        this.ParentCategories = response.data?.GetShoplistProductCategories.data
        this.Storage.set(
          'parent_categories',
          JSON.stringify(response.data?.GetShoplistProductCategories.data),
        )

        return response.data?.GetShoplistProductCategories
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetCategoriesWithProducts = () => {
    return $api.shoplist
      .GetCategoriesWithProducts()
      .then(async (response) => {
        this.ManyCategoriesWithProducts =
          response.data?.GetCategoriesWithProducts
        return response.data?.GetCategoriesWithProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetUserShoplistCarts = () => {
    return $api.shoplist.GetUserCart().then(async (response) => {
      this.ManyUserShoplistCarts = response.data?.GetCart

      return response.data?.GetCart
    })
  }

  public GetWoocommerceAuthUrl = (store_url: string) => {
    return $api.shoplist
      .GetWoocommerceAuthUrl(store_url)
      .then(async (response) => {
        return response.data?.GetWoocommerceAuthUrl
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetShoplistBrands = (page: number, count: number) => {
    const whereQuery = `{
      column: IS_BRAND
      operator: EQ
      value: true
    }`
    return $api.shoplist
      .GetShoplistBrands(page, count, whereQuery)
      .then(async (response) => {
        this.ManyShoplistBrands = response.data?.GetShoplistBrands
        return response.data?.GetShoplistBrands
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetShopifyProducts = (
    first: number,
    after: string,
    query: string,
    isUpdate = false,
  ) => {
    return $api.shoplist
      .GetShopifyProducts(first, after, query)
      .then(async (response) => {
        if (!isUpdate) {
          this.ManyShopifyProducts = response.data?.GetShopifyProducts
        }
        return response.data?.GetShopifyProducts
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetShopifyCollections = (
    first: number,
    after: string,
    query: string,
    isUpdate = false,
  ) => {
    return $api.shoplist
      .GetShopifyCollections(first, after, query)
      .then(async (response) => {
        if (!isUpdate) {
          this.ManyShopifyCollections = response.data?.GetShopifyCollections
        }
        return response.data?.GetShopifyCollections
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetWoocommerceProducts = (
    page: string,
    per_page: number,
    isUpdate = false,
    search: string,
  ) => {
    return $api.shoplist
      .GetWoocommerceProducts(page, per_page, search)
      .then(async (response) => {
        if (!isUpdate) {
          this.ManyWoocommerceProducts = response.data?.GetWoocommerceProducts
        }
        return response.data?.GetWoocommerceProducts
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetUserProductCart = () => {
    return $api.shoplist.GetUserProductCart().then(async (response) => {
      this.UserProductCart = response.data?.GetUserCart
      localStorage.setItem(
        'user_product_cart',
        JSON.stringify(response.data?.GetUserCart),
      )

      return response.data?.GetUserCart
    })
  }

  public GetPackageDimension = () => {
    return $api.shoplist
      .GetPackageDimension()
      .then(async (response) => {
        this.ManyPackageDimensions = response.data?.GetPackageDimension

        return response.data?.GetPackageDimension
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShippingCategory = () => {
    return $api.shoplist
      .GetShippingCategory()
      .then(async (response) => {
        this.ManyShippingCategory = response.data?.GetShippingCategory

        return response.data?.GetShippingCategory
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetSupportAreas = () => {
    return $api.shoplist
      .GetSupportedAreas()
      .then(async (response) => {
        this.ManySupportedAreas = response.data?.GetSupportedAreas

        return response.data?.GetSupportedAreas
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetDeliveryLocation = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
  ) => {
    return $api.shoplist
      .GetDeliveryLocations(page, count, orderType, order, whereQuery)
      .then(async (response) => {
        this.ManyDeliveryLocations = response.data?.GetDeliveryLocation

        return response.data?.GetDeliveryLocation
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShoplistProducts = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    isSearch = false,
    affiliateable = '',
  ) => {
    return $api.shoplist
      .GetShoplistProducts(
        page,
        count,
        orderType,
        order,
        whereQuery,
        affiliateable,
      )
      .then(async (response) => {
        if (!isSearch) {
          this.ManyShoplistProducts = response.data?.GetShoplistProducts
        }
        return response.data?.GetShoplistProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShoplistProductsByCategoryFromKey = (key: string) => {
    let hasCategory = ''

    if (key != 'All') {
      hasCategory = `{
        column: UUID
        operator: EQ
        value: "${key}"
      }`
    }
    return $api.shoplist
      .GetShoplistProducts(1, 10, 'CREATED_AT', 'DESC', '', '', hasCategory)
      .then(async (response) => {
        this.ManyShoplistProducts = response.data?.GetShoplistProducts
        return response.data?.GetShoplistProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShoplistProductsByCategory = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    hasCategory?: string,
    isUpdate = false,
  ) => {
    return $api.shoplist
      .GetShoplistProducts(
        page,
        count,
        orderType,
        order,
        whereQuery,
        '',
        hasCategory,
      )
      .then(async (response) => {
        if (!isUpdate) {
          this.ManyShoplistProducts = response.data?.GetShoplistProducts
        }
        return response.data?.GetShoplistProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetBusiness = (page: number, count: number, isUpdate = false) => {
    return $api.shoplist
      .SearchBrands('', page, count)
      .then((response) => {
        if (!isUpdate) {
          this.ManyBusinesses = response.data?.SearchBrands
        }

        return response.data?.SearchBrands
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetBrandsByCategory = (
    category_id: number,
    page: number,
    count: number,
    isUpdate = false,
  ) => {
    return $api.shoplist
      .GetBrandsByCategory(parseInt(category_id.toString()), page, count)
      .then(async (response) => {
        if (!isUpdate) {
          this.ManyBrandsByCategory = response.data?.GetBrandsByCategory
        }

        return response.data?.GetBrandsByCategory
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetCashbackOffersCollections = (
    category_id: any,
    page: number,
    count: number,
    isUpdate = false,
  ) => {
    const categoryId = parseInt(category_id == 'All' ? '0' : category_id)
    return $api.shoplist
      .GetCollectionsByCategory(categoryId, page, count, 'most_rewards')
      .then(async (response) => {
        if (!isUpdate) {
          this.CashbackOffersCollections = undefined;
          this.CashbackOffersCollections =
            response.data?.GetCollectionsByCategory
        }
        return response.data?.GetCollectionsByCategory
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetCashbackOffersProducts = (
    category_id: any = 0,
    page: number,
    count: number,
    isUpdate = false,
  ) => {
    const categoryId = parseInt(category_id == 'All' ? '0' : category_id)
    return $api.shoplist
      .GetProductsByCategory(categoryId, page, count, 'most_rewards')
      .then(async (response) => {
        if (!isUpdate) {
          this.CashbackOffersProducts = response.data?.GetProductsByCategory;
        }
        return response.data?.GetProductsByCategory
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetCollectionsByCategory = (
    category_id: number = 0,
    page: number,
    count: number,
    isUpdate = false,
  ) => {
    return $api.shoplist
      .GetCollectionsByCategory(
        parseInt(category_id.toString()),
        page,
        count,
        'expire_date',
      )
      .then(async (response) => {
        if (!isUpdate) {
          this.ManyCollectionsByCategory =
            response.data?.GetCollectionsByCategory
        }

        return response.data?.GetCollectionsByCategory
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShoplistCollections = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    isSearch = false,
  ) => {
    return $api.shoplist
      .GetShoplistCollections(page, count, orderType, order, whereQuery)
      .then(async (response) => {
        if (!isSearch) {
          this.ManyShoplistCollections = response.data?.GetShoplistCollections
        }
        return response.data?.GetShoplistCollections
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShoplistsForBusiness = (
    uuid: string,
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    isSearch = false,
  ) => {
    return $api.shoplist
      .GetShoplistCollections(
        page,
        count,
        orderType,
        order,
        whereQuery,
        `{
         column: UUID
         operator: EQ
         value: "${uuid}"
        }
        `,
      )
      .then(async (response) => {
        if (!isSearch) {
          this.ManyShoplistsForBusiness = response.data?.GetShoplistCollections
        }

        return response.data?.GetShoplistCollections
      })
      .catch((_error: CombinedError) => {
        // Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetInstagramAuthUrl = () => {
    return $api.shoplist.GetInstagramAuthUrl().then(async (response) => {
      this.InstagramAuthUrl = response.data?.GetInstagramAuthUrl

      return response.data?.GetInstagramAuthUrl
    })
  }

  public GetUserInstagramMedia = (
    after: string = '',
    before: string = '',
    isUpdate = false,
  ) => {
    return $api.shoplist
      .GetUserInstagramMedia(after, before)
      .then(async (response) => {
        if (!isUpdate) {
          this.InstagramMedia = response.data?.GetUserInstagramMedia
        }

        return response.data?.GetUserInstagramMedia
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetShoplistProduct = (uuid: string) => {
    return $api.shoplist
      .GetShoplistProduct(uuid)
      .then(async (response) => {
        this.SingleShoplistProduct = response.data?.GetShoplistProduct

        if (this.ManyShoplistProducts) {
          const products = JSON.parse(JSON.stringify(this.ManyShoplistProducts))

          const productsData = products?.data.map((item: any) => {
            if (item.id == response.data?.GetShoplistProduct.id) {
              return response.data?.GetShoplistProduct
            } else {
              return item
            }
          })

          products.data = productsData

          this.ManyShoplistProducts = products
        }

        return response.data?.GetShoplistProduct
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetSimilarProducts = (uuid: string) => {
    return $api.shoplist
      .GetSimilarProducts(uuid)
      .then((response) => {
        this.ManySimilarProducts = response.data?.GetSimilarProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public SearchItems = (
    query: string,
    page: number,
    count: number,
    isUpdate = false,
  ) => {
    return Promise.all([
      $api.shoplist.SearchCollections(query, page, count),
      $api.shoplist.SearchProducts(query, page, count),
      $api.shoplist.SearchBrands(query, page, count),
    ])
      .then(async ([collectionsResponse, productsResponse, brandsResponse]) => {
        if (!isUpdate) {
          this.SearchResults = {
            collections: collectionsResponse.data?.SearchCollections,
            products: productsResponse.data?.SearchShoplistProducts,
            businesses: brandsResponse.data?.SearchBrands,
          }
        }

        return {
          collections: collectionsResponse.data?.SearchCollections,
          products: productsResponse.data?.SearchShoplistProducts,
          businesses: brandsResponse.data?.SearchBrands,
        }
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetShoplistCollection = (uuid: string) => {
    if (uuid == 'cart') {
      uuid = this.UserProductCart?.uuid || ''
    }
    return $api.shoplist
      .GetShoplistCollection(uuid)
      .then(async (response) => {
        this.SingleShoplistCollection = response.data?.GetShoplistCollection

        if (this.ManyShoplistCollections) {
          const collections = JSON.parse(
            JSON.stringify(this.ManyShoplistCollections),
          )

          const collectionData = collections?.data.map((item: any) => {
            if (item.id == response.data?.GetShoplistCollection.id) {
              return response.data?.GetShoplistCollection
            } else {
              return item
            }
          })

          this.ManyShoplistCollections = collections

          collections.data = collectionData
          this.ManyShoplistCollections = collections
        }

        const allDataToUpdate = [
          'WhatsNewContent',
          'FavoriteContent',
          'LimitedContent',
          'RecommendedForYouContent',
          'ExploreContent',
          'SponsoredShoplistContent',
          'BannerAdsContent',
          'MerchantShoplistsContent',
        ]

        allDataToUpdate.forEach((baseData) => {
          // @ts-ignore
          if (Logic.User[baseData]) {
            // @ts-ignore
            Logic.User[baseData].contents.forEach((item) => {
              if (
                item.id ==
                  parseInt(response.data?.GetShoplistCollection?.id || '0') &&
                item.type == 'collection'
              ) {
                item.data = response.data?.GetShoplistCollection
              } else if (item.type == 'ad') {
                if (item.data?.type == 'shoppable') {
                  item.data.advert_media.shoplist =
                    response.data?.GetShoplistCollection
                }
              }
            })
          }
        })

        return response.data?.GetShoplistCollection
      })
      .catch((_error: CombinedError) => {
        throw _error
        // Logic.Common.showError(error, 'Oops!')
      })
  }

  // Mutations

  public CreateBusinessDeliveryAreas = () => {
    if (this.CreateBusinessDeliveryAreasForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.shoplist
        .CreateBusinessDeliveryAreas(this.CreateBusinessDeliveryAreasForm)
        .then((response) => {
          Logic.Common.hideLoader()
          return response.data?.CreateBusinessDeliveryAreas
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public ImportShopifyProducts = (product_ids: string[]) => {
    return $api.shoplist
      .ImportShopifyProducts(product_ids)
      .then((response) => {
        return response.data?.ImportShopifyProducts
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public ImportWooCommerceProducts = (product_ids: number[]) => {
    return $api.shoplist
      .ImportWooCommerceProducts(product_ids)
      .then(async (response) => {
        return response.data?.ImportWooCommerceProducts
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public ImportShopifyProductFromCSV = () => {
    if (this.ImportShopifyProductFromCSVForm) {
      return $api.shoplist
        .ImportShopifyProductFromCSV(this.ImportShopifyProductFromCSVForm)
        .then((response) => {
          return response.data?.ImportShopifyProductFromCSV
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public ImportCSVProducts = () => {
    if (this.ImportCSVProductsForm) {
      return $api.shoplist
        .ImportCSVProducts(this.ImportCSVProductsForm)
        .then((response) => {
          return response.data?.ImportCSVProducts
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CheckIfMediaAccountIsConnected = (callback: () => void) => {
    if (this.CheckIfMediaAccountIsConnectedForm) {
      return $api.shoplist
        .CheckIfMediaAccountIsConnected(this.CheckIfMediaAccountIsConnectedForm)
        .then(async (response) => {
          const state = response.data?.CheckIfMediaAccountIsConnected

          if (state) {
            callback()
          } else {
            setTimeout(() => {
              this.CheckIfMediaAccountIsConnected(callback)
            }, 1000)
          }
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public RequestShipmentRate = () => {
    if (this.RequestShipmentRateForm) {
      return $api.shoplist
        .RequestShipmentRate(this.RequestShipmentRateForm)
        .then((response) => {
          this.ManyShippingRates = response.data?.RequestShipmentRate
          return response.data?.RequestShipmentRate
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public LikeUnlikeCollection = () => {
    if (this.LikeUnlikeCollectionForm) {
      return $api.shoplist
        .LikeUnlikeCollection(this.LikeUnlikeCollectionForm)
        .then((response) => {
          return response.data?.LikeUnlikeCollection
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CreateUserDeliveryLocation = () => {
    if (this.CreateUserDeliveryLocationForm) {
      return $api.shoplist
        .CreateUserDeliveryLocation(this.CreateUserDeliveryLocationForm)
        .then((response) => {
          return response.data?.CreateUserDeliveryLocation
        })
        .catch((error: CombinedError) => {
          throw error
        })
    }
  }

  public UpdateDeliveryLocation = () => {
    if (this.UpdateDeliveryLocationForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.shoplist
        .UpdateDeliveryLocation(this.UpdateDeliveryLocationForm)
        .then((response) => {
          Logic.Common.hideLoader()
          return response.data?.UpdateDeliveryLocation
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CheckIfProductIsAvailableInArea = () => {
    if (this.CheckIfProductIsAvailableInAreaForm) {
      return $api.shoplist
        .CheckIfProductIsDeliverable(this.CheckIfProductIsAvailableInAreaForm)
        .then((response) => {
          return response.data?.CheckIfProductIsAvailableInArea
        })
    }
  }

  public DeleteDeliveryLocation = (delivery_location_id: string) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.shoplist
      .DeleteDeliveryLocation(delivery_location_id)
      .then((response) => {
        Logic.Common.hideLoader()
        return response.data?.DeleteDeliveryLocation
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public CreateShoplistProduct = () => {
    if (this.CreateShoplistProductForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.shoplist
        .CreateShoplistProduct(this.CreateShoplistProductForm)
        .then((response) => {
          this.SingleShoplistProduct = response.data?.CreateShoplistProduct
          Logic.Common.hideLoader()
          return response.data?.CreateShoplistProduct
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public LikeUnlikeProduct = () => {
    if (this.LikeUnlikeProductForm) {
      return $api.shoplist
        .LikeUnlikeProduct(this.LikeUnlikeProductForm)
        .then((response) => {
          return response.data?.LikeUnlikeProduct
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public AddRemoveCollectionProduct = () => {
    if (this.AddRemoveCollectionProductForm) {
      return $api.shoplist
        .AddRemoveCollectionProduct(this.AddRemoveCollectionProductForm)
        .then((response) => {
          return response.data?.AddRemoveCollectionProduct
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CreateShoplistProductReward = () => {
    if (this.CreateShoplistProductRewardForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.shoplist
        .CreateShoplistProductReward(this.CreateShoplistProductRewardForm)
        .then((response) => {
          Logic.Common.hideLoader()
          return response.data?.CreateShoplistProductReward
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CreateShoplistCollection = (
    progressCb: (progress: number) => void,
  ) => {
    if (this.CreateShoplistCollectionForm) {
      return $api.shoplist
        .CreateShoplistCollection(this.CreateShoplistCollectionForm, progressCb)
        .then((response) => {
          this.SingleShoplistCollection =
            response.data?.CreateShoplistCollection
          return response.data?.CreateShoplistCollection
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CreateUserShoplistCart = (collection_id: string) => {
    return $api.shoplist
      .CreateUserShoplistCart(collection_id)
      .then((response) => {
        this.GetUserShoplistCarts()

        return response.data?.CreateUserShoplistCart
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public UpdateShoplistProduct = () => {
    if (this.UpdateShoplistProductForm) {
      return $api.shoplist
        .UpdateShoplistProduct(this.UpdateShoplistProductForm)
        .then((response) => {
          this.SingleShoplistProduct = response.data?.UpdateShoplistProduct

          return response.data?.UpdateShoplistProduct
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public UpdateShoplistProductReward = () => {
    if (this.UpdateShoplistProductRewardForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.shoplist
        .UpdateShoplistProductReward(this.UpdateShoplistProductRewardForm)
        .then((response) => {
          Logic.Common.hideLoader()
          return response.data?.UpdateShoplistProductReward
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public UpdateShoplistCollection = (
    progressCb: (progress: number) => void,
  ) => {
    if (this.UpdateShoplistCollectionForm) {
      return $api.shoplist
        .UpdateShoplistCollection(this.UpdateShoplistCollectionForm, progressCb)
        .then((response) => {
          this.SingleShoplistCollection =
            response.data?.UpdateShoplistCollection

          return response.data?.UpdateShoplistCollection
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public DeleteShoplistProductReward = (reward_id: number) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.shoplist
      .DeleteShoplistProductReward(reward_id)
      .then((response) => {
        Logic.Common.hideLoader()
        return response.data?.DeleteShoplistProductReward
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public DeleteCollection = (collection_id: number) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.shoplist
      .DeleteCollection(collection_id)
      .then((response) => {
        Logic.Common.hideLoader()
        return response.data?.DeleteCollection
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public DeleteUserShoplistCart = (cart_id: string) => {
    return $api.shoplist
      .DeleteUserShoplistCart(cart_id)
      .then((response) => {
        return response.data?.DeleteUserShoplistCart
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }
}
