<template>
  <app-wrapper>
    <dashboard-layout :pageKey="'savings'" :greetings="'Hi'">
      <div class="w-full flex flex-col space-y-4 h-full pt-4">
        <!-- Top section -->
        <div class="w-full flex flex-col px-4">
          <app-image-loader
            :custom-class="'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500 relative'"
            :photo-url="'/images/card-bg.svg'"
          >
            <div class="w-full flex flex-row space-x-2">
              <AppNormalText :color="'text-white'"> Cashback Balance </AppNormalText>
            </div>

            <div class="flex flex-col space-y-1">
              <div class="flex flex-row space-x-2 items-center">
                <app-header-text :color="'text-white'">{{
                  canView
                    ? `${Logic.Common.convertToMoney(
                        UserWallet?.cash_point_balance,
                        true,
                        "ngn"
                      )}`
                    : "*********"
                }}</app-header-text>
                <app-icon
                  v-if="canView"
                  @click="canView = false"
                  :custom-class="'w-[16px] cursor-pointer'"
                  :name="'show-white'"
                ></app-icon>
                <app-icon
                  @click="canView = true"
                  v-if="!canView"
                  :custom-class="'w-[16px] cursor-pointer'"
                  :name="'hide-white'"
                ></app-icon>
              </div>
            </div>

            <!-- Weekly spend limit -->
            <div class="w-full flex flex-col space-y-2 pt-2">
              <div class="w-full flex flex-row justify-between items-center">
                <app-normal-text class="!text-white !text-[11px]">
                  Weekly spend limit ({{ weeklySpendLimit }}%)
                </app-normal-text>

                <app-normal-text
                  class="!text-primary-200 cursor-pointer"
                  @click="Logic.Common.GoToRoute('/others/membership')"
                >
                  Upgrade
                </app-normal-text>
              </div>
              <div
                class="w-full h-[8px] rounded-[10px] bg-secondary-50 dark:bg-secondary-100 relative"
              >
                <div
                  class="absolute top-0 left-0 h-full rounded-[10px] bg-[#FFC44D] dark:bg-secondary-500"
                  :style="`width: ${weeklySpendLimit}%;`"
                ></div>
              </div>
            </div>

            <span
              class="absolute top-[4%] right-[4%] px-4 py-1 bg-secondary-main rounded-[20px] cursor-pointer"
              @click="Logic.Common.GoToRoute('/savings/topup')"
            >
              <app-normal-text class="!text-white"> + Topup </app-normal-text>
            </span>
          </app-image-loader>
        </div>

        <!-- Cashback savings -->
        <div class="w-full flex flex-col">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide pl-4 pt-2"
            id="cashback-savings-container"
          >
            <div class="flex flex-row pr-4 space-x-3">
              <div
                v-for="(item, index) in cashbackSavings"
                :key="index"
                class="w-[240px] flex flex-col space-y-3 bg-white dark:bg-gray-800 rounded-[6px] px-3 py-3 border-[1px] border-gray-100"
              >
                <div class="w-full flex flex-row items-center justify-between">
                  <div class="flex flex-col space-y-1">
                    <app-normal-text
                      class="!text-left !text-[10px] uppercase !font-semibold"
                    >
                      {{ item.title }}
                    </app-normal-text>
                    <app-header-text class="!text-sm !text-[#240046] !text-left">
                      {{ Logic.Common.convertToMoney(item.amount, true, "ngn") }}
                    </app-header-text>
                  </div>

                  <div
                    class="px-3 py-1 bg-gray-100 dark:bg-primary-700 rounded-[30px] items-center justify-between cursor-pointer"
                    @click="
                      monthIsClaimed(item.key) || !item.is_active
                        ? null
                        : claimedMonthPoint(item.key)
                    "
                  >
                    <app-normal-text
                      class="!text-primary-400 !text-[11px] dark:!text-primary-100"
                    >
                      {{
                        item.redeemed
                          ? "Redeemed"
                          : item.is_active
                          ? "Redeem now"
                          : "Not available"
                      }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="w-full flex flex-row space-x-1">
                  <app-icon :name="'calendar-purple'" :customClass="'h-[14px]'" />
                  <app-normal-text
                    class="!text-[11px] !text-[#5A189A] dark:!text-primary-200"
                  >
                    Cashback available on:
                    <span class="!font-bold pl-[2px]">{{ item.available_on }}</span>
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Activities and Transactions-->
        <div class="w-full flex flex-col px-4 pt-3">
          <app-switch :items="savingsSwitch" v-model="selectedFilterSavings" />
        </div>

        <div
          class="w-full flex flex-col space-y-4 dark:bg-black rounded-[10px] px-4 pb-4"
        >
          <!-- <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text
                  class="!font-semibold !text-sm text-left invisible"
                >
                  Recent
                </app-normal-text>

                <app-normal-text
                  class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
                  @click="Logic.Common.GoToRoute(`/shoplist/products?key=All`)"
                >
                  See all >
                </app-normal-text>
              </div> -->

          <template v-if="selectedFilterSavings == 'activities'">
            <template v-if="loadingPointEarned">
              <div class="w-full flex flex-col justify-center items-center h-[200px]">
                <app-loading-state class="text-primary-500" />
              </div>
            </template>
            <template v-else>
              <div
                class="w-full flex flex-col justify-center items-center h-[200px]"
                v-if="rewards.length == 0"
              >
                <app-empty-state
                  :title="'No activities yet'"
                  :sub-title="'Your cashback rewards will appear here'"
                />
              </div>

              <div class="w-full flex flex-col space-y-3" v-else>
                <app-reward
                  v-for="(item, index) in rewards"
                  :key="index"
                  :item="item"
                  @click="item.action()"
                />

                <div class="w-full items-center flex justify-center py-2">
                  <app-normal-text
                    class="!py-1 !px-3 w-auto !text-gray-800 dark:!text-gray-300 cursor-pointer"
                    @click.prevent="Logic.Common.GoToRoute('/others/rewards?tab=scan')"
                  >
                    See all activities >
                  </app-normal-text>
                </div>
              </div>
            </template>
          </template>
          <template v-if="selectedFilterSavings == 'transactions'">
            <template v-if="loadingTransactions">
              <div class="w-full flex flex-col justify-center items-center h-[200px]">
                <app-loading-state class="text-primary-500" />
              </div>
            </template>
            <template v-else>
              <div
                class="w-full flex flex-col justify-center items-center h-[200px]"
                v-if="transactions.length == 0"
              >
                <app-empty-state
                  :title="'No transactions yet'"
                  :sub-title="'Your transactions will appear here'"
                />
              </div>

              <div class="w-full flex flex-col space-y-5" v-else>
                <app-transaction
                  v-for="(item, index) in transactions"
                  :key="index"
                  :data="item"
                  class="cursor-pointer"
                  @click="
                    item.transaction_type == 'wallet'
                      ? Logic.Common.GoToRoute(`/savings/transactions/${item.uuid}`)
                      : Logic.Common.GoToRoute(`/savings/points/${item.uuid}`)
                  "
                />

                <div class="w-full items-center flex justify-center py-2">
                  <app-normal-text
                    class="!py-1 !px-3 w-auto !text-gray-800 dark:!text-gray-300 cursor-pointer"
                    @click.prevent="Logic.Common.GoToRoute('/savings/transactions')"
                  >
                    See all transactions >
                  </app-normal-text>
                </div>
              </div>
            </template>
          </template>

          <!-- Spacer -->
          <div class="h-[100px]"></div>
        </div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppImageLoader,
  AppIcon,
  AppNormalText,
  AppHeaderText,
  AppTransaction,
  AppEmptyState,
  AppLoadingState,
  AppSwitch,
  AppReward,
} from "@shpt/ui-components";
import { showSideBar } from "@shpt/ui-components/src/composable";
import {
  ContentType,
  PointTransaction,
  Transaction,
} from "@shpt/ui-components/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import AppWrapper from "@/components/AppWrapper.vue";
import { RewardData } from "@shpt/ui-components/src/types";
import { getSingleReward } from "@shpt/ui-components/src/composable/game";
import { selectedFilterSavings } from "@/composables";

export default defineComponent({
  components: {
    AppImageLoader,
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppTransaction,
    AppEmptyState,
    AppLoadingState,
    AppSwitch,
    AppReward,
    AppWrapper,
  },
  name: "SavingsPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "UserWallet",
        method: "GetUserWallet",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Savings Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Savings",
    });

    const savingsSwitch = reactive([
      {
        name: "Activities",
        key: "activities",
      },
      {
        name: "Transactions",
        key: "transactions",
      },
    ]);

    const canView = ref(true);

    const AuthUser = ref(Logic.Auth.AuthUser);
    const UserWallet = ref(Logic.Wallet.UserWallet);
    const EarningStats = ref(Logic.Auth.EarningStats);
    const ManyPointEarned = ref(Logic.Wallet.ManyPointEarned);

    const ClientWalletTransactions = ref(Logic.Wallet.ClientWalletTransactions);
    const ManyPointTransaction = ref(Logic.Wallet.ManyPointTransaction);

    const loadingTransactions = ref(false);
    const loadingPointEarned = ref(false);

    const weeklySpendLimit = computed(() => {
      return (
        ((AuthUser.value?.receipt_week_spend || 0) /
          parseFloat(
            AuthUser.value?.profile?.subscription_plan.receipt_weekly_spend_limit || "0"
          ) <=
        1
          ? (AuthUser.value?.receipt_week_spend || 0) /
            parseFloat(
              AuthUser.value?.profile?.subscription_plan.receipt_weekly_spend_limit || "0"
            )
          : 1) * 100
      ).toFixed(2);
    });

    const monthIsClaimed = (month: string) => {
      const claimedMonths: string[] = JSON.parse(
        AuthUser.value?.profile?.claimed_earning_periods || "[]"
      );

      return claimedMonths.includes(month);
    };

    const claimedMonthPoint = (month: string) => {
      Logic.User.ClaimPointGained(month);
    };

    const cashbackSavings = reactive<
      {
        key: string;
        title: string;
        amount: number;
        available_on: string;
        redeemed: boolean;
        is_active: boolean;
      }[]
    >([]);

    const transactions = reactive<
      {
        id: string;
        uuid: string;
        description: string;
        type: string;
        time: string;
        amount: number;
        category: string;
        real_date: string;
        transaction_type: "wallet" | "point";
      }[]
    >([]);

    const rewards = reactive<RewardData[]>([]);

    const setTransactions = () => {
      transactions.length = 0;

      const allTransactions: (Transaction | PointTransaction | any)[] = [
        ...(ClientWalletTransactions.value?.data || []),
        ...(ManyPointTransaction.value?.data || []),
      ];

      allTransactions.forEach((transaction) => {
        transactions.push({
          id: transaction.id,
          uuid: transaction.uuid,
          description: transaction.description || "",
          type: transaction.dr_or_cr,
          time: Logic.Common.fomartDate(transaction.created_at, "MMM D, YYYY | h:mmA"),
          amount: transaction.amount,
          category: capitalize(transaction.chargeable_type?.split("_").join(" ") || ""),
          real_date: transaction.created_at,
          transaction_type: transaction.__typename == "Transaction" ? "wallet" : "point",
        });
      });

      transactions.sort((a, b) => {
        return new Date(b.real_date).getTime() - new Date(a.real_date).getTime();
      });
    };

    const fetchTransactions = (silent = false) => {
      if (!silent || transactions.length > 0) {
        loadingTransactions.value = true;
      }
      const allFetchActions: any[] = [];
      allFetchActions.push(
        Logic.Wallet.GetTransactionsByWalletId(
          "client",
          parseInt(UserWallet.value?.id || "0") || 0,
          1,
          10,
          "CREATED_AT",
          "DESC"
        )
      );
      allFetchActions.push(
        Logic.Wallet.GetManyPointTransaction(
          parseInt(UserWallet.value?.id || "0") || 0,
          1,
          10,
          "CREATED_AT",
          "DESC"
        )
      );
      Promise.all(allFetchActions).then(() => {
        //
        loadingTransactions.value = false;
        setTransactions();
      });
    };

    const fetchPointEarned = (silent = false) => {
      if (!silent || rewards?.length > 0) {
        loadingPointEarned.value = true;
      }

      Logic.Wallet.GetPointEarned(1, 10, "CREATED_AT", "DESC", "", true).then(
        (response) => {
          rewards.length = 0;
          response?.data?.forEach((item) => {
            const rewardData = getSingleReward(item);
            rewards.push(rewardData);
          });

          loadingPointEarned.value = false;
        }
      );
    };

    const setCashbackSavings = () => {
      cashbackSavings.length = 0;
      for (const key in EarningStats.value) {
        if (Object.prototype.hasOwnProperty.call(EarningStats.value, key)) {
          const earning = EarningStats.value[key];

          cashbackSavings.push({
            key: key,
            title: key.toUpperCase(),
            amount: earning.claimed,
            available_on: earning.redemption_date,
            redeemed: monthIsClaimed(key.toString()),
            is_active: earning.is_active,
          });
        }
      }

      setTimeout(() => {
        const cashbackContainer = document.getElementById("cashback-savings-container");

        if (cashbackContainer) {
          cashbackContainer.scrollTo({
            left: 5000,
          });
        }
      }, 1000);
    };

    watch([ClientWalletTransactions, ManyPointTransaction], () => {
      setTransactions();
    });

    watch(selectedFilterSavings, () => {
      if (selectedFilterSavings.value == "transactions") {
        fetchTransactions();
      } else {
        fetchPointEarned();
      }
    });

    onIonViewWillEnter(() => {
      setCashbackSavings();
      if (selectedFilterSavings.value == "transactions") {
        fetchTransactions(true);
      } else {
        fetchPointEarned(true);
      }
      Logic.Game.CheckGameStatus();
    });

    onIonViewDidEnter(() => {
      Logic.Wallet.GetUserWallet();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("UserWallet", UserWallet);
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Auth.watchProperty("EarningStats", EarningStats);
      Logic.Wallet.watchProperty("ManyPointEarned", ManyPointEarned);
      Logic.Wallet.watchProperty("ClientWalletTransactions", ClientWalletTransactions);
      Logic.Wallet.watchProperty("ManyPointTransaction", ManyPointTransaction);
      setCashbackSavings();

      if (selectedFilterSavings.value == "transactions") {
        fetchTransactions();
      } else {
        fetchPointEarned();
      }

      if (Logic.Common.currentBuildType() == "web") {
        Logic.Game.CheckGameStatus();
        Logic.Wallet.GetUserWallet();
      }
    });

    return {
      Logic,
      AuthUser,
      cashbackSavings,
      transactions,
      showSideBar,
      UserWallet,
      weeklySpendLimit,
      ContentType,
      loadingTransactions,
      savingsSwitch,
      selectedFilterSavings,
      rewards,
      loadingPointEarned,
      canView,
      monthIsClaimed,
      claimedMonthPoint,
      setStoryContent,
    };
  },
});
</script>
