import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-5 xs:space-y-4 h-full flex-grow pt-4 items-center justify-center z-50" }
const _hoisted_2 = {
  class: "w-full md:!w-[400px] flex flex-row items-center justify-center",
  ref: "lottieContainer"
}
const _hoisted_3 = {
  key: 0,
  class: "w-full absolute top-0 left-0 h-full bg-white flex flex-col justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppReveal = _resolveComponent("AppReveal")!

  return (_ctx.setup)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`w-full flex flex-col fixed top-0 left-0  ${
      _ctx.setup?.isInteractive
        ? 'bg-black !bg-opacity-60'
        : 'bg-black !bg-opacity-30 dark:!bg-opacity-50'
    }   z-[99999999999999999]`),
        id: "innerModal"
      }, [
        (_ctx.setup?.loading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "loader-container w-full absolute top-0 left-0" }, [
                _createElementVNode("div", { class: "loader" })
              ], -1)),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, null, 512)
              ])
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.setup?.reveal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_AppReveal, {
                      "trigger-button": _ctx.setup?.reveal?.button_action,
                      "custom-class": '',
                      onLastTextEvent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setup?.reveal?.last_text_action())),
                      "secondary-button-text": _ctx.setup?.reveal?.secondary_button_text,
                      "secondary-button-trigger": _ctx.setup?.reveal?.secondary_button_action
                    }, {
                      "reveal-logo": _withCtx(() => [
                        _createVNode(_component_app_icon, {
                          customClass: 'h-[90px]',
                          name: _ctx.setup.reveal.logo
                        }, null, 8, ["name"])
                      ]),
                      "center-text": _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.setup.reveal.title), 1)
                      ]),
                      "sub-center-text": _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.setup?.reveal?.sub_title), 1)
                      ]),
                      "button-text": _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.setup.reveal?.button_text), 1)
                      ]),
                      "last-text": _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.setup?.reveal.last_text), 1)
                      ]),
                      _: 1
                    }, 8, ["trigger-button", "secondary-button-text", "secondary-button-trigger"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
      ], 2))
    : _createCommentVNode("", true)
}