import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-0 h-dvh items-center justify-center absolute top-0 left-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_story_view = _resolveComponent("app-story-view")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_app_swiper = _resolveComponent("app-swiper")!
  const _component_plain_layout = _resolveComponent("plain-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_plain_layout, {
        class: _normalizeClass(` ${
        _ctx.Logic.Common.currentBuildType() == 'web' ? '' : '!bg-black'
      } flex flex-col items-center justify-center !h-dvh`)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_swiper, {
              "free-mode": false,
              "show-pagination": false,
              "space-between": 
            _ctx.Logic.Common.currentMediaQuery() != 'xs' &&
            _ctx.Logic.Common.currentMediaQuery() != 'sm'
              ? 10
              : 1
          ,
              "slide-per-view": 1,
              currentSlidePosition: _ctx.currentSlidePosition,
              "custom-class": "w-full h-full mdlg:!w-[480px] lg:!w-[480px] mdlg:h-[90%] lg:!h-[90%] md:!w-[480px] md:!h-[90%] mdlg:dark:border-[1px] md:dark:border-[1px] mdlg:dark:border-grey-100 md:!border-grey-100 mdlg:!rounded-[10px] md:!rounded-[10px]",
              swiperClass: 'h-full   mdlg:!rounded-[10px] md:!rounded-[10px]',
              modelValue: _ctx.slidePosition,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slidePosition) = $event)),
              id: "swiperContainerShoplists",
              ref: "swiperContainerShoplists",
              direction: 'vertical'
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeStory, (item, index) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, {
                    key: index,
                    class: "w-full !h-full mdlg:!w-[480px] lg:!w-[480px] mdlg:!rounded-[10px] md:!w-full md:!rounded-[10px]"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_story_view, {
                        ref_for: true,
                        ref: `swiperSlide${index}`,
                        class: "h-full",
                        data: [item],
                        isFullScreen: true,
                        hideOnPlay: false,
                        onStoryViewIsDone: _ctx.onStoryViewIsDone,
                        currentIndex: _ctx.slidePosition,
                        slideIndex: index
                      }, null, 8, ["data", "onStoryViewIsDone", "currentIndex", "slideIndex"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["space-between", "currentSlidePosition", "modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}