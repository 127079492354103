<template>
  <app-wrapper>
    <plain-layout
      :class="` ${
        Logic.Common.currentBuildType() == 'web' ? '' : '!bg-black'
      } flex flex-col items-center justify-center !h-dvh`"
    >
      <div
        class="w-full flex flex-col space-y-0 h-dvh items-center justify-center absolute top-0 left-0"
      >
        <!-- Stories -->
        <app-swiper
          :free-mode="false"
          :show-pagination="false"
          :space-between="
            Logic.Common.currentMediaQuery() != 'xs' &&
            Logic.Common.currentMediaQuery() != 'sm'
              ? 10
              : 1
          "
          :slide-per-view="1"
          :currentSlidePosition="currentSlidePosition"
          custom-class="w-full h-full mdlg:!w-[480px] lg:!w-[480px] mdlg:h-[90%] lg:!h-[90%] md:!w-[480px] md:!h-[90%] mdlg:dark:border-[1px] md:dark:border-[1px] mdlg:dark:border-grey-100 md:!border-grey-100 mdlg:!rounded-[10px] md:!rounded-[10px]"
          :swiperClass="'h-full   mdlg:!rounded-[10px] md:!rounded-[10px]'"
          v-model="slidePosition"
          id="swiperContainerShoplists"
          ref="swiperContainerShoplists"
          :direction="'vertical'"
        >
          <swiper-slide
            v-for="(item, index) in activeStory"
            :key="index"
            class="w-full !h-full mdlg:!w-[480px] lg:!w-[480px] mdlg:!rounded-[10px] md:!w-full md:!rounded-[10px]"
          >
            <app-story-view
              :ref="`swiperSlide${index}`"
              class="h-full"
              :data="[item]"
              :isFullScreen="true"
              :hideOnPlay="false"
              @storyViewIsDone="onStoryViewIsDone"
              :currentIndex="slidePosition"
              :slideIndex="index"
            />
          </swiper-slide>
        </app-swiper>
      </div>
    </plain-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
  onUnmounted,
} from "vue";
import { useMeta } from "vue-meta";
import {
  onIonViewDidEnter,
  onIonViewDidLeave,
  onIonViewWillEnter,
  onIonViewWillLeave,
} from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { AppSwiper, AppStoryView } from "@shpt/ui-components";
import { SwiperSlide } from "swiper/vue";
import {
  Advert,
  ContentType,
  ShoplistProduct,
  UserContent,
} from "@shpt/logic/src/gql/graphql";
import { currentStoryContents } from "@shpt/ui-components/src/composable/story";
import {
  getMediaBox,
  getMediaBoxForAds,
  getMediaBoxForProduct,
} from "@shpt/ui-components/src/composable";
import { Collection } from "@shpt/ui-components/src/gql/graphql";
import { Story } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppSwiper,
    SwiperSlide,
    AppStoryView,
    AppWrapper,
  },
  name: "StoryPage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "ExploreContent",
        method: "GetUserContent",
        params: ["", [ContentType.Explore], 1, 6, false, false],
        requireAuth: false,
        useRouteQuery: true,
        queries: ["type", "uuid"],
        query_concatenation_type: "append",
      },
    ],
    tracking_data: {
      lable: "Story Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Plain",
  setup() {
    useMeta({
      title: "Story",
    });

    const authUser = ref(Logic.Auth.AuthUser);

    const storyBaseContent = ref<UserContent>();
    const focusStoryUuid = ref("");
    const focusStoryType = ref("");

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { proxy } = getCurrentInstance()!;

    const categoryNames = reactive<string[]>([]);
    const swiperContainerShoplists = ref();
    const parentRefs = ref({});

    const storiesActiveAtATime = ref(3);

    const slidePosition = ref(0);

    const currentSlidePosition = ref(0);

    const stories = reactive<Story[]>([]);

    const activeStory = reactive<Story[]>([]);

    const storyIsFetching = ref(false);

    const fetchMoreStories = () => {
      if (storyIsFetching.value) {
        return;
      }

      if (currentStoryContents.type) {
        let currentPage = storyBaseContent.value?.current_page || 0;
        let totalPage = storyBaseContent.value?.total_pages || 0;

        let nextPage = currentPage;

        if (currentPage < totalPage) {
          nextPage += 1;
        } else {
          nextPage = -1;
        }

        if (nextPage > 1) {
          storyIsFetching.value = true;
          Logic.User.GetUserContent(
            currentStoryContents.business_uuid,
            [currentStoryContents.type],
            nextPage,
            6,
            true
          )
            .then((responseData) => {
              if (responseData) {
                const existingRecommndationContent = JSON.parse(
                  JSON.stringify(storyBaseContent.value?.contents)
                );
                responseData.contents.unshift(...existingRecommndationContent);

                storyBaseContent.value = responseData;

                storyIsFetching.value = false;
              }
            })
            .catch(() => {
              storyIsFetching.value = false;
            });
        }
      }
    };

    const setStoryContent = () => {
      storyBaseContent.value == undefined;

      if (currentStoryContents.type == ContentType.Explore) {
        storyBaseContent.value = Logic.User.ExploreContent;
      } else if (currentStoryContents.type == ContentType.Favorite) {
        storyBaseContent.value = Logic.User.FavoriteContent;
      } else if (currentStoryContents.type == ContentType.Limited) {
        storyBaseContent.value = Logic.User.LimitedContent;
      } else if (currentStoryContents.type == ContentType.RecommendedForYou) {
        storyBaseContent.value = Logic.User.RecommendedForYouContent;
      } else if (currentStoryContents.type == ContentType.SponsoredShoplist) {
        storyBaseContent.value = Logic.User.SponsoredShoplistContent;
      } else if (currentStoryContents.type == ContentType.WhatsNew) {
        storyBaseContent.value = Logic.User.WhatsNewContent;
      } else if (currentStoryContents.type == ContentType.BusinessContents) {
        storyBaseContent.value = Logic.User.MerchantShoplistsContent;
      } else {
        storyBaseContent.value = Logic.User.ExploreContent;
      }
    };

    const setStories = (isMounted = false) => {
      stories.length = 0;
      focusStoryUuid.value = "";
      focusStoryType.value = "";

      if (Logic.Common.route?.query?.uuid) {
        focusStoryUuid.value = Logic.Common.route?.query?.uuid as string;
      }

      if (Logic.Common.route?.query?.type) {
        focusStoryType.value = Logic.Common.route?.query?.type as string;
      }

      storyBaseContent.value?.contents?.forEach((content) => {
        if (content.data) {
          if (content.type == "collection") {
            const mediaBox = getMediaBox(content.data as Collection);

            const story: Story = {
              media: mediaBox,
              caption: "",
              is_group_buy: false,
              saved: false,
              saving: mediaBox.reward_percentage || 0,
              type: "shoplist",
              shoplist: {
                base_tag: mediaBox.main_category || "",
                price: mediaBox.price || 0,
                product_images: mediaBox.grid_images || [],
                title: mediaBox.title || "",
                uuid: mediaBox.data?.uuid || "",
                reward_percentage: mediaBox.reward_percentage || 0,
                reward_type: "cashback",
                type: "shoplist",
              },
              cursor: {
                id: content.id,
                type: content.type,
              },
            };
            stories.push(story);
          } else if (content.type == "ad") {
            const adContent: Advert = content.data as Advert;

            let adType: any = adContent.type;

            let mediaBox = getMediaBoxForAds(content.data as Advert);

            if (adType == "shoppable") {
              adType = "shoplist";
              const shoplistData = (content.data as Advert).advert_media?.shoplist;
              const productData = (content.data as Advert).advert_media?.product;
              if (shoplistData) {
                mediaBox = getMediaBox(shoplistData);
                if (
                  (content.data as Advert).advert_media?.metadata?.image_url &&
                  !(content.data as Advert).advert_media?.media
                ) {
                  mediaBox.base_image =
                    (content.data as Advert).advert_media?.metadata?.image_url || "";
                  mediaBox.video_url = "";
                  mediaBox.is_grid = true;
                } else if ((content.data as Advert).advert_media?.media) {
                  mediaBox.video_url =
                    (content.data as Advert).advert_media?.metadata.media_type == "video"
                      ? parseFloat(
                          (content.data as Advert).advert_media?.media?.percentage?.toString() ||
                            "0"
                        ) == 100
                        ? (content.data as Advert).advert_media?.media?.media_url?.toString()
                        : ""
                      : "";

                  mediaBox.is_grid = false;
                }
              } else if (productData) {
                const mediaBoxForProduct = getMediaBoxForProduct(
                  productData as ShoplistProduct
                );
                if (mediaBoxForProduct) {
                  mediaBox = mediaBoxForProduct;
                }
              }
            }

            mediaBox.tags = ["Sponsored"];

            const mediaContentType =
              (content.data as Advert)?.advert_media?.metadata?.shoppable_type ||
              "shoplist";

            const story: Story = {
              media: mediaBox,
              caption: "",
              is_group_buy: false,
              saved: false,
              saving: mediaBox.reward_percentage || 0,
              type: adType,
              sponsor_uuid: adContent.uuid,
              shoplist: {
                base_tag: mediaBox.main_category || "",
                price: mediaBox.price || 0,
                product_images: mediaBox.grid_images || [],
                title: mediaBox.title || "",
                uuid:
                  mediaContentType == "product"
                    ? (mediaBox.product_data as ShoplistProduct)?.uuid
                    : mediaBox.data?.uuid || "",
                reward_percentage: mediaBox.reward_percentage || 0,
                reward_type: "cashback",
                type: mediaContentType as "shoplist" | "product",
              },
              polls: {
                question: adContent.advert_media?.cta_rules.polls?.question || "",
                answer_type: (adContent.advert_media?.cta_rules.polls?.answer_type ||
                  "") as "binary" | "options",
                option_type: (adContent.advert_media?.cta_rules.polls?.option_type ||
                  "") as "image" | "plain",
                options:
                  adContent.advert_media?.cta_rules.polls?.options.map((option) => ({
                    value: option.value,
                    slug: option.slug,
                    image_url: option.image_url || "",
                  })) || [],
                summary: adContent.poll_summary || [],
                user_poll_vote: adContent.user_poll_vote || undefined,
              },
              website_visit: {
                cta: adContent.advert_media?.cta_rules?.cta || "",
                landing_page_url:
                  adContent.advert_media?.cta_rules?.landing_page_url || "",
              },
              is_sponsored: true,
              request_uuid: storyBaseContent.value?.request_uuid || "",
              cursor: {
                id: content.id,
                type: content.type,
              },
            };
            stories.push(story);
          }
        }
      });

      let focusStorySet = false;

      // If focusStoryUuid is not empty, then set the first item of stories to the story with the id of focusStoryUuid and type of currentStoryContents.cursor.type

      if (focusStoryUuid.value && focusStoryType.value) {
        let story: Story | undefined = undefined;

        if (focusStoryType.value == "shoplist") {
          story = stories.find((story) => story.media?.uuid == focusStoryUuid.value);
        } else if (focusStoryType.value == "advert") {
          story = stories.find((story) => story.sponsor_uuid == focusStoryUuid.value);
        }

        // If the story is found, exchange it with the first item of stores with the story
        if (story) {
          const removedStory = stories.splice(stories.indexOf(story), 1);
          stories.unshift(removedStory[0]);
        }

        if (isMounted) {
          activeStory.length = 0;
        }

        focusStorySet = true;
      }

      // If currentStoryContents.cursor.id is not 0, then set the first item of stories to the story with the id of currentStoryContents.cursor.id and type of currentStoryContents.cursor.type
      if (
        currentStoryContents.cursor &&
        currentStoryContents.cursor.id != 0 &&
        !focusStorySet
      ) {
        const story = stories.find(
          (story) =>
            story.cursor?.id == currentStoryContents.cursor?.id &&
            story.cursor?.type == currentStoryContents.cursor?.type
        );

        // If the story is found, exchange it with the first item of stores with the story
        if (story) {
          const removedStory = stories.splice(stories.indexOf(story), 1);
          stories.unshift(removedStory[0]);
        }

        if (isMounted) {
          activeStory.length = 0;
        }
      }

      if (activeStory.length == 0) {
        // Get the first five stories
        activeStory.push(...stories.slice(0, storiesActiveAtATime.value));
      }
    };

    const onStoryViewIsDone = (index: number) => {
      if (currentSlidePosition.value == index) {
        swiperContainerShoplists.value?.goToNextSlide();
      }
    };

    const onSlideInView = (index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentStoryRef = parentRefs.value[`swiperSlide${index}`];

      if (currentStoryRef?.length > 0) {
        const allExistionStoryViews = Array.from(
          { length: activeStory.length },
          (_, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return parentRefs.value[`swiperSlide${index}`][0];
          }
        );

        allExistionStoryViews.forEach((storyView, i) => {
          if (i === index || i === index - 1 || i === index + 1) {
            if (i !== index) {
              storyView.onSlideInView(false, i);
            } else {
              storyView.onSlideInView(i === index, i);
            }
          } else {
            storyView.onSlideOutView();
          }
        });
      }
    };

    const handleStorySlideChanged = (index: number) => {
      if (index >= 4) {
        if (!Logic.Auth.AuthUser) {
          Logic.Common.GoToRoute("/auth/login");
        }
      }
      // Add two more stories if they exist
      onSlideInView(index);
      if (index === activeStory.length - 2) {
        const nextStoryIndex = stories.indexOf(activeStory[activeStory.length - 1]) + 1;
        if (nextStoryIndex < stories.length) {
          activeStory.push(stories[nextStoryIndex]);
          if (nextStoryIndex + 1 < stories.length) {
            activeStory.push(stories[nextStoryIndex + 1]);
          }
        }
      }
    };

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
      handleStorySlideChanged(slidePosition.value);

      const currentStoriesLength = stories.length;

      // We fetch more stories when the current index is like 2 less than the total stories length
      if (slidePosition.value >= currentStoriesLength - 2) {
        fetchMoreStories();
      }
    });

    watch(currentStoryContents, () => {
      setStoryContent();
      setStories();
    });

    onIonViewWillEnter(() => {
      setStoryContent();
      if (currentStoryContents.former) {
        if (
          currentStoryContents.cursor != currentStoryContents.former.cursor &&
          currentStoryContents.former.type != currentStoryContents.type
        ) {
          setStories(true);
        } else {
          setStories(false);
        }
      } else {
        setStories(true);
      }
    });

    onIonViewDidEnter(() => {
      onSlideInView(currentSlidePosition.value);
    });

    onIonViewDidLeave(() => {
      document.body.style.backgroundColor = "white";
      document.body.style.setProperty("background-color", "white", "important");
    });

    onIonViewWillLeave(() => {
      document.body.style.backgroundColor = "white";
      document.body.style.setProperty("background-color", "white", "important");
    });

    onMounted(() => {
      // https://useshoplist.com/DthnsQWN
      if (proxy) {
        parentRefs.value = proxy.$refs;
      }

      if (Logic.Common.currentBuildType() == "web") {
        setStoryContent();
        if (currentStoryContents.former) {
          if (
            currentStoryContents.cursor != currentStoryContents.former.cursor &&
            currentStoryContents.former.type != currentStoryContents.type
          ) {
            setStories(true);
          } else {
            setStories(false);
          }
        } else {
          setStories(true);
        }

        onSlideInView(currentSlidePosition.value);

        // Listen to arrow up and arrow down button
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === "ArrowUp") {
            // Handle arrow up
            swiperContainerShoplists.value?.goToPrevSlide();
          } else if (event.key === "ArrowDown") {
            // Handle arrow down

            swiperContainerShoplists.value?.goToNextSlide();
          }
        };

        window.addEventListener("keydown", handleKeyDown);
      } else {
        document.body.style.backgroundColor = "black";
        document.body.style.setProperty("background-color", "black", "important");
      }
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", () => {
        //
      });
    });

    return {
      Logic,
      authUser,
      stories,
      categoryNames,
      slidePosition,
      activeStory,
      currentSlidePosition,
      swiperContainerShoplists,
      parentRefs,
      onStoryViewIsDone,
    };
  },
});
</script>
<style scoped></style>
