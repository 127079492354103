<template>
  <app-wrapper>
    <subpage-layout
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
    >
      <!-- Add to basket -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            :class="`!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white`"
            v-if="!fromPreview"
            @click="handleAddToCart"
            id="add-to-cart-button"
          >
            {{ "Add to Shoplist" }}
          </app-button>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-row px-4 justify-between items-center">
          <div class="flex flex-col space-y-1 justify-start">
            <app-header-text class="!text-base">
              {{ SingleShoplistProduct?.name }}
            </app-header-text>
            <div class="w-full flex flex-row space-x-2 items-center">
              <!-- <app-normal-text> 1.4k Shoppers </app-normal-text>
              <span
                class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-50"
              >
              </span> -->
              <div class="flex flex-row items-center space-x-1">
                <app-normal-text class="!text-success-500"
                  >Earn {{ numberToAbbrev(totalReward) }} pts
                  {{
                    totalDiscount
                      ? `and (${Logic.Common.convertToMoney(
                          totalDiscount,
                          true,
                          "ngn"
                        )} off)`
                      : ""
                  }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Shoplist items -->
        <div class="w-full flex flex-col space-y-1 px-4">
          <div
            v-for="(item, index) in productLists"
            :key="index"
            class="w-full flex flex-col space-y-3 border-b-[1px] border-grey-100 py-3 !z-[0]"
          >
            <div class="w-full flex flex-col space-y-2 !z-[0]">
              <app-swiper
                :free-mode="false"
                :show-pagination="false"
                :space-between="10"
                :slide-per-view="1"
                :currentSlidePosition="currentSlidePosition"
                custom-class="h-[300px]"
                :swiperClass="''"
                v-model="slidePosition"
                id="swiperContainerProducts"
              >
                <swiper-slide
                  class="w-full h-full"
                  v-for="(image, index) in images"
                  :key="index"
                >
                  <app-image-loader
                    @click="showFullImages = true"
                    :photoUrl="image"
                    class="rounded-[10px] h-[300px] w-full border-[1px] border-gray-200 dark:!border-gray-700 cursor-pointer"
                  />
                </swiper-slide>
              </app-swiper>

              <div
                class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
              >
                <div class="flex flex-row py-2 pr-4">
                  <div
                    class="flex flex-row cursor-pointer pr-2"
                    v-for="(image, index) in images"
                    :key="index"
                    @click="currentSlidePosition = index"
                    :id="`image${index}`"
                  >
                    <app-image-loader
                      :photoUrl="image"
                      :class="`rounded-[10px] h-[70px] w-[70px] border-[2px] border-gray-200 dark:!border-gray-700 ${
                        currentSlidePosition == index ? '!border-primary-main' : ''
                      }`"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-row space-x-2">
              <div class="flex flex-col items-end w-full justify-between space-y-1">
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>

                  <!-- Description -->
                  <div
                    class="w-full flex flex-col space-y-2"
                    v-if="SingleShoplistProduct?.description"
                  >
                    <app-normal-text
                      class="!text-left !text-[#5A5A5A] dark:!text-grey-200 line-clamp-6"
                    >
                      {{ SingleShoplistProduct?.description }}
                    </app-normal-text>
                  </div>

                  <div class="w-full flex flex-row items-center space-x-2 pt-4">
                    <!-- <app-normal-text class="!text-[11px] !text-grey-900">
                        {{ item.weight }}
                      </app-normal-text>
                      -->

                    <app-badge
                      :color="item.reward_type == 'cashback' ? 'purple' : 'purple'"
                      class="capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                      >Earn {{ numberToAbbrev(totalReward) }} pts
                    </app-badge>

                    <span
                      class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200"
                    >
                    </span>

                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-grey-200"
                    >
                      {{ item.main_category }}
                    </app-normal-text>
                  </div>
                </div>

                <!-- Attributes -->
                <div
                  class="w-full grid grid-cols-2 gap-3 pb-2 pt-3"
                  v-if="productAttributes.length"
                >
                  <div
                    class="col-span-1 flex flex-col space-y-[1px]"
                    v-for="(attribute, index) in productAttributes"
                    :key="index"
                  >
                    <app-normal-text class="!text-left !font-semibold">
                      {{ attribute.name }}
                    </app-normal-text>
                    <div class="w-full flex flex-row flex-wrap">
                      <div
                        class="pr-2 pt-2 flex flex-col cursor-pointer"
                        v-for="(attributeValue, index) in attribute.existing_values"
                        :key="index"
                        @click="selectOption(attribute.name, attributeValue)"
                      >
                        <div
                          :class="`px-2 py-[2px] bg-primary-50 dark:!bg-primary-500 rounded-[4px] flex flex-row items-center justify-center space-x-2 ${
                            itemSelected(attribute.name, attributeValue)
                              ? '!border-primary-main !border-[2px] dark:!border-primary-50'
                              : '!border-[2px] !border-transparent'
                          }`"
                        >
                          <app-normal-text>{{ attributeValue }}</app-normal-text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full flex flex-col">
                  <!-- Items left in stock -->
                  <div class="w-full flex flex-row pt-3">
                    <app-normal-text
                      :class="` ${
                        (currentVariant?.inventory_quantity || 0) > 5
                          ? '!text-success-main'
                          : '!text-secondary-700'
                      }`"
                      v-if="(currentVariant?.inventory_quantity || 0) > 0"
                    >
                      <span class="!font-bold">{{
                        currentVariant?.inventory_quantity
                      }}</span>
                      left in stock
                    </app-normal-text>
                    <app-normal-text class="!text-error-main" v-else>
                      Out of stock
                    </app-normal-text>
                  </div>

                  <div class="w-full flex flex-row space-x-2 justify-between items-end">
                    <app-header-text class="!text-sm">
                      {{
                        Logic.Common.convertToMoney(currentVariant?.price, true, "ngn")
                      }}
                    </app-header-text>

                    <div class="flex flex-col space-y-1 relative pt-2">
                      <div
                        :class="`flex flex-row items-center rounded-[13.83px] ${
                          item.max_qty == 0 ? 'opacity-50' : ''
                        }`"
                      >
                        <div
                          @click="item.qty > 1 ? item.qty-- : null"
                          class="w-[28px] h-[28px] rounded-l-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                        >
                          <app-normal-text
                            class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                          >
                            -
                          </app-normal-text>
                        </div>
                        <div
                          class="w-[28px] h-[28px] bg-[#F7F7F7] dark:bg-grey-800 flex items-center justify-center"
                        >
                          <app-normal-text
                            class="!text-grey-900 dark:!text-grey-50 !font-semibold"
                          >
                            {{ item.max_qty > 0 ? item.qty : "0" }}
                          </app-normal-text>
                        </div>
                        <div
                          @click="item.qty < item.max_qty ? item.qty++ : null"
                          class="w-[28px] h-[28px] rounded-r-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                        >
                          <app-normal-text
                            class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                          >
                            +
                          </app-normal-text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Similar products -->
        <div class="w-full flex flex-col space-y-3 px-4" v-if="similarProducts.length">
          <app-header-text class="!text-base !font-semibold">
            Similar products
          </app-header-text>
          <div class="w-full grid grid-cols-2 gap-2">
            <app-shoplist-explore
              v-for="(item, index) in similarProducts"
              :key="index"
              :item="item"
              @click="handleShoplsistClick(item)"
            />
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Image viewer -->
      <Teleport to="#modal" v-if="showFullImages">
        <fixed-container
          class="!z-[9999999999999999] !h-full !w-full !fixed !top-0 !left-0"
          baseColor="bg-black !bg-opacity-50"
          :customClass="'!border-none !h-screen '"
          @click="showFullImages = false"
        >
          <div class="!h-full !w-full flex flex-col justify-between">
            <!-- Top -->
            <div
              class="w-full flex flex-row justify-between pb-2"
              style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
            >
              <span
                class="h-[30px] w-[30px] rounded-full flex flex-row items-center justify-center border-[2px] border-white cursor-pointer"
              >
                <app-icon name="close-white" :customClass="'!h-[10px]'" />
              </span>
            </div>

            <!-- Image content -->
            <div class="w-full flex flex-col !h-[80%]" @click.stop="null">
              <app-swiper
                :free-mode="false"
                :show-pagination="false"
                :space-between="10"
                :slide-per-view="1"
                :currentSlidePosition="currentSlidePosition"
                custom-class="h-full"
                :swiperClass="'h-full'"
                v-model="slidePosition"
                id="swiperContainerProductsFull"
              >
                <swiper-slide
                  class="w-full h-full"
                  v-for="(image, index) in images"
                  :key="index"
                >
                  <div
                    class="rounded-[10px] h-full w-full border-[1px] border-gray-200 dark:!border-gray-700 bg-black flex flex-col items-center justify-center"
                  >
                    <img :src="image" class="h-auto w-full" />
                  </div>
                </swiper-slide>
              </app-swiper>
            </div>

            <!-- Bottom -->
            <div class="w-full flex flex-col pt-1" @click.stop="null">
              <div
                class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
              >
                <div class="flex flex-row py-2 pr-4">
                  <div
                    class="flex flex-row cursor-pointer pr-2"
                    v-for="(image, index) in images"
                    :key="index"
                    @click.stop="currentSlidePosition = index"
                    :id="`image${index}`"
                  >
                    <app-image-loader
                      :photoUrl="image"
                      :class="`rounded-[10px] h-[70px] w-[70px] border-[2px] border-gray-200 dark:!border-gray-700 ${
                        currentSlidePosition == index ? '!border-primary-main' : ''
                      }`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fixed-container>
      </Teleport>

      <!-- Bottom section -->
      <fixed-container customClass="!px-0 !space-y-0">
        <app-cart />
        <div
          class="w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black"
        >
          <div class="flex flex-col space-y-[3px]">
            <app-header-text class="!text-base">
              {{ Logic.Common.convertToMoney(totalCost, true, "ngn") }}
            </app-header-text>
            <div class="flex flex-row space-x-1 justify-start flex-grow items-center">
              <app-icon :name="'cashback-icon'" :customClass="'h-[15px]'" />
              <app-normal-text class="!text-[#898989] dark:!text-grey-200"
                >{{ numberToAbbrev(totalReward) }} pts
              </app-normal-text>
            </div>
          </div>
          <div
            :class="`py-3 px-6 flex flex-row justify-center items-center space-x-2 rounded-[999px] cursor-pointer ${
              productIsInCart(SingleShoplistProduct?.uuid || '')
                ? 'bg-error-50'
                : 'bg-primary-main'
            }`"
            @click="addOrRemoveFromCart"
            v-if="!fromPreview"
          >
            <app-normal-text
              :class="`!font-semibold ${
                productIsInCart(SingleShoplistProduct?.uuid || '')
                  ? '!text-error-main'
                  : '!text-white'
              }`"
            >
              {{
                productIsInCart(SingleShoplistProduct?.uuid || "")
                  ? "Remove from Cart"
                  : "Add to Cart"
              }}
            </app-normal-text>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppBadge,
  AppIcon,
  AppShoplistExplore,
  AppCart,
  AppSwiper,
} from "@shpt/ui-components";
import { getMediaBoxForProduct } from "@shpt/ui-components/src/composable";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";
import { MediaBox } from "@shpt/ui-components/src/types";
import { scrollToTop } from "@shpt/ui-components/src/composable";
import { ManageCollectionAction } from "@shpt/logic/src/gql/graphql";
import { SwiperSlide } from "swiper/vue";

type ProductList = {
  title: string;
  weight: string;
  main_category: string;
  price: number;
  base_image: string;
  qty: number;
  id: string;
  reward_type: string;
  data: ShoplistProduct;
  max_qty: number;
  discount: number;
  reward_percentage: number;
  reward_point: number;
  cashback: number;
  available_in_area: boolean;
  business: {
    name: string;
    id: string;
  };
  delivery: {
    id: string;
    cost: string;
  };
  has_delivery: boolean;
  shoplist_id: string;
  variant_id: string;
};

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppBadge,
    AppWrapper,
    AppIcon,
    AppCart,
    FixedContainer,
    AppShoplistExplore,
    AppSwiper,
    SwiperSlide,
  },
  name: "ProductInfoPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistProduct",
        method: "GetShoplistProduct",
        ignoreProperty: true,
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManySimilarProducts",
        method: "GetSimilarProducts",
        ignoreProperty: true,
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Product Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    const SingleShoplistProduct = ref(Logic.Shoplist.SingleShoplistProduct);
    const ManySimilarProducts = ref(Logic.Shoplist.ManySimilarProducts);
    const UserProductCart = ref(Logic.Shoplist.UserProductCart);

    const currentSlidePosition = ref(0);
    const slidePosition = ref(0);

    const similarProducts = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const selectedOptions = reactive<
      {
        attribute_id: string;
        value: string;
      }[]
    >([]);

    const fromPreview = ref(false);
    const showFullImages = ref(false);

    const currentVariant = computed(() => {
      if (productAttributes.value.length) {
        //  Variant that match selected options
        const selectedVariant = SingleShoplistProduct.value?.variants?.find((variant) => {
          return variant?.selected_options?.every((option) => {
            return selectedOptions.find(
              (selectedOption) =>
                selectedOption.attribute_id == option.name &&
                selectedOption.value == option.value
            );
          });
        });
        return selectedVariant
          ? selectedVariant
          : SingleShoplistProduct.value?.variants
          ? SingleShoplistProduct.value?.variants[0]
          : null;
      } else {
        return SingleShoplistProduct.value?.variants
          ? SingleShoplistProduct.value?.variants[0]
          : null;
      }
    });

    const productAttributes = computed(() => {
      const attributes: {
        id: string;
        name: string;
        value: string;
        existing_values: string[];
      }[] = [];

      SingleShoplistProduct.value?.variants?.forEach((variant) => {
        variant?.selected_options?.forEach((item) => {
          const attributExist = attributes.find(
            (attribute) => attribute.name == item.name
          );
          if (!attributExist) {
            attributes.push({
              id: Logic.Common.makeid(10),
              name: item.name,
              value: "",
              existing_values: [item.value],
            });
          } else {
            const itemIsInExistingValues = attributExist.existing_values.find(
              (singleItem) => singleItem == item.value
            );
            if (!itemIsInExistingValues) {
              attributExist.existing_values.push(item.value);
            }
          }
        });
      });
      return attributes;
    });

    const images = computed(() => {
      const images: any[] = [];

      // Add variants images
      if (SingleShoplistProduct.value?.variants) {
        SingleShoplistProduct.value?.variants.forEach((variant) => {
          if (variant?.base_image_url) {
            images.push(variant?.base_image_url);
          }
        });
      }

      if (!SingleShoplistProduct.value?.medias?.length) {
        images.push(SingleShoplistProduct.value?.primary_image_url);
      } else {
        const mediaImages = SingleShoplistProduct.value?.medias.map(
          (item) => item.media_url
        );
        images.push(...(mediaImages ? mediaImages : []));
      }

      return images;
    });

    const totalReward = computed(() => {
      let total = 0;

      productLists.forEach((item) => {
        total += productRealPrice.value * item.qty * (item.reward_percentage / 100) * 10;
      });

      return total;
    });

    const totalDiscount = computed(() => {
      let total = 0;
      return total;
    });

    const productRealPrice = computed(() => {
      let price = SingleShoplistProduct.value?.regular_price || "0";
      if (currentVariant.value) {
        price = currentVariant.value?.price || "0";
      }
      price = price.replaceAll(",", "");
      return parseFloat(price);
    });

    const totalCost = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        total += productRealPrice.value * item.qty;
      });
      return total;
    });

    const addedToCart = computed(() => {
      return false;
    });

    const mediaData = computed(() => {
      return SingleShoplistProduct.value
        ? getMediaBoxForProduct(SingleShoplistProduct.value)
        : undefined;
    });

    const productLists = reactive<ProductList[]>([]);

    const createProductList = () => {
      productLists.length = 0;

      if (!SingleShoplistProduct.value) {
        return;
      }

      [SingleShoplistProduct.value].forEach((product) => {
        let discount = 0;
        let reward_percentage = 0;
        let reward_point = 0;
        let cashback = 0;
        let rewardType = "cashback";

        let mediaBox = getMediaBoxForProduct(product);

        cashback = mediaBox?.points || 0;
        reward_point = mediaBox?.points || 0;
        reward_percentage = mediaBox?.reward_percentage || 0;

        // Select default option
        if (productAttributes.value.length) {
          productAttributes.value.forEach((attribute) => {
            selectOption(attribute.name, attribute.existing_values[0]);
          });
        }

        const savedSelectedVariant =
          localStorage.getItem(`selected_variant_for_product_${product.uuid}`) || "";

        if (savedSelectedVariant) {
          const variant = product.variants?.find(
            (variant) => variant?.id == savedSelectedVariant
          );

          selectedOptions.length = 0;
          variant?.selected_options?.forEach((item) => {
            selectedOptions.push({ attribute_id: item.name, value: item.value });
          });
        }

        productLists.push({
          base_image: product.primary_image_url,
          main_category: product.category.name,
          price: parseFloat(product.regular_price),
          title: product.name,
          weight: "",
          qty: 1,
          id: product.id,
          data: product,
          max_qty: currentVariant.value?.inventory_quantity || 0,
          reward_type: rewardType,
          discount,
          reward_percentage,
          reward_point,
          cashback,
          available_in_area: true,
          business: {
            name: product.business?.business_name || "",
            id: product.business?.id || "",
          },
          delivery: {
            id: "",
            cost: "",
          },
          has_delivery: product.deliverable || false,
          shoplist_id: "",
          variant_id: savedSelectedVariant,
        });
      });

      if (Logic.Common.currentBuildType() == "web") {
        // useMeta({
        //   title: `${SingleShoplistProduct.value?.name}`,
        // });
        document.title = `${SingleShoplistProduct?.value?.name} | Shoppoint`;
      }

      // Set default variant
      if (currentVariant.value) {
        saveSelectedVariantForProduct(
          SingleShoplistProduct.value?.uuid || "",
          currentVariant.value?.id || ""
        );
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        if (currentPathQuery.preview?.toString()) {
          fromPreview.value = true;
        }
      } else {
        fromPreview.value = true;
      }
    };

    const handleAddToCart = () => {
      Logic.Common.showModal({
        show: true,
        title: "Add to Shoplist",
        type: "add_product_to_shoplists",
        extraData: {
          product_id: SingleShoplistProduct.value?.id,
        },
        action: () => {
          console.log("add to shoplist");
        },
      });
    };

    const saveMetric = (event_type: string) => {
      Logic.Ad.SaveMetric({
        entity_type: "product",
        event_type,
        entity_uuid: SingleShoplistProduct.value?.uuid || "",
        event_metadata: JSON.stringify({
          type: "product",
          product_uuid: SingleShoplistProduct.value?.uuid || "",
        }),
      });
    };

    const saveSelectedVariantForProduct = (product_uuid: string, variant_id: string) => {
      //  Save to local storage
      localStorage.setItem(`selected_variant_for_product_${product_uuid}`, variant_id);
    };

    const setSimilarProducts = () => {
      similarProducts.length = 0;

      ManySimilarProducts.value?.forEach((product) => {
        const mediaBox = getMediaBoxForProduct(product as ShoplistProduct);
        if (mediaBox) {
          similarProducts.push({
            data: mediaBox,
            id: product.id.toString(),
            type: "product",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
      });
    };

    const handleShoplsistClick = (item: any) => {
      Logic.Common.GoToRoute(`/shoplist/product/${item.data.product_data?.uuid}`);
    };

    const productIsInCart = (product_uuid: string) => {
      return UserProductCart.value?.products?.find((item) => {
        return item.uuid == product_uuid;
      })
        ? true
        : false;
    };

    const addOrRemoveFromCart = () => {
      if (productIsInCart(SingleShoplistProduct.value?.uuid || "")) {
        Logic.Shoplist.AddRemoveCollectionProductForm = {
          collection_id: parseInt(Logic.Shoplist.UserProductCart?.id || "0"),
          products: [parseInt(SingleShoplistProduct.value?.id || "0")],
          action: ManageCollectionAction.Remove,
          type: "product",
        };

        Logic.Common.showAlert({
          show: true,
          message: `Product removed from Cart`,
          type: "success",
        });

        const oldCartData = JSON.parse(localStorage.getItem("user_product_cart") || "{}");
        oldCartData.products = oldCartData.products.filter(
          (product: any) => product.id !== SingleShoplistProduct.value?.id
        );
        Logic.Shoplist.UserProductCart = oldCartData;

        localStorage.setItem(
          "user_product_cart",
          JSON.stringify(Logic.Shoplist.UserProductCart)
        );
      } else {
        saveMetric("clicked");

        Logic.Shoplist.AddRemoveCollectionProductForm = {
          collection_id: parseInt(Logic.Shoplist.UserProductCart?.id || "0"),
          products: [parseInt(SingleShoplistProduct.value?.id || "0")],
          action: ManageCollectionAction.Add,
          type: "product",
        };

        Logic.Common.showAlert({
          show: true,
          message: `Product added to Cart`,
          type: "success",
        });

        const oldCartData = JSON.parse(JSON.stringify(Logic.Shoplist.UserProductCart));
        oldCartData.products.push(SingleShoplistProduct.value);

        Logic.Shoplist.UserProductCart = oldCartData;
        localStorage.setItem(
          "user_product_cart",
          JSON.stringify(Logic.Shoplist.UserProductCart)
        );
      }

      Logic.Shoplist.AddRemoveCollectionProduct()?.then((data: any) => {
        if (data) {
          Logic.Shoplist.GetUserProductCart();
        }
      });
    };

    const itemSelected = (attribute_name: string, value: string) => {
      return selectedOptions.find(
        (item) => item.attribute_id == attribute_name && item.value == value
      );
    };

    const selectOption = (attribute_name: string, value: string) => {
      //  Check if item with attribute_name already exists in selectedOptions
      const attributeIsSelected = selectedOptions?.find(
        (item) => item.attribute_id == attribute_name
      );
      if (attributeIsSelected) {
        attributeIsSelected.value = value;
      } else {
        selectedOptions.push({ attribute_id: attribute_name, value });
      }
    };

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
    });

    watch(ManySimilarProducts, () => {
      setSimilarProducts();
    });

    watch(SingleShoplistProduct, () => {
      createProductList();
      scrollToTop();
    });

    watch(currentVariant, () => {
      if (currentVariant.value) {
        saveSelectedVariantForProduct(
          SingleShoplistProduct.value?.uuid || "",
          currentVariant.value?.id || ""
        );
      }
    });

    onIonViewWillEnter(() => {
      createProductList();
      setSimilarProducts();
      setPageState();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("SingleShoplistProduct", SingleShoplistProduct);
      Logic.Shoplist.watchProperty("ManySimilarProducts", ManySimilarProducts);
      Logic.Shoplist.watchProperty("UserProductCart", UserProductCart);
      createProductList();
      setSimilarProducts();
      setPageState();
      scrollToTop();
      // Scroll to top of #main-section after component is mounted
    });

    return {
      Logic,
      productLists,
      SingleShoplistProduct,
      mediaData,
      fromPreview,
      totalReward,
      addedToCart,
      totalCost,
      totalDiscount,
      similarProducts,
      currentSlidePosition,
      slidePosition,
      images,
      productAttributes,
      selectedOptions,
      showFullImages,
      currentVariant,
      saveSelectedVariantForProduct,
      productIsInCart,
      addOrRemoveFromCart,
      handleAddToCart,
      numberToAbbrev,
      handleShoplsistClick,
      itemSelected,
      selectOption,
    };
  },
});
</script>
