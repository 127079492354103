<template>
  <app-wrapper>
    <subpage-layout
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
    >
      <!-- Add to basket -->
      <template
        v-slot:extra-topbar
        v-if="SingleShoplistCollection?.type != 'user-generated'"
      >
        <div class="flex flex-col">
          <app-button
            :class="`${
              addedToCart
                ? '!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white'
                : '!bg-primary-50 !text-primary-main dark:!bg-primary-500 dark:!text-white'
            } flex flex-row space-x-1 items-center`"
            v-if="!fromPreview"
            @click="handleAddToCart"
          >
            <app-icon :name="'check-green'" :customClass="'h-[9px]'" v-if="addedToCart" />
            <span
              :class="
                addedToCart
                  ? '!text-success-main dark:!text-white'
                  : '!text-primary-main dark:!text-white'
              "
            >
              {{ addedToCart ? "Saved Shoplist" : "Save Shoplist" }}
            </span>
          </app-button>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-row px-4 justify-between items-center">
          <div class="flex flex-col space-y-1 justify-start">
            <app-header-text class="!text-base">
              {{
                SingleShoplistCollection?.name.includes("base-cart")
                  ? "Your Shopping Cart"
                  : SingleShoplistCollection?.name
              }}
            </app-header-text>
            <div
              class="w-full flex flex-row space-x-2 items-center"
              v-if="SingleShoplistCollection?.collections?.length || productLists.length"
            >
              <template v-if="SingleShoplistCollection?.type != 'user-generated'">
                <app-normal-text class="!text-primary-main">
                  Expires
                  {{ Logic.Common.timeFromNow(SingleShoplistCollection?.expires_at) }}
                </app-normal-text>
                <span class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-50">
                </span>
              </template>
              <div class="flex flex-row items-center space-x-1">
                <app-normal-text class="!text-success-500">
                  Earn {{ numberToAbbrev(totalReward) }} pts
                  {{
                    totalDiscount
                      ? `and (${Logic.Common.convertToMoney(
                          totalDiscount,
                          true,
                          "ngn"
                        )} off)`
                      : ""
                  }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Description -->
        <div
          class="w-full flex flex-col space-y-2 px-4"
          v-if="SingleShoplistCollection?.description"
        >
          <app-normal-text
            class="!text-left !text-[#5A5A5A] dark:!text-grey-200 line-clamp-6"
          >
            {{ SingleShoplistCollection?.description }}
          </app-normal-text>
        </div>

        <!-- Shoplist items -->
        <div class="w-full flex flex-col space-y-1 px-4">
          <!-- Products -->
          <div
            class="w-full flex flex-row justify-start"
            v-if="
              SingleShoplistCollection?.type == 'user-generated' && productLists.length
            "
          >
            <app-normal-text class="!text-grey-main dark:!text-grey-200">
              Product{{ productLists.length > 1 ? "s" : "" }} ({{ productLists.length }})
            </app-normal-text>
          </div>
          <div
            v-for="(item, index) in productLists"
            :key="index"
            class="w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 py-3 cursor-pointer"
            @click="Logic.Common.GoToRoute(`/shoplist/product/${item.uuid}`)"
          >
            <div class="w-full flex flex-row space-x-2">
              <div class="h-[82px]">
                <app-image-loader
                  :photoUrl="item.base_image"
                  class="rounded-[4.4px] h-[88px] w-[88px] relative"
                >
                  <div
                    class="absolute bottom-0 left-0 flex items-center justify-center w-full px-2 py-2 cursor-pointer"
                    v-if="SingleShoplistCollection?.type == 'user-generated'"
                    @click.stop="removeProductFromCollection(item.id)"
                  >
                    <app-badge
                      :color="'red'"
                      class="!text-[9px] !rounded-[10px] !py-[1px]"
                    >
                      Remove
                    </app-badge>
                  </div>
                </app-image-loader>
              </div>
              <div class="flex flex-col items-end w-full justify-between space-y-1">
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>
                  <div class="w-full flex flex-row items-center space-x-2">
                    <!-- <app-normal-text class="!text-[11px] !text-grey-900">
                      {{ item.weight }}
                    </app-normal-text>
                    -->

                    <app-badge
                      :color="item.reward_type == 'cashback' ? 'purple' : 'purple'"
                      class="capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                    >
                      Earn {{ numberToAbbrev(item.reward_point) }} pts
                    </app-badge>

                    <span
                      class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200"
                    >
                    </span>

                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-grey-200"
                    >
                      {{ item.main_category }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="w-full flex flex-row space-x-2 justify-between items-end">
                  <app-header-text class="!text-sm">
                    {{ Logic.Common.convertToMoney(item.price, true, "ngn") }}
                  </app-header-text>

                  <div class="flex flex-col space-y-1 relative pt-2" @click.stop="null">
                    <div
                      :class="`flex flex-row items-center rounded-[13.83px] ${
                        item.max_qty == 0 ? 'opacity-50' : ''
                      }`"
                    >
                      <div
                        @click.stop="item.qty > 1 ? item.qty-- : null"
                        class="w-[28px] h-[28px] rounded-l-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                      >
                        <app-normal-text
                          class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                        >
                          -
                        </app-normal-text>
                      </div>
                      <div
                        class="w-[28px] h-[28px] bg-[#F7F7F7] dark:bg-grey-800 flex items-center justify-center"
                      >
                        <app-normal-text
                          class="!text-grey-900 dark:!text-grey-50 !font-semibold"
                        >
                          {{ item.max_qty > 0 ? item.qty : "0" }}
                        </app-normal-text>
                      </div>
                      <div
                        @click.stop="item.qty < item.max_qty ? item.qty++ : null"
                        class="w-[28px] h-[28px] rounded-r-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                      >
                        <app-normal-text
                          class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                        >
                          +
                        </app-normal-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Collections -->
          <div
            class="w-full flex flex-col pt-3"
            v-if="SingleShoplistCollection?.collections?.length"
          >
            <div
              class="w-full flex flex-row justify-start"
              v-if="SingleShoplistCollection?.type == 'user-generated'"
            >
              <app-normal-text class="!text-grey-main dark:!text-grey-200">
                Shoplist{{
                  SingleShoplistCollection?.collections?.length > 1 ? "s" : ""
                }}
                ({{ SingleShoplistCollection?.collections?.length }})
              </app-normal-text>
            </div>

            <div
              v-for="(item, index) in SingleShoplistCollection?.collections"
              :key="index"
              class="w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 py-3 cursor-pointer"
              @click="goToShoplist(item, item.uuid)"
            >
              <div class="w-full flex flex-row space-x-2">
                <div class="h-[82px] !w-[120px]">
                  <app-media-box
                    :data="getMediaBox(item)"
                    :isVideo="false"
                    class="!h-[82px] !w-[82px] !rounded-[4.4px]"
                    :isBasketType="true"
                    :enforceGrid="true"
                  >
                    <div
                      class="absolute bottom-0 left-0 flex items-center justify-center w-full px-2 py-2 cursor-pointer"
                      v-if="SingleShoplistCollection?.type == 'user-generated'"
                      @click.stop="removeProductFromCollection(item.id, 'collection')"
                    >
                      <app-badge
                        :color="'red'"
                        class="!text-[9px] !rounded-[10px] !py-[1px]"
                      >
                        Remove
                      </app-badge>
                    </div>
                  </app-media-box>
                </div>
                <div class="flex flex-col items-end w-full justify-between space-y-1">
                  <div class="w-full flex flex-col space-y-[2px]">
                    <app-header-text class="!text-sm text-left">
                      {{ item.name }}
                    </app-header-text>
                    <div class="w-full flex flex-row items-center space-x-2">
                      <!-- <app-normal-text class="!text-[11px] !text-grey-900">
                      {{ item.weight }}
                    </app-normal-text>
                    -->

                      <app-badge
                        :color="
                          getMediaBox(item).reward_type == 'cashback'
                            ? 'purple'
                            : 'purple'
                        "
                        class="capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                      >
                        Earn {{ numberToAbbrev(getMediaBox(item).points || 0) }}pts
                      </app-badge>

                      <span
                        class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200"
                      >
                      </span>

                      <app-normal-text
                        class="!text-[11px] !text-grey-900 dark:!text-grey-200"
                      >
                        {{ getMediaBox(item).main_category }}
                      </app-normal-text>
                    </div>
                  </div>

                  <div class="w-full flex flex-row space-x-2 justify-between items-end">
                    <app-header-text class="!text-sm">
                      {{
                        Logic.Common.convertToMoney(
                          getMediaBox(item).price || 0,
                          true,
                          "ngn"
                        )
                      }}
                    </app-header-text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- No items -->
          <div
            class="w-full flex flex-col items-center justify-center h-[200px]"
            v-if="!SingleShoplistCollection?.collections?.length && !productLists.length"
          >
            <app-empty-state
              :title="'No items'"
              :subTitle="`Items in ${
                SingleShoplistCollection?.name.includes('base-cart')
                  ? 'Your Shopping Cart'
                  : `${SingleShoplistCollection?.name} Shoplist`
              } will appear here`"
            />
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Bottom section -->
      <fixed-container
        customClass="!px-0 !space-y-0"
        v-if="SingleShoplistCollection?.collections?.length || productLists.length"
      >
        <app-cart v-if="SingleShoplistCollection?.type != 'user-generated'" />
        <div
          class="w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black"
        >
          <div class="flex flex-col space-y-[3px]">
            <app-header-text class="!text-base">
              {{ Logic.Common.convertToMoney(totalCost - totalDiscount, true, "ngn") }}
            </app-header-text>
            <div class="flex flex-row space-x-1 justify-start flex-grow items-center">
              <app-icon :name="'cashback-icon'" :customClass="'h-[15px]'" />
              <app-normal-text class="!text-[#898989] dark:!text-grey-200">
                Earn {{ numberToAbbrev(totalReward) }} pts
              </app-normal-text>
            </div>
          </div>
          <div
            class="py-3 px-8 bg-primary-main flex justify-center items-center rounded-[999px] cursor-pointer"
            @click="buyNow"
            v-if="!fromPreview && SingleShoplistCollection?.type == 'user-generated'"
          >
            <app-normal-text class="!font-semibold !text-white">
              Checkout
            </app-normal-text>
          </div>
          <div
            :class="`py-3 px-6 flex flex-row justify-center items-center space-x-2 rounded-[999px] cursor-pointer ${
              productIsInCart(SingleShoplistCollection?.uuid || '')
                ? 'bg-error-50'
                : 'bg-primary-main'
            }`"
            @click="addOrRemoveFromCart"
            v-if="!fromPreview && SingleShoplistCollection?.type != 'user-generated'"
          >
            <app-normal-text
              :class="`!font-semibold ${
                productIsInCart(SingleShoplistCollection?.uuid || '')
                  ? '!text-error-main'
                  : '!text-white'
              }`"
            >
              {{
                productIsInCart(SingleShoplistCollection?.uuid || "")
                  ? "Remove from Cart"
                  : "Add to Cart"
              }}
            </app-normal-text>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppBadge,
  AppIcon,
  AppCart,
  AppMediaBox,
  AppEmptyState,
} from "@shpt/ui-components";
import { getMediaBox, getMediaBoxForProduct } from "@shpt/ui-components/src/composable";
import { Collection, ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { scrollToTop } from "@shpt/ui-components/src/composable";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";
import { ManageCollectionAction } from "@shpt/logic/src/gql/graphql";
import { watch } from "vue";

type ProductList = {
  uuid: string;
  title: string;
  weight: string;
  main_category: string;
  price: number;
  base_image: string;
  qty: number;
  id: string;
  reward_type: string;
  data: ShoplistProduct;
  max_qty: number;
  discount: number;
  reward_percentage: number;
  reward_point: number;
  cashback: number;
  available_in_area: boolean;
  business: {
    name: string;
    id: string;
  };
  delivery: {
    id: string;
    cost: string;
  };
  has_delivery: boolean;
  shoplist_id: string;
  variant_id: string;
};

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppBadge,
    AppWrapper,
    AppIcon,
    AppCart,
    AppMediaBox,
    AppEmptyState,
    FixedContainer,
  },
  name: "ShoplistInfoPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistCollection",
        method: "GetShoplistCollection",
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyUserShoplistCarts",
        method: "GetUserShoplistCarts",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Shopplist Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    const SingleShoplistCollection = ref(Logic.Shoplist.SingleShoplistCollection);
    const ManyUserShoplistCarts = ref(Logic.Shoplist.ManyUserShoplistCarts);
    const UserProductCart = ref(Logic.Shoplist.UserProductCart);

    const fromPreview = ref(false);

    const totalReward = computed(() => {
      let total = 0;

      // For products
      productLists.forEach((item) => {
        total += item.reward_point * item.qty;
      });

      // For collections
      SingleShoplistCollection.value?.collections?.forEach((item) => {
        const mediaBox = getMediaBox(item);

        if (mediaBox) {
          total += mediaBox.points || 0;
        }
      });
      return total;
    });

    const totalDiscount = computed(() => {
      let total = 0;
      return total;
    });

    const totalCost = computed(() => {
      let total = 0;

      // Products
      productLists.forEach((item) => {
        total += item.price * item.qty - item.discount;
      });

      // Collections
      SingleShoplistCollection.value?.collections?.forEach((item) => {
        const mediaBox = getMediaBox(item);
        if (mediaBox) {
          total += mediaBox.price || 0;
        }
      });
      return total;
    });

    const addedToCart = computed(() => {
      return ManyUserShoplistCarts.value?.some(
        (item) => item.collection?.id == SingleShoplistCollection.value?.id
      );
    });

    const mediaData = computed(() => {
      return SingleShoplistCollection.value
        ? getMediaBox(SingleShoplistCollection.value)
        : undefined;
    });

    const productLists = reactive<ProductList[]>([]);

    const productIsInCart = (uuid: string) => {
      return UserProductCart.value?.collections?.find((item) => {
        return item.uuid == uuid;
      })
        ? true
        : false;
    };

    const addOrRemoveFromCart = () => {
      if (productIsInCart(SingleShoplistCollection.value?.uuid || "")) {
        Logic.Shoplist.AddRemoveCollectionProductForm = {
          collection_id: parseInt(Logic.Shoplist.UserProductCart?.id || "0"),
          products: [parseInt(SingleShoplistCollection.value?.id || "0")],
          action: ManageCollectionAction.Remove,
          type: "collection",
        };

        Logic.Common.showAlert({
          show: true,
          message: `Shoplist removed from Cart`,
          type: "success",
        });

        const oldCartData = JSON.parse(localStorage.getItem("user_product_cart") || "{}");
        oldCartData.collections = oldCartData.collections.filter(
          (collection: any) => collection.id !== SingleShoplistCollection.value?.id
        );
        Logic.Shoplist.UserProductCart = oldCartData;

        localStorage.setItem(
          "user_product_cart",
          JSON.stringify(Logic.Shoplist.UserProductCart)
        );
      } else {
        saveMetric("clicked");

        Logic.Shoplist.AddRemoveCollectionProductForm = {
          collection_id: parseInt(Logic.Shoplist.UserProductCart?.id || "0"),
          products: [parseInt(SingleShoplistCollection.value?.id || "0")],
          action: ManageCollectionAction.Add,
          type: "collection",
        };

        Logic.Common.showAlert({
          show: true,
          message: `Shoplist added to Cart`,
          type: "success",
        });

        const oldCartData = JSON.parse(JSON.stringify(Logic.Shoplist.UserProductCart));
        oldCartData.collections.push(SingleShoplistCollection.value);

        Logic.Shoplist.UserProductCart = oldCartData;
        localStorage.setItem(
          "user_product_cart",
          JSON.stringify(Logic.Shoplist.UserProductCart)
        );
      }

      Logic.Shoplist.AddRemoveCollectionProduct()?.then((data: any) => {
        if (data) {
          Logic.Shoplist.GetUserProductCart();
        }
      });
    };

    const createProductList = () => {
      productLists.length = 0;

      SingleShoplistCollection.value?.products.forEach((product) => {
        let discount = 0;
        let reward_percentage = 0;
        let reward_point = 0;
        let cashback = 0;
        let rewardType = "cashback";

        const mediaBox = getMediaBoxForProduct(product);
        reward_percentage = mediaBox?.reward_percentage || 0;

        const savedSelectedVariant =
          localStorage.getItem(`selected_variant_for_product_${product.uuid}`) || "";

        let baseImage = product.primary_image_url;
        let productRealPrice = parseFloat(product.regular_price || "0");
        let maxQty = product.stock_quantity;

        if (savedSelectedVariant) {
          const variant = product.variants?.find(
            (variant) => variant?.id == savedSelectedVariant
          );

          if (variant?.base_image_url) {
            baseImage = variant?.base_image_url;
          }
          productRealPrice = parseFloat(variant?.price?.replaceAll(",", "") || "0");
          maxQty = variant?.inventory_quantity || 0;
        }

        cashback = productRealPrice * (reward_percentage / 100) * 10;
        reward_point = cashback;

        productLists.push({
          uuid: product.uuid,
          base_image: baseImage,
          main_category: product.category.name,
          price: productRealPrice,
          title: product.name,
          weight: "",
          qty: 1,
          id: product.id,
          data: product,
          max_qty: maxQty,
          reward_type: rewardType,
          discount,
          reward_percentage,
          reward_point,
          cashback,
          available_in_area: true,
          business: {
            name: product.business?.business_name || "",
            id: product.business?.id || "",
          },
          delivery: {
            id: "",
            cost: "",
          },
          has_delivery: product.deliverable || false,
          shoplist_id: SingleShoplistCollection.value?.id || "",
          variant_id: savedSelectedVariant,
        });
      });

      if (Logic.Common.currentBuildType() == "web") {
        document.title = `${SingleShoplistCollection?.value?.name} | Shoppoint`;
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        if (currentPathQuery.preview?.toString()) {
          fromPreview.value = true;
        }
      } else {
        fromPreview.value = true;
      }
    };

    const goToShoplist = (data: Collection, uuid: string) => {
      Logic.Shoplist.SingleShoplistCollection = data;
      Logic.Common.GoToRoute(`/shoplist/${uuid}?force_load=true`);
    };

    const removeProductFromCollection = (
      product_id: string,
      type: "product" | "collection" = "product"
    ) => {
      Logic.Shoplist.AddRemoveCollectionProductForm = {
        collection_id: parseInt(Logic.Shoplist.SingleShoplistCollection?.id || "0"),
        products: [parseInt(product_id || "0")],
        action: ManageCollectionAction.Remove,
        type: type,
      };

      Logic.Common.showAlert({
        show: true,
        message: `${type == "product" ? "Product" : "Shoplist"} removed from ${
          SingleShoplistCollection.value?.name.includes("base-cart")
            ? "Cart"
            : SingleShoplistCollection.value?.name
        }`,
        type: "success",
      });

      const oldData = JSON.parse(JSON.stringify(Logic.Shoplist.SingleShoplistCollection));

      // for products
      if (type == "product") {
        oldData.products = oldData.products.filter(
          (product: any) => product.id !== product_id
        );
      }

      // for collections
      if (type == "collection") {
        oldData.collections = oldData.collections.filter(
          (collection: any) => collection.id !== product_id
        );
      }

      Logic.Shoplist.SingleShoplistCollection = oldData;

      Logic.Shoplist.AddRemoveCollectionProduct()?.then((data: any) => {
        if (data) {
          Logic.Shoplist.GetShoplistCollection(
            SingleShoplistCollection.value?.uuid || ""
          );
          if (SingleShoplistCollection.value?.name == "base-cart") {
            Logic.Shoplist.GetUserProductCart();
          } else {
            Logic.Shoplist.GetShoplistCollections(
              1,
              30,
              "CREATED_AT",
              "DESC",
              `{
                          column: PROFILE_ID
                          operator: EQ
                          value: ${Logic.Auth.AuthUser?.profile?.id}
                        }`,
              false
            );
          }
        }
      });
    };

    const handleAddToCart = () => {
      if (addedToCart.value) {
        Logic.Common.GoToRoute("/basket");
      } else {
        saveMetric("added_to_basket");
        Logic.Common.showAlert({
          type: "success",
          show: true,
          message: "Shoplist has been added to your basket",
        });
        Logic.Shoplist.CreateUserShoplistCart(SingleShoplistCollection.value?.id || "")
          ?.then((data) => {
            if (!data) {
              //
            }
          })
          .catch(() => {
            Logic.Common.showAlert({
              type: "error",
              show: true,
              message: "Failed to add shoplist to basket",
            });
          });
      }
    };

    const saveMetric = (event_type: string) => {
      const sponsored_uuid = localStorage.getItem(
        `sponsored_shoplist_${SingleShoplistCollection.value?.uuid}`
      );
      Logic.Ad.SaveMetric({
        entity_type: "shoplist",
        event_type,
        entity_uuid: SingleShoplistCollection.value?.uuid || "",
        event_metadata: JSON.stringify({
          type: "shoplist",
          shoplist_uuid: SingleShoplistCollection.value?.uuid || "",
        }),
      });

      if (sponsored_uuid) {
        const sponsored_shoplist_request_uuid = localStorage.getItem(
          `sponsored_shoplist_request_uuid_${SingleShoplistCollection.value?.uuid}`
        );
        Logic.Ad.SaveMetric({
          entity_type: "advert",
          event_type,
          entity_uuid: sponsored_uuid,
          event_metadata: JSON.stringify({
            type: "advert",
            advert_uuid: sponsored_uuid,
            request_uuid: sponsored_shoplist_request_uuid,
          }),
        });
      }
    };

    const buyNow = async () => {
      saveMetric("clicked");
      Logic.Shoplist.Storage.set(
        `checkout_setup_${SingleShoplistCollection.value?.uuid}`,
        JSON.stringify({
          products: productLists,
          totalCost: totalCost.value,
          totalReward: totalReward.value,
          totalDiscount: totalDiscount.value,
        })
      ).then(() => {
        Logic.Common.GoToRoute(
          `/shoplist/checkout/${SingleShoplistCollection.value?.uuid}?collection=true`
        );
      });
    };

    watch(SingleShoplistCollection, () => {
      createProductList();
    });

    onIonViewWillEnter(() => {
      createProductList();
      setPageState();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("SingleShoplistCollection", SingleShoplistCollection);
      Logic.Shoplist.watchProperty("ManyUserShoplistCarts", ManyUserShoplistCarts);
      Logic.Shoplist.watchProperty("UserProductCart", UserProductCart);
      createProductList();
      setPageState();
      scrollToTop();
    });

    return {
      Logic,
      productLists,
      SingleShoplistCollection,
      mediaData,
      fromPreview,
      totalReward,
      addedToCart,
      totalCost,
      totalDiscount,
      getMediaBox,
      addOrRemoveFromCart,
      productIsInCart,
      buyNow,
      handleAddToCart,
      numberToAbbrev,
      removeProductFromCollection,
      goToShoplist,
    };
  },
});
</script>
