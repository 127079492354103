<template>
  <template v-if="Logic.Common.currentBuildType() == 'mobile'">
    <ion-page
      :registerIonPage="registerIonPage"
      id="ionPageApp"
      :class="`${innerClassRef}`"
    >
      <ion-content :scroll-y="false" id="mainContent" :class="`${innerClassRef}`">
        <slot />
      </ion-content>
    </ion-page>
  </template>
  <template v-if="Logic.Common.currentBuildType() == 'web'">
    <div :class="`w-full h-full ${innerClassRef} `" id="ionPageApp">
      <slot />
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, toRef, watch } from "vue";
import { IonContent, IonPage } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { ionHandlers } from "@shpt/ui-components/src/composable";
import { ref } from "vue";

export default defineComponent({
  props: {
    class: {
      type: String,
      default: "",
    },
    registerIonPage: {
      type: Function,
      default: () => {
        //
      },
    },
    innerClass: {
      type: String,
      default: "bg-white dark:bg-black",
    },
  },
  components: {
    IonContent,
    IonPage,
  },
  name: "AppWrapper",
  setup(props) {
    const innerClassRef = toRef(props, "innerClass");

    const forcePageTransparency = ref(false);

    onMounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        document.body.classList.add("!bg-white", "dark:!bg-black");
      } else {
        document.body.classList.add("!bg-transparent");
      }

      Logic.Common.watchProperty("forcePageTransparency", forcePageTransparency);
    });

    watch(forcePageTransparency, (value) => {
      console.log("forcePageTransparency", value);
      if (value) {
        if (Logic.Common.currentBuildType() == "mobile") {
          // Set background color of body to transparent
          document.body.style.backgroundColor = "transparent";

          // Set background color of mainApp to transparent
          const mainApp = document.getElementById("mainApp");
          if (mainApp) {
            mainApp.style.backgroundColor = "transparent";
          }

          // Set background color of ionPageApp to transparent
          const ionPageApp = document.getElementById("ionPageApp");
          if (ionPageApp) {
            ionPageApp.style.backgroundColor = "transparent";
          }

          // Set background color of mainContent to transparent
          const mainContent = document.getElementById("mainContent");
          if (mainContent) {
            mainContent.style.backgroundColor = "transparent";
          }
        }
      } else {
        // Return to default background color
        if (Logic.Common.currentBuildType() == "mobile") {
          // Set background color of body to transparent
          document.body.style.backgroundColor =
            Logic.Common.currentAppTheme() == "light" ? "white !important" : "black !important";

          // Set background color of mainApp to transparent
          const mainApp = document.getElementById("mainApp");
          if (mainApp) {
            mainApp.style.backgroundColor =
              Logic.Common.currentAppTheme() == "light" ? "white !important" : "black !important";
          }

          // Set background color of ionPageApp to transparent
          const ionPageApp = document.getElementById("ionPageApp");
          if (ionPageApp) {
            ionPageApp.style.backgroundColor =
              Logic.Common.currentAppTheme() == "light" ? "white !important" : "black !important";
          }

          // Set background color of mainContent to transparent
          const mainContent = document.getElementById("mainContent");
          if (mainContent) {
            mainContent.style.backgroundColor =
              Logic.Common.currentAppTheme() == "light" ? "white !important" : "black !important";
          }
        }
      }
    });

    return {
      Logic,
      ionHandlers,
      innerClassRef,
    };
  },
});
</script>
