<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Existing delivery info -->
    <template v-if="currentView === 'select'">
      <div class="w-full flex flex-col space-y-3">
        <template v-if="allDeliveryInfo.length > 0">
          <template v-for="(item, index) in allDeliveryInfo" :key="index">
            <div
              :class="`w-full flex flex-col space-y-1 bg-[#F3F3F3] dark:!bg-gray-900 rounded-[10px] cursor-pointer px-3 py-3 border-gray-200 dark:!border-gray-700 ${
                item.id == data?.selected_delivery_info?.id
                  ? 'border-primary-400  border-[2px]'
                  : 'border-gray-200  border-[1px]'
              }`"
              @click="selectDeliveryInfo(item)"
            >
              <app-normal-text class="!text-[#202020] dark:!text-gray-50 !font-semibold">
                {{ item.contact_name }}
              </app-normal-text>
              <app-normal-text class="text-left !text-[11px]">
                {{ item.delivery_address.name }}
              </app-normal-text>
              <app-normal-text class="text-left !text-[11px]">
                {{ item.contact_phone }}
              </app-normal-text>
            </div>
          </template>
        </template>

        <template v-else>
          <div
            class="w-full flex flex-row items-center justify-center h-[60px] bg-[#F3F3F3] dark:!bg-gray-900 rounded-[10px] py-4"
          >
            <app-normal-text class="!text-[#202020] dark:!text-gray-50">
              You have no delivery address yet
            </app-normal-text>
          </div>
        </template>
      </div>
    </template>

    <!-- Enter Name -->

    <app-normal-text class="text-left font-semibold text-base">
      Add a new delivery address
    </app-normal-text>

    <app-text-field
      :placeholder="'Enter name'"
      :hasTitle="true"
      :type="'text'"
      :padding="'py-3 px-3'"
      name="Name e.g John Doe"
      ref="contactName"
      v-model="formData.contact_name"
      :updateValue="formData.contact_name"
      :rules="[
        FormValidations.RequiredRule,
        FormValidations.customValidator(
          formData.contact_name.length > 2,
          'Name must be more than 2 characters'
        ),
      ]"
      required
    >
      <template v-slot:title> Contact Name </template>
    </app-text-field>

    <app-text-field
      :placeholder="'Enter phone number'"
      v-model="formData.contact_phone"
      :hasTitle="true"
      type="tel"
      :padding="'py-3 px-3'"
      name="Phone number e.g 08012345678"
      ref="contactPhone"
      :updateValue="formData.contact_phone"
      :rules="[
        FormValidations.RequiredRule,
        FormValidations.customValidator(
          formData.contact_phone.length == 11,
          'Phone number must be valid'
        ),
      ]"
      :content-rule="{
        addAfterCount: 0,
        characterToAdd: ``,
        max: 11,
      }"
      required
    >
      <template v-slot:title> Contact Phone </template>
    </app-text-field>

    <app-select
      :placeholder="'Select area'"
      :hasTitle="true"
      v-model="formData.delivery_address.name"
      :paddings="'px-3 py-3'"
      ref="deliveryAddress"
      @OnSearch="handleAreaSearch"
      :customClass="'dark:!bg-grey-800'"
      :options="areaOptions"
      :theme="Logic.Common.currentAppTheme()"
      autoComplete
      :hasSearch="true"
      :searchMessage="'Search for your delivery address'"
      :searchIsLoading="areaSearchIsLoading"
      required
    >
      <template v-slot:title> Contact Address </template>
    </app-select>

    <app-button
      :class="`w-full ${formIsValid ? '' : 'opacity-50'}`"
      :padding="'!py-3'"
      :loading="savingDeliveryInfo"
      @click.prevent="saveDeliveryInfo"
    >
      Save
    </app-button>
  </app-form-wrapper>
</template>
<script lang="ts">
import { SelectOption } from "../../types";
import { google } from "google-maps";
import { computed, defineComponent, onMounted, PropType, reactive, ref } from "vue";
import { Logic } from "../../composable";
import { AppFormWrapper, AppTextField, AppSelect } from "../AppForm";
import { AppNormalText } from "../AppTypography";
import AppButton from "../AppButton";

type DeliveryInfo = {
  id: string;
  contact_name: string;
  contact_phone: string;
  delivery_address: {
    name: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
};
export default defineComponent({
  props: {
    data: {
      type: Object as PropType<{
        delivery_addresses: DeliveryInfo[];
        selected_delivery_info: DeliveryInfo;
        defaults: {
          name?: string;
          phone?: string;
        };
      }>,
      required: false,
    },
    action: {
      type: Function,
      required: false,
    },
  },
  components: {
    AppTextField,
    AppFormWrapper,
    AppSelect,
    AppNormalText,
    AppButton,
  },
  name: "AppModalDeliveryInfo",
  setup(props) {
    const FormValidations = Logic.Form;

    const areaSearchIsLoading = ref(false);

    const savingDeliveryInfo = ref(false);

    const currentView = ref<"select" | "add">("select");

    const formData = reactive({
      contact_name: "",
      contact_phone: "",
      delivery_address: {
        name: "",
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
      },
    });

    const allDeliveryInfo = reactive<DeliveryInfo[]>([]);

    const contactName = ref("");
    const contactPhone = ref("");
    const deliveryAddress = ref("");

    const areaOptions = reactive<SelectOption[]>([]);

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const formIsValid = computed(() => {
      return (
        formData.contact_name != "" &&
        formData.contact_phone != "" &&
        formData.delivery_address.name != "" &&
        formData.contact_phone.length == 11 &&
        formData.contact_name.length > 2
      );
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        return formData;
      } else {
        return false;
      }
    };

    const handleAreaSearch = async (searchValue: string) => {
      Logic.Common.debounce(() => {
        try {
          const google = window.google;
          if (google) {
            const service = new google.maps.places.AutocompleteService();

            if (service && searchValue.length) {
              areaSearchIsLoading.value = true;
              areaOptions.length = 0;
              service.getQueryPredictions(
                {
                  input: searchValue,
                },
                (predictions: any) => {
                  predictions?.forEach((prediction: any) => {
                    areaOptions.push({
                      key: prediction.description,
                      value: prediction.description,
                    });
                  });
                  areaSearchIsLoading.value = false;
                }
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    };

    const saveDeliveryInfo = () => {
      if (formIsValid.value) {
        savingDeliveryInfo.value = true;
        Logic.Shoplist.CreateUserDeliveryLocationForm = {
          title: formData.contact_name,
          address: formData.delivery_address.name,
          phone: formData.contact_phone,
          user_name: formData.contact_name,
          user_email: Logic.Auth.AuthUser?.email || "",
          default: false,
        };
        Logic.Shoplist.CreateUserDeliveryLocation()
          ?.then((response: any) => {
            if (response) {
              Logic.Shoplist.GetDeliveryLocation(
                1,
                50,
                "CREATED_AT",
                "DESC",
                `{
        column: USER_ID
        operator: EQ
        value: ${Logic.Auth.AuthUser?.id}
      }`
              ).then((response: any) => {
                if (response) {
                  savingDeliveryInfo.value = false;
                  Logic.Common.showAlert({
                    show: true,
                    type: "success",
                    message: "Delivery address added successfully",
                  });
                  const newDeliveryInfo = response.data[0];
                  if (newDeliveryInfo) {
                    selectDeliveryInfo({
                      id: newDeliveryInfo.id,
                      contact_name: newDeliveryInfo.title,
                      contact_phone: newDeliveryInfo.phone,
                      delivery_address: {
                        name: newDeliveryInfo.address,
                        coordinates: {
                          latitude: parseFloat(newDeliveryInfo.lat || "0") || 0,
                          longitude: parseFloat(newDeliveryInfo.lng || "0") || 0,
                        },
                      },
                    });
                  }
                }
              });
            }
          })
          .catch(() => {
            savingDeliveryInfo.value = false;
            Logic.Common.showAlert({
              show: true,
              type: "error",
              message: "Oops! Unable to save delivery address",
            });
          });
      }
    };

    const selectDeliveryInfo = (data: DeliveryInfo) => {
      if (props.action) {
        props.action(data);
      }
    };

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(contactName.value, contactPhone.value, deliveryAddress.value);
      if (props.data) {
        if (props.data.delivery_addresses) {
          allDeliveryInfo.push(...props.data.delivery_addresses);
        }
        if (props.data.defaults) {
          formData.contact_name = props.data.defaults.name || "";
          formData.contact_phone = props.data.defaults.phone
            ? props.data.defaults.phone.startsWith("234")
              ? "0" + props.data.defaults.phone.slice(3)
              : props.data.defaults.phone
            : "";
        }
      }
    });

    return {
      FormValidations,
      formComp,
      parentRefs,
      contactName,
      contactPhone,
      deliveryAddress,
      formData,
      allDeliveryInfo,
      currentView,
      areaOptions,
      formIsValid,
      areaSearchIsLoading,
      savingDeliveryInfo,
      Logic,
      saveDeliveryInfo,
      validateForm,
      handleAreaSearch,
      selectDeliveryInfo,
    };
  },
});
</script>
