import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col items-center justify-start space-y-2 fixed !font-inter h-screen !pt-0 top-0 left-0 z-[9999999999999]  px-4`)
}
const _hoisted_2 = {
  class: "w-full flex flex-row items-center justify-between !bg-opacity-100 !opacity-100 z-[99999] !bg-transparent",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_3 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_4 = { class: "flex flex-col space-y-1" }
const _hoisted_5 = {
  class: "w-full flex flex-row justify-center items-start z-[99999] pt-3",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_onboarding_layout = _resolveComponent("onboarding-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, { innerClass: '!bg-transparent' }, {
    default: _withCtx(() => [
      _createVNode(_component_onboarding_layout, {
        class: "!bg-transparent",
        title: 'clear',
        id: "videoContainer"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("div", {
              id: "reader",
              width: "0px",
              class: "hidden"
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(`w-[40px] h-[40px] xs:w-[43px] xs:h-[43px] rounded-full flex flex-row items-center justify-center`),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {
                _ctx.Logic.Common.goBack();
                _ctx.CameraPreview.stop();
              })
                }, [
                  _createVNode(_component_app_icon, {
                    name: 'back-arrow-grey',
                    customClass: "h-[38px]"
                  })
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_header_text, {
                    color: '!text-black',
                    class: "!font-semibold"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Scan QR Code ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-full flex flex-row justify-center items-start z-[99999]" }, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("div", {
              class: _normalizeClass(`w-[95%] h-full h-min-[300px] flex-grow flex flex-col transparent-center  items-center rounded-[22px] relative `),
              id: "scanContainer"
            }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.snappedImages.length == 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.startScanning())),
                    class: "w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-white shadow-custom flex flex-row justify-center items-center"
                  }, [
                    _createVNode(_component_app_icon, {
                      name: 'scan',
                      customClass: 'h-[28px] xs:h-[22px]'
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}