<template>
  <app-wrapper>
    <dashboard-layout topBarType="plain" title="Shop" :pageKey="'shoplist'">
      <div class="w-full flex flex-col space-y-4 pt-4">
        <!-- Search and delivery area -->
        <div class="w-full flex flex-row px-4">
          <div class="w-full flex flex-col">
            <app-text-field
              class="w-full"
              :placeholder="'Search for products, shoplists or brands'"
              :inputStyle="''"
              @focused="searchIsFocused = true"
              @blurred="searchIsFocused = false"
              v-model="searchValue"
            >
              <template v-slot:inner-prefix>
                <span>
                  <app-icon :custom-class="''" :name="'search'"></app-icon>
                </span>
              </template>

              <template v-slot:inner-suffix>
                <app-loading
                  class="!text-primary-400 dark:!text-white"
                  v-if="searchValue.length > 2 && searchResultsLoading"
                />
              </template>
            </app-text-field>
          </div>
        </div>

        <template v-if="searchValue.length > 2">
          <div class="w-full flex flex-col space-y-3 px-4">
            <app-normal-text
              v-if="ShoplistSearchResults.data.length"
              class="w-full !text-left"
            >
              Search results for
              <span class="!font-semibold">"{{ searchValue }}"</span>
            </app-normal-text>

            <div
              class="w-full flex flex-col items-center justify-center h-[350px]"
              v-if="!ShoplistSearchResults.data.length && !searchResultsLoading"
            >
              <app-empty-state
                :title="'No products found'"
                :subTitle="`No products found for '${searchValue}'`"
              />
            </div>

            <div class="w-full flex flex-col" v-else>
              <app-virtual-scroller
                :data="ShoplistSearchResults.data"
                :pagination="ShoplistSearchResults.paginatorInfo"
                :fetchMore="fetchMoreShoplistSearchResults"
                :container-class="` grid grid-cols-2 gap-4 !space-y-0`"
              >
                <template #item-content="{ index, item }">
                  <template v-if="itemIsMediaBox(item)">
                    <app-shoplist-explore
                      :key="index"
                      :item="item"
                      @click="handleShoplsistClick(item)"
                    />
                  </template>
                  <template v-else>
                    <app-business
                      :item="item"
                      @click="Logic.Common.GoToRoute(`/brand?uuid=${item.data.uuid}`)"
                    />
                  </template>
                </template>
                <template #skeleton-loaders>
                  <div
                    class="col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton"
                  ></div>
                  <div
                    class="col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton"
                  ></div>
                </template>
              </app-virtual-scroller>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- Banner Ads -->
          <div class="w-full flex flex-col space-y-3 px-4">
            <app-banner-ad :data="bannerAds" />
          </div>

          <!-- Top shoplists -->
          <div class="w-full flex flex-col space-y-2">
            <!-- Categories -->
            <div class="w-full flex flex-col pl-4 !-mt-3">
              <div
                class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
                id="categoryContainerShoplist"
              >
                <div class="flex flex-row py-2 pr-4">
                  <div
                    class="flex flex-row cursor-pointer pr-2"
                    v-for="(category, index) in categoryOptions"
                    :key="index"
                    @click="selectedCategory = category.key"
                    :id="`category${category.key}`"
                  >
                    <div
                      :class="` ${
                        selectedCategory == category.key
                          ? 'bg-primary-main'
                          : 'bg-[#f3f3f3] dark:bg-gray-700'
                      } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`"
                    >
                      <app-normal-text
                        :class="`!whitespace-nowrap ${
                          selectedCategory == category.key
                            ? 'text-white'
                            : 'text-gray-600 dark:text-gray-50'
                        }`"
                      >
                        {{ category.value }}
                      </app-normal-text>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full flex flex-row items-center justify-between px-4 pt-2">
              <app-header-text :customClass="'!text-left  !text-base !font-semibold'">
                Most Popular Savings Deals
              </app-header-text>
              <app-normal-text
                class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
                @click="
                  Logic.Common.GoToRoute(`/shoplist/products?key=${selectedCategory}`)
                "
              >
                See all >
              </app-normal-text>
            </div>
            <div class="w-full flex flex-col">
              <app-virtual-scroller
                :container-class="'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2'"
                :data="topCashbackOffers.data"
                :pagination="topCashbackOffers.paginatorInfo"
                :fetchMore="fetchMoreCashbackOffers"
                :fetchNew="fetchNewCashbackOffers"
                :direction="'horizontal'"
                ref="productVirtualScrollerRef"
              >
                <template #item-content="{ index, item }">
                  <div>
                    <app-shoplist-recommended
                      :item="item"
                      @click="handleShoplsistClick(item)"
                      :key="index"
                      :hideCategory="true"
                    />
                  </div>
                </template>
                <template #skeleton-loaders>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[180px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[180px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                </template>
              </app-virtual-scroller>
            </div>
          </div>

          <!-- Featured Shoplists -->
          <div class="w-full flex flex-col space-y-2 pt-3">
            <div class="w-full flex flex-row items-center justify-between px-4">
              <app-header-text :customClass="'!text-left  !text-base !font-semibold'">
                Limited Bundle Deals
              </app-header-text>
              <app-normal-text
                class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
                @click="
                  Logic.Common.GoToRoute(
                    `/shoplist/featured?key=${
                      selectedCategory == 'All' ? '0' : selectedCategory
                    }`
                  )
                "
              >
                See all >
              </app-normal-text>
            </div>
            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
              v-if="sponsoredShoplists"
            >
              <div class="flex flex-row space-x-2 py-1 pr-4">
                <app-shoplist-sponsored
                  v-for="(item, index) in sponsoredShoplists"
                  :key="index"
                  :item="item"
                  @click="
                    setStoryContent(ContentType.SponsoredShoplist, {
                      id: parseInt(item.id),
                      type: item.type,
                    })
                  "
                />
                <div
                  class="flex flex-col space-y-3 h-full justify-end"
                  v-if="showSponsoredShoplistIsLoading"
                >
                  <div class="w-[270px] skeleton !rounded-[10px]"></div>
                </div>
              </div>
              <div id="visibility-handle-sponsored-shoplist"></div>
            </div>
          </div>

          <!-- Top Brands -->
          <div class="w-full flex flex-col space-y-2 pt-3">
            <div class="w-full flex flex-row items-center justify-between px-4">
              <app-header-text :customClass="'!text-left  !text-base !font-semibold'">
                Top brands
              </app-header-text>
              <app-normal-text
                class="!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase"
                @click="
                  Logic.Common.GoToRoute(
                    `/shoplist/brands?key=${
                      selectedCategory == 'All' ? '0' : selectedCategory
                    }`
                  )
                "
              >
                See all >
              </app-normal-text>
            </div>

            <div class="w-full flex flex-col" v-if="ManyBusinesses">
              <app-virtual-scroller
                :container-class="'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2'"
                :data="ManyBusinesses.data"
                :pagination="ManyBusinesses.paginatorInfo"
                :fetchMore="fetchMoreBusinesses"
                :direction="'horizontal'"
              >
                <template #item-content="{ index, item }">
                  <app-business
                    :item="item"
                    :key="index"
                    @click="Logic.Common.GoToRoute(`/brand?uuid=${item.uuid}`)"
                  />
                </template>
                <template #skeleton-loaders>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                </template>
              </app-virtual-scroller>
            </div>
          </div>
        </template>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { BannerAd } from "@/modules/common";
import {
  AppShoplistSponsored,
  AppNormalText,
  AppTextField,
  AppIcon,
  AppBannerAd,
  AppHeaderText,
  AppLoading,
  AppVirtualScroller,
  AppEmptyState,
  AppShoplistExplore,
  AppBusiness,
  AppShoplistRecommended,
} from "@shpt/ui-components";
import {
  getMediaBox,
  getMediaBoxForProduct,
  numberToAbbrev,
  onExploreShoplistsScrolledToEnd,
  onRecommendedScrolledToEnd,
  onSponsoredShoplistScrolledToEnd,
  showExploreIsLoading,
  showRecommendationIsLoading,
  showSponsoredShoplistIsLoading,
} from "@shpt/ui-components/src/composable";
import { Advert, Collection, ContentType } from "@shpt/logic/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import AppWrapper from "@/components/AppWrapper.vue";
import { MediaBox } from "@shpt/ui-components/src/types";
import {
  ShoplistSearchResults,
  searchValue,
  searchResultsLoading,
} from "@shpt/ui-components/src/composable/shop";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { SelectOption } from "@shpt/logic/src/logic/types/common";
import { PaginatorInfo } from "@shpt/logic/dist/esm/gql/graphql";
import { scrollToSpecificItem } from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppTextField,
    AppIcon,
    AppBannerAd,
    AppHeaderText,
    AppWrapper,
    AppLoading,
    AppVirtualScroller,
    AppEmptyState,
    AppShoplistExplore,
    AppShoplistSponsored,
    AppBusiness,
    AppShoplistRecommended,
  },
  name: "ShopListPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategoriesWithProducts",
        method: "GetCategoriesWithProducts",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "CashbackOffersCollections",
        method: "GetCashbackOffersCollections",
        params: [0, 1, 10, false],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "CashbackOffersProducts",
        method: "GetCashbackOffersProducts",
        params: [0, 1, 10, false],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "SponsoredShoplistContent",
        method: "GetUserContent",
        params: ["", [ContentType.SponsoredShoplist, ContentType.Explore], 1, 6],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyBusinesses",
        method: "GetBusiness",
        params: [1, 10],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Shoplist Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Shop",
    });

    const authUser = ref(Logic.Auth.AuthUser);

    const selectedCategory = ref("All");

    const searchIsFocused = ref(false);
    const productVirtualScrollerRef = ref();

    const categoryNames = reactive<string[]>([]);

    const categoryOptions = reactive<SelectOption[]>([]);

    const CashbackOffersProducts = ref(Logic.Shoplist.CashbackOffersProducts);
    const CashbackOffersCollections = ref(Logic.Shoplist.CashbackOffersCollections);
    const ManyCategoriesWithProducts = ref(Logic.Shoplist.ManyCategoriesWithProducts);
    const ExploreContent = ref(Logic.User.ExploreContent);
    const SponsoredShoplistContent = ref(Logic.User.SponsoredShoplistContent);
    const SearchResults = ref(Logic.Shoplist.SearchResults);
    const ManyBusinesses = ref(Logic.Shoplist.ManyBusinesses);
    const ManyBrandsByCategory = ref(Logic.Shoplist.ManyBrandsByCategory);
    const ManyCollectionsByCategory = ref(Logic.Shoplist.ManyCollectionsByCategory);

    const recommendedShopLists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const exploreShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const topCashbackOffers = reactive<{
      data: {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[];
      paginatorInfo: PaginatorInfo | undefined;
    }>({
      data: [],
      paginatorInfo: undefined,
    });

    const sponsoredShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: "collection" | "ad";
      }[]
    >([]);

    const bannerAds = reactive<BannerAd[]>([
      {
        base_image: "/images/temp/ad-image-1.png",
        has_video: false,
        variant: "red",
        content: {
          main_caption: "Weekly Meal Plans",
          sub_caption: "Save up to 10% Cashbash with",
          cta: "Shop List",
        },
      },
      {
        base_image: "/images/temp/ad-image-1.png",
        has_video: false,
        variant: "brown",
        content: {
          main_caption: "Weekly Meal Plans",
          sub_caption: "Save up to 10% Cashbash with",
          cta: "Shop List",
        },
      },
    ]);

    const setCategory = () => {
      categoryOptions.length = 0;
      categoryOptions.push({
        key: "All",
        value: "All Categories",
      });
      ManyCategoriesWithProducts.value?.forEach((item) => {
        categoryOptions.push({
          key: item.uuid,
          value: item.name,
          extras: item.id.toString(),
        });
      });
    };

    const setTopCashbackOffers = (force = false) => {
      if (force) {
        topCashbackOffers.data.length = 0;
      }

      let cashbackOffers: any[] = [];

      // For products
      Logic.Shoplist.CashbackOffersProducts?.data.forEach((item) => {
        const mediaBox = getMediaBoxForProduct(item as ShoplistProduct);
        cashbackOffers.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "product",
          colors: {
            bg: "",
            text: "",
          },
        });
      });

      // For collections
      Logic.Shoplist.CashbackOffersCollections?.data.forEach((item) => {
        const mediaBox = getMediaBox(item as Collection);
        cashbackOffers.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "collection",
          colors: {
            bg: "",
            text: "",
          },
        });

        // Add results to Logic.User.ExploreContent if the collection item is not there already

        const existingContent = JSON.parse(JSON.stringify(Logic.User.ExploreContent));
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "collection",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });

      // Now sort  the cashback by points (descending)
      cashbackOffers.sort((a, b) => b.data.points - a.data.points);

      // remove all existing data in topCashbackOffers.data in cashbackOffers
      cashbackOffers = cashbackOffers.filter((item) => {
        const isExist = topCashbackOffers.data.some((topItem) => topItem.id === item.id);
        return !isExist;
      });

      topCashbackOffers.data.push(...cashbackOffers);

      
      // Set paginator info. The next page will be the greatest between the two paginator info
      topCashbackOffers.paginatorInfo =
        (Logic.Shoplist.CashbackOffersProducts?.paginatorInfo?.lastPage || 0) >
        (Logic.Shoplist.CashbackOffersCollections?.paginatorInfo?.lastPage || 0)
          ? Logic.Shoplist.CashbackOffersProducts?.paginatorInfo
          : Logic.Shoplist.CashbackOffersCollections?.paginatorInfo;
    };

    const setRecommendedShoplists = () => {
      //
    };

    const setShoplistSearchResults = () => {
      ShoplistSearchResults.data.length = 0;

      // For products
      SearchResults.value?.products?.data.forEach((item) => {
        const mediaBox = getMediaBoxForProduct(item as ShoplistProduct);
        if (mediaBox) {
          ShoplistSearchResults.data.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "media",
          });
        }

        // Add results to Logic.User.ExploreContent if the product item is not there already

        const existingContent = JSON.parse(JSON.stringify(Logic.User.ExploreContent));
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "product",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });

      // For collections
      SearchResults.value?.collections?.data.forEach((item) => {
        const mediaBox = getMediaBox(item as Collection);
        ShoplistSearchResults.data.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "media",
        });

        // Add results to Logic.User.ExploreContent if the collection item is not there already

        const existingContent = JSON.parse(JSON.stringify(Logic.User.ExploreContent));
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "collection",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });

      // For businesses
      SearchResults.value?.businesses?.data.forEach((item) => {
        ShoplistSearchResults.data.push({
          data: JSON.parse(JSON.stringify(item)),
          id: item.id.toString(),
          type: "business",
        });
      });
    };

    const setExploreShoplists = () => {
      exploreShoplists.length = 0;

      ExploreContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          exploreShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
        if (item.type == "product") {
          const mediaBox = getMediaBoxForProduct(item.data as ShoplistProduct);
          if (mediaBox) {
            exploreShoplists.push({
              data: mediaBox,
              id: item.id.toString(),
              type: "product",
              colors: {
                bg: "",
                text: "",
              },
            });
          }
        }
      });
    };

    const setSponsoredShoplists = () => {
      sponsoredShoplists.length = 0;

      SponsoredShoplistContent.value?.contents.forEach((item) => {
        const adContent: Advert = item.data as Advert;

        if (adContent) {
          let adType: any = adContent.type;
          if (item.type == "ad" && adType == "shoppable") {
            const shoplistData = (item.data as Advert).advert_media?.shoplist;
            const productData = (item.data as Advert).advert_media?.product;
            if (shoplistData) {
              const mediaBox = getMediaBox(shoplistData);

              sponsoredShoplists.push({
                data: mediaBox,
                id: item.id.toString(),
                type: "ad",
              });
            } else if (productData) {
              const mediaBox = getMediaBoxForProduct(productData as ShoplistProduct);
              if (mediaBox) {
                sponsoredShoplists.push({
                  data: mediaBox,
                  id: item.id.toString(),
                  type: "ad",
                });
              }
            }
          } else if (item.type == "collection") {
            const mediaBox = getMediaBox(item.data as Collection);
            sponsoredShoplists.push({
              data: mediaBox,
              id: item.id.toString(),
              type: "collection",
            });
          }
        }
      });
    };

    const setShoplistByCategory = () => {
      sponsoredShoplists.length = 0;

      ManyCollectionsByCategory.value?.data.forEach((item) => {
        const mediaBox = getMediaBox(item as Collection);
        sponsoredShoplists.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "collection",
        });

        // Add results to Logic.User.SponsoredShoplistContent if the collection item is not there already

        const existingContent = JSON.parse(
          JSON.stringify(Logic.User.SponsoredShoplistContent)
        );
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "collection",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.SponsoredShoplistContent = existingContent;
        }
      });
    };

    const setBrandsByCategory = () => {
      Logic.Shoplist.ManyBusinesses = ManyBrandsByCategory.value;
    };

    const createRecommendedForYouObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRecommendedScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(
        `visibility-handle-recommended-shoplist`
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    const createSponsoredShoplistObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSponsoredShoplistScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(
        `visibility-handle-sponsored-shoplist`
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    const createExploreShoplistObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onExploreShoplistsScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(`visibility-handle-explore-shoplist`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };
    const itemIsMediaBox = (item: any): boolean => {
      return item?.type === "media";
    };

    const fetchMoreShoplistSearchResults = (nextPage: number) => {
      return Logic.Shoplist.SearchItems(
        searchValue.value,
        nextPage,
        ShoplistSearchResults.paginatorInfo?.perPage || 6,
        true
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(JSON.stringify(Logic.Shoplist.SearchResults));

            if (existingData.products && response.products) {
              existingData.products.data = existingData.products.data.concat(
                response.products.data
              );
              existingData.products.paginatorInfo = response.products.paginatorInfo;
            }

            if (existingData.collections && response.collections) {
              existingData.collections.data = existingData.collections.data.concat(
                response.collections.data
              );
              existingData.collections.paginatorInfo = response.collections.paginatorInfo;
            }

            Logic.Shoplist.SearchResults = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const fetchMoreCashbackOffers = (nextPage: number) => {
      let categoryId = "0";

      if (selectedCategory.value != "All") {
        categoryId =
          categoryOptions.find((category) => category.key === selectedCategory.value)
            ?.extras || "0";
      }

      return Promise.all([
        Logic.Shoplist.GetCashbackOffersProducts(
          parseInt(categoryId),
          nextPage,
          10,
          true
        ),
        Logic.Shoplist.GetCashbackOffersCollections(
          parseInt(categoryId),
          nextPage,
          10,
          true
        ),
      ])
        .then((response) => {
          // For GetCashbackOffersProducts
          const cashbackOffersProducts = response[0];
          if (cashbackOffersProducts) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.CashbackOffersProducts)
            );

            if (existingData.data && cashbackOffersProducts.data) {
              existingData.data = existingData.data.concat(cashbackOffersProducts.data);
              existingData.paginatorInfo = cashbackOffersProducts.paginatorInfo;
            }

            Logic.Shoplist.CashbackOffersProducts = existingData;
          }

          const cashbackOffersCollections = response[1];

          if (cashbackOffersCollections) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.CashbackOffersCollections)
            );

            if (existingData.data && cashbackOffersCollections.data) {
              existingData.data = existingData.data.concat(
                cashbackOffersCollections.data
              );
              existingData.paginatorInfo = cashbackOffersCollections.paginatorInfo;
            }

            Logic.Shoplist.CashbackOffersCollections = existingData;
          }

          setTopCashbackOffers();
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const fetchMoreBusinesses = (nextPage: number) => {
      return Logic.Shoplist.GetBusiness(nextPage, 10, true)
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyBusinesses)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyBusinesses = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const fetchNewCashbackOffers = () => {
      let categoryId = "0";
      if (selectedCategory.value != "All") {
        categoryId =
          categoryOptions.find((category) => category.key === selectedCategory.value)
            ?.extras || "0";
      }

      return Promise.all([
        Logic.Shoplist.GetCashbackOffersProducts(parseInt(categoryId), 1, 10, false),
        Logic.Shoplist.GetCashbackOffersCollections(parseInt(categoryId), 1, 10, false),
      ])
        .then(() => {
          setTopCashbackOffers(true);
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(`/shoplist/product/${item.data.product_data?.uuid}`);
      }
    };

    const focusOnCategory = (key: string) => {
      scrollToSpecificItem("categoryContainerShoplist", `category${key}`);
    };

    const searchShoplist = () => {
      Logic.Common.debounce(() => {
        if (searchValue.value.length <= 2) {
          return;
        }
        searchResultsLoading.value = true;
        Logic.Shoplist.SearchItems(
          searchValue.value,
          ShoplistSearchResults.paginatorInfo?.currentPage || 1,
          ShoplistSearchResults.paginatorInfo?.perPage || 10,
          false
        )
          .then((data) => {
            if (data) {
              Logic.Shoplist.SearchResults = data;
              ShoplistSearchResults.paginatorInfo =
                data.products?.paginatorInfo && data.collections?.paginatorInfo
                  ? data.products.paginatorInfo.lastPage >
                    data.collections.paginatorInfo.lastPage
                    ? data.products.paginatorInfo
                    : data.collections.paginatorInfo
                  : data.products?.paginatorInfo || data.collections?.paginatorInfo;
            }
            searchResultsLoading.value = false;
          })
          .catch(() => {
            searchResultsLoading.value = false;
          });
      }, 500);
    };

    const fetchCollectionsAndBrands = () => {
      if (selectedCategory.value == "All") {
        Logic.Shoplist.GetCollectionsByCategory(0, 1, 10, false);
        Logic.Shoplist.GetBrandsByCategory(0, 1, 10, false);
      } else {
        const categoryId =
          categoryOptions.find((category) => category.key === selectedCategory.value)
            ?.extras || "0";

        Logic.Shoplist.GetCollectionsByCategory(parseInt(categoryId), 1, 10, false);
        Logic.Shoplist.GetBrandsByCategory(parseInt(categoryId), 1, 10, false);
      }
    };

    watch(selectedCategory, () => {
      topCashbackOffers.data.length = 0;
      productVirtualScrollerRef.value?.resetList();
      focusOnCategory(selectedCategory.value);
      fetchCollectionsAndBrands();
      localStorage.setItem("selectedCategory", selectedCategory.value);
    });

    watch(ManyBrandsByCategory, () => {
      setBrandsByCategory();
    });

    watch(ManyCollectionsByCategory, () => {
      setShoplistByCategory();
    });

    watch(searchValue, () => {
      searchShoplist();
    });

    watch(ExploreContent, () => {
      setExploreShoplists();
    });

    watch(SponsoredShoplistContent, () => {
      setSponsoredShoplists();
    });

    watch(SearchResults, () => {
      setShoplistSearchResults();
    });

    onIonViewWillEnter(() => {
      setCategory();
      setSponsoredShoplists();
      setRecommendedShoplists();
      setExploreShoplists();
      setTopCashbackOffers();
      Logic.Game.CheckGameStatus();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("CashbackOffersProducts", CashbackOffersProducts);
      Logic.Shoplist.watchProperty(
        "CashbackOffersCollections",
        CashbackOffersCollections
      );
      Logic.User.watchProperty("ExploreContent", ExploreContent);
      Logic.User.watchProperty("SponsoredShoplistContent", SponsoredShoplistContent);
      Logic.Shoplist.watchProperty(
        "ManyCategoriesWithProducts",
        ManyCategoriesWithProducts
      );
      Logic.Shoplist.watchProperty("SearchResults", SearchResults);
      Logic.Shoplist.watchProperty("ManyBusinesses", ManyBusinesses);
      Logic.Shoplist.watchProperty("ManyBrandsByCategory", ManyBrandsByCategory);
      Logic.Shoplist.watchProperty(
        "ManyCollectionsByCategory",
        ManyCollectionsByCategory
      );
      setTimeout(() => {
        createRecommendedForYouObserver();
        createSponsoredShoplistObserver();
        createExploreShoplistObserver();
      }, 200);

      if (Logic.Common.currentBuildType() == "web") {
        selectedCategory.value = localStorage.getItem("selectedCategory") || "All";
        setCategory();
        setSponsoredShoplists();
        setRecommendedShoplists();
        setExploreShoplists();
        setTopCashbackOffers();
        Logic.Game.CheckGameStatus();
      } else {
        setCategory();
      }
    });

    return {
      Logic,
      authUser,
      categoryNames,
      selectedCategory,
      bannerAds,
      recommendedShopLists,
      exploreShoplists,
      sponsoredShoplists,
      showSponsoredShoplistIsLoading,
      showRecommendationIsLoading,
      showExploreIsLoading,
      searchIsFocused,
      searchValue,
      ContentType,
      ShoplistSearchResults,
      searchResultsLoading,
      categoryOptions,
      topCashbackOffers,
      productVirtualScrollerRef,
      ManyBusinesses,
      itemIsMediaBox,
      fetchMoreBusinesses,
      fetchMoreCashbackOffers,
      setStoryContent,
      fetchMoreShoplistSearchResults,
      handleShoplsistClick,
      numberToAbbrev,
      fetchNewCashbackOffers,
    };
  },
});
</script>
