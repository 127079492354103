<template>
  <div
    v-if="setup"
    :class="`w-full flex flex-col fixed top-0 left-0  ${
      setup?.isInteractive
        ? 'bg-black !bg-opacity-60'
        : 'bg-black !bg-opacity-30 dark:!bg-opacity-50'
    }   z-[99999999999999999]`"
    id="innerModal"
  >
    <template v-if="setup?.loading">
      <div class="loader-container w-full absolute top-0 left-0">
        <div class="loader"></div>
      </div>

      <div
        class="w-full flex flex-col space-y-5 xs:space-y-4 h-full flex-grow pt-4 items-center justify-center z-50"
      >
        <div
          class="w-full md:!w-[400px] flex flex-row items-center justify-center"
          ref="lottieContainer"
        ></div>

        <!-- <app-normal-text
          :color="'text-white'"
          :customClass="'px-8 !text-sm !font-semibold text-center pt-3 '"
          v-if="setup?.isInteractive"
        >
          {{ setup?.message || messages[currentMessageIndex] }}
        </app-normal-text> -->
      </div>
    </template>
    <template v-else>
      <div
        class="w-full absolute top-0 left-0 h-full bg-white flex flex-col justify-center items-center"
        v-if="setup?.reveal"
      >
        <AppReveal
          :trigger-button="setup?.reveal?.button_action"
          :custom-class="''"
          @last-text-event="setup?.reveal?.last_text_action()"
          :secondary-button-text="setup?.reveal?.secondary_button_text"
          :secondary-button-trigger="setup?.reveal?.secondary_button_action"
        >
          <template v-slot:reveal-logo>
            <app-icon :customClass="'h-[90px]'" :name="setup.reveal.logo"></app-icon>
          </template>
          <template v-slot:center-text> {{ setup.reveal.title }} </template>
          <template v-slot:sub-center-text>
            {{ setup?.reveal?.sub_title }}
          </template>
          <template v-slot:button-text>
            {{ setup.reveal?.button_text }}
          </template>
          <template v-slot:last-text> {{ setup?.reveal.last_text }} </template>
        </AppReveal>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import AppIcon from "../AppIcon";
import AppReveal from "../AppReveal";
import { AppNormalText } from "../AppTypography";
import lottie from "lottie-web/build/player/lottie_light";

export default defineComponent({
  components: {
    AppNormalText,
    AppReveal,
    AppIcon,
  },
  props: {
    customClass: {
      type: String,
      required: false,
    },
    setup: {
      type: Object as () => {
        show?: boolean;
        loading?: boolean;
        message?: string;
        reveal?: {
          title: string;
          sub_title: string;
          logo: string;
          button_text: string;
          button_action: Function;
          last_text: string;
          last_text_action?: Function;
          secondary_button_text?: string;
          secondary_button_action?: Function;
        };
        isInteractive?: boolean;
      },
    },
  },
  name: "AppLoader",
  setup(props) {
    const currentMessageIndex = ref(0);

    const timeoutTime = ref(120);
    const timeOutInstance = ref();
    const lottieContainer = ref();

    const messageInteval = ref();
    const messages = [
      "Did You Know: Cats have five toes on front paws.",
      "Reward Fact: Laughing burns calories.",
      "Daily Insight: A smile is universal welcome",
      "Fun Fact: Cows have best friends.",
      "Quick Tip: Drink water to boost energy.",
      "Did You Know: Honey never spoils.",
      "Today's Fact: Penguins propose with pebbles.",
      "Reward Fact: Positive thoughts create joy.",
      "Fun Fact: Dolphins have names for each other.",
      "Quick Insight: Gratitude improves sleep.",
      "Did You Know: Strawberries aren't berries.",
      "Fact of the Day: Trees can communicate.",
      "Reward Tip: Saving coins builds wealth.",
      "Fun Fact: Baby kangaroos are called joeys.",
      "Daily Thought: Kindness is free.",
      "Did You Know: A group of owls is a parliament.",
      "Quick Fact: Almonds are seeds, not nuts.",
      "Reward Insight: Small wins matter.",
      "Fun Fact: Elephants can't jump.",
      "Today's Insight: Laughter unites people.",
      "Did You Know: Giraffes have purple tongues.",
      "Fact of the Day: Snails can sleep for 3 years.",
      "Reward Tip: Patience pays off.",
      "Quick Fact: Lightning strikes 8 million times a day.",
      "Daily Fun: Frogs can't swallow with eyes open.",
      "Did You Know: Bananas are berries.",
      "Today's Fact: An ant can lift 50 times its weight.",
      "Reward Insight: Every penny counts.",
      "Fun Fact: Butterflies taste with feet.",
      "Quick Tip: Smiling boosts your mood.",
    ];

    const startTimeoutCounter = () => {
      timeOutInstance.value = setInterval(() => {
        if (timeoutTime.value == 0) {
          Logic.Common.hideLoader();
          Logic.Common.showError(
            undefined,
            "Network Timeout. Please try again",
            "Got It"
          );
          return;
        }
        timeoutTime.value -= 1;
      }, 1000);
    };

    const rotateMessage = () => {
      messageInteval.value = setInterval(() => {
        currentMessageIndex.value = Math.floor(Math.random() * messages.length);
      }, 5000);
    };

    onMounted(() => {
      if (props.setup?.isInteractive) {
        rotateMessage();
      }

      const animation = lottie.loadAnimation({
        container: lottieContainer.value, // the ref to our div
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/images/shoppoint-logo.json", // path to the animation JSON
      });
      animation.setSpeed(1.5);

      startTimeoutCounter();
    });

    onUnmounted(() => {
      clearInterval(messageInteval.value);
      clearInterval(timeOutInstance.value);
    });

    return {
      messages,
      currentMessageIndex,
      lottieContainer,
    };
  },
});
</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4px;
  background-color: transparent;
  border-radius: 4px;
  overflow: hidden;
}

.loader {
  height: 100%;
  animation: loaderAnimation 1.5s infinite;
}

@keyframes loaderAnimation {
  0% {
    width: 0%;
    background-color: #53c1ff;
  }
  25% {
    width: 25%;
    background-color: #ffaa00;
  }
  50% {
    width: 50%;
    background-color: #80d89b;
  }
  75% {
    width: 75%;
    background-color: #ea5959;
  }
  100% {
    width: 100%;
    background-color: #6a76e5;
  }
}

#innerModal {
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
}
</style>
