import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "w-full flex flex-row items-center justify-center h-[60px] bg-[#F3F3F3] dark:!bg-gray-900 rounded-[10px] py-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      (_ctx.currentView === 'select')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.allDeliveryInfo.length > 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.allDeliveryInfo, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(`w-full flex flex-col space-y-1 bg-[#F3F3F3] dark:!bg-gray-900 rounded-[10px] cursor-pointer px-3 py-3 border-gray-200 dark:!border-gray-700 ${
                item.id == _ctx.data?.selected_delivery_info?.id
                  ? 'border-primary-400  border-[2px]'
                  : 'border-gray-200  border-[1px]'
              }`),
                    onClick: ($event: any) => (_ctx.selectDeliveryInfo(item))
                  }, [
                    _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-50 !font-semibold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.contact_name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_app_normal_text, { class: "text-left !text-[11px]" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.delivery_address.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_app_normal_text, { class: "text-left !text-[11px]" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.contact_phone), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_2))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-50" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" You have no delivery address yet ")
                    ])),
                    _: 1
                  })
                ]))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_app_normal_text, { class: "text-left font-semibold text-base" }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Add a new delivery address ")
        ])),
        _: 1
      }),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter name',
        hasTitle: true,
        type: 'text',
        padding: 'py-3 px-3',
        name: "Name e.g John Doe",
        ref: "contactName",
        modelValue: _ctx.formData.contact_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.contact_name) = $event)),
        updateValue: _ctx.formData.contact_name,
        rules: [
        _ctx.FormValidations.RequiredRule,
        _ctx.FormValidations.customValidator(
          _ctx.formData.contact_name.length > 2,
          'Name must be more than 2 characters'
        ),
      ],
        required: ""
      }, {
        title: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Contact Name ")
        ])),
        _: 1
      }, 8, ["modelValue", "updateValue", "rules"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter phone number',
        modelValue: _ctx.formData.contact_phone,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.contact_phone) = $event)),
        hasTitle: true,
        type: "tel",
        padding: 'py-3 px-3',
        name: "Phone number e.g 08012345678",
        ref: "contactPhone",
        updateValue: _ctx.formData.contact_phone,
        rules: [
        _ctx.FormValidations.RequiredRule,
        _ctx.FormValidations.customValidator(
          _ctx.formData.contact_phone.length == 11,
          'Phone number must be valid'
        ),
      ],
        "content-rule": {
        addAfterCount: 0,
        characterToAdd: ``,
        max: 11,
      },
        required: ""
      }, {
        title: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Contact Phone ")
        ])),
        _: 1
      }, 8, ["modelValue", "updateValue", "rules"]),
      _createVNode(_component_app_select, {
        placeholder: 'Select area',
        hasTitle: true,
        modelValue: _ctx.formData.delivery_address.name,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.delivery_address.name) = $event)),
        paddings: 'px-3 py-3',
        ref: "deliveryAddress",
        onOnSearch: _ctx.handleAreaSearch,
        customClass: 'dark:!bg-grey-800',
        options: _ctx.areaOptions,
        theme: _ctx.Logic.Common.currentAppTheme(),
        autoComplete: "",
        hasSearch: true,
        searchMessage: 'Search for your delivery address',
        searchIsLoading: _ctx.areaSearchIsLoading,
        required: ""
      }, {
        title: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" Contact Address ")
        ])),
        _: 1
      }, 8, ["modelValue", "onOnSearch", "options", "theme", "searchIsLoading"]),
      _createVNode(_component_app_button, {
        class: _normalizeClass(`w-full ${_ctx.formIsValid ? '' : 'opacity-50'}`),
        padding: '!py-3',
        loading: _ctx.savingDeliveryInfo,
        onClick: _withModifiers(_ctx.saveDeliveryInfo, ["prevent"])
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" Save ")
        ])),
        _: 1
      }, 8, ["class", "loading", "onClick"])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}