import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 h-full pt-4" }
const _hoisted_2 = { class: "w-full flex flex-col px-4" }
const _hoisted_3 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_4 = { class: "flex flex-col space-y-1" }
const _hoisted_5 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-2 pt-2" }
const _hoisted_7 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_8 = { class: "w-full h-[8px] rounded-[10px] bg-secondary-50 dark:bg-secondary-100 relative" }
const _hoisted_9 = { class: "w-full flex flex-col" }
const _hoisted_10 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide pl-4 pt-2",
  id: "cashback-savings-container"
}
const _hoisted_11 = { class: "flex flex-row pr-4 space-x-3" }
const _hoisted_12 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_13 = { class: "flex flex-col space-y-1" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "w-full flex flex-row space-x-1" }
const _hoisted_16 = { class: "!font-bold pl-[2px]" }
const _hoisted_17 = { class: "w-full flex flex-col px-4 pt-3" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-4 dark:bg-black rounded-[10px] px-4 pb-4" }
const _hoisted_19 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}
const _hoisted_20 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}
const _hoisted_21 = {
  key: 1,
  class: "w-full flex flex-col space-y-3"
}
const _hoisted_22 = { class: "w-full items-center flex justify-center py-2" }
const _hoisted_23 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}
const _hoisted_24 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}
const _hoisted_25 = {
  key: 1,
  class: "w-full flex flex-col space-y-5"
}
const _hoisted_26 = { class: "w-full items-center flex justify-center py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_reward = _resolveComponent("app-reward")!
  const _component_app_transaction = _resolveComponent("app-transaction")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        pageKey: 'savings',
        greetings: 'Hi'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_image_loader, {
                "custom-class": 'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500 relative',
                "photo-url": '/images/card-bg.svg'
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_AppNormalText, { color: 'text-white' }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Cashback Balance ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_app_header_text, { color: 'text-white' }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.canView
                    ? `${_ctx.Logic.Common.convertToMoney(
                        _ctx.UserWallet?.cash_point_balance,
                        true,
                        "ngn"
                      )}`
                    : "*********"), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.canView)
                        ? (_openBlock(), _createBlock(_component_app_icon, {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.canView = false)),
                            "custom-class": 'w-[16px] cursor-pointer',
                            name: 'show-white'
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.canView)
                        ? (_openBlock(), _createBlock(_component_app_icon, {
                            key: 1,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.canView = true)),
                            "custom-class": 'w-[16px] cursor-pointer',
                            name: 'hide-white'
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_app_normal_text, { class: "!text-white !text-[11px]" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Weekly spend limit (" + _toDisplayString(_ctx.weeklySpendLimit) + "%) ", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, {
                        class: "!text-primary-200 cursor-pointer",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/others/membership')))
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Upgrade ")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: "absolute top-0 left-0 h-full rounded-[10px] bg-[#FFC44D] dark:bg-secondary-500",
                        style: _normalizeStyle(`width: ${_ctx.weeklySpendLimit}%;`)
                      }, null, 4)
                    ])
                  ]),
                  _createElementVNode("span", {
                    class: "absolute top-[4%] right-[4%] px-4 py-1 bg-secondary-main rounded-[20px] cursor-pointer",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/savings/topup')))
                  }, [
                    _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" + Topup ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cashbackSavings, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "w-[240px] flex flex-col space-y-3 bg-white dark:bg-gray-800 rounded-[6px] px-3 py-3 border-[1px] border-gray-100"
                    }, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_app_normal_text, { class: "!text-left !text-[10px] uppercase !font-semibold" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_app_header_text, { class: "!text-sm !text-[#240046] !text-left" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.amount, true, "ngn")), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", {
                          class: "px-3 py-1 bg-gray-100 dark:bg-primary-700 rounded-[30px] items-center justify-between cursor-pointer",
                          onClick: ($event: any) => (
                      _ctx.monthIsClaimed(item.key) || !item.is_active
                        ? null
                        : _ctx.claimedMonthPoint(item.key)
                    )
                        }, [
                          _createVNode(_component_app_normal_text, { class: "!text-primary-400 !text-[11px] dark:!text-primary-100" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.redeemed
                          ? "Redeemed"
                          : item.is_active
                          ? "Redeem now"
                          : "Not available"), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ], 8, _hoisted_14)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_app_icon, {
                          name: 'calendar-purple',
                          customClass: 'h-[14px]'
                        }),
                        _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-[#5A189A] dark:!text-primary-200" }, {
                          default: _withCtx(() => [
                            _cache[10] || (_cache[10] = _createTextVNode(" Cashback available on: ")),
                            _createElementVNode("span", _hoisted_16, _toDisplayString(item.available_on), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_app_switch, {
                items: _ctx.savingsSwitch,
                modelValue: _ctx.selectedFilterSavings,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedFilterSavings) = $event))
              }, null, 8, ["items", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_18, [
              (_ctx.selectedFilterSavings == 'activities')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.loadingPointEarned)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createVNode(_component_app_loading_state, { class: "text-primary-500" })
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (_ctx.rewards.length == 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createVNode(_component_app_empty_state, {
                                  title: 'No activities yet',
                                  "sub-title": 'Your cashback rewards will appear here'
                                })
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rewards, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_app_reward, {
                                    key: index,
                                    item: item,
                                    onClick: ($event: any) => (item.action())
                                  }, null, 8, ["item", "onClick"]))
                                }), 128)),
                                _createElementVNode("div", _hoisted_22, [
                                  _createVNode(_component_app_normal_text, {
                                    class: "!py-1 !px-3 w-auto !text-gray-800 dark:!text-gray-300 cursor-pointer",
                                    onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.Logic.Common.GoToRoute('/others/rewards?tab=scan')), ["prevent"]))
                                  }, {
                                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                                      _createTextVNode(" See all activities > ")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ]))
                        ], 64))
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.selectedFilterSavings == 'transactions')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.loadingTransactions)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createVNode(_component_app_loading_state, { class: "text-primary-500" })
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (_ctx.transactions.length == 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                _createVNode(_component_app_empty_state, {
                                  title: 'No transactions yet',
                                  "sub-title": 'Your transactions will appear here'
                                })
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_app_transaction, {
                                    key: index,
                                    data: item,
                                    class: "cursor-pointer",
                                    onClick: ($event: any) => (
                    item.transaction_type == 'wallet'
                      ? _ctx.Logic.Common.GoToRoute(`/savings/transactions/${item.uuid}`)
                      : _ctx.Logic.Common.GoToRoute(`/savings/points/${item.uuid}`)
                  )
                                  }, null, 8, ["data", "onClick"]))
                                }), 128)),
                                _createElementVNode("div", _hoisted_26, [
                                  _createVNode(_component_app_normal_text, {
                                    class: "!py-1 !px-3 w-auto !text-gray-800 dark:!text-gray-300 cursor-pointer",
                                    onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.Logic.Common.GoToRoute('/savings/transactions')), ["prevent"]))
                                  }, {
                                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                                      _createTextVNode(" See all transactions > ")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ]))
                        ], 64))
                  ], 64))
                : _createCommentVNode("", true),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}